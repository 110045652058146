import { supabase } from '../utils';
import { PostgrestTransformBuilder } from '@supabase/postgrest-js';
import { Draft } from '../draft/api';

export interface ApiArgs {
  id: string;
}

export interface PostApiArgs {
  drafts: Draft[] | any;
}

export const getDataByTeamIds = ({ teamIds }: { teamIds: any[] }) => {
  return supabase.from('team_player').select('*').in('team_id', teamIds);
};

export const getDataByLeagueId = (leagueId) => {
  return supabase
    .from('team_player')
    .select(`*, team_id (*), player_id (*)`)
    .eq('league_id', leagueId);
};

export const postData = ({
  drafts,
}: PostApiArgs): PostgrestTransformBuilder<any, any, any> => {
  const payload = [];

  drafts.forEach((draft) => {
    payload.push({
      team_id: draft.team_id.id,
      player_id: draft.player_id.id,
      league_id: draft.team_id.league_id,
    });
  });
  return supabase.from('team_player').insert(payload);
};

export const postDataForFreeAgency = (leagueId, player, teamId) => {
  const payload = {
    team_id: teamId,
    player_id: player.id,
    league_id: leagueId,
  };
  return supabase
    .from('team_player')
    .insert(payload)
    .select('team_id (*), player_id (*)');
};

export const postDataByTrade = (trades) => {
  const payload = [];
  trades.forEach((trade) => {
    payload.push({
      team_id: trade.team_id,
      player_id: trade.player_id,
      league_id: trade.league_id,
    });
  });
  return supabase.from('team_player').insert(payload);
};

export const putData = (id, payload) => {
  return supabase.from('team_player').update(payload).eq('team_id', id);
};

export const deleteDataByTeamIdPlayerId = (teamId, playerId) => {
  return supabase
    .from('team_player')
    .delete()
    .match({ team_id: teamId, player_id: playerId });
};

export const deleteData = (ids) => {
  return supabase.from('team_player').delete().in('id', ids);
};
