import { Transition, Dialog } from '@headlessui/react';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isValidEmail } from '../../../utils/utils';
import axios from 'axios';
import { useLeague } from '../../../hooks/useLeague';

export const InviteTeamsModal = ({
  onClose,
  leagueId,
  onSuccess = undefined,
}) => {
  const { data: leagueData } = useLeague(leagueId);
  const [emails, setEmails] = useState({
    [uuidv4()]: '',
  });
  const [isError, setIsError] = useState(false);

  const onChangeHandler = (e) => {
    setEmails({
      ...emails,
      [e.target.name]: e.target.value,
    });
  };

  const onClickAddEmailHandler = () => {
    setEmails({
      ...emails,
      [uuidv4()]: '',
    });
    setIsError(false);
  };

  const onCloseHandler = () => {
    onClose();
  };

  const onSuccessHandler = () => {
    onSuccess();
  };

  const onClickDeleteEmailHandler = (name) => {
    const payload = {
      ...emails,
    };
    delete payload[name];
    setEmails(payload);
    setIsError(false);
  };

  const onSubmit = async () => {
    const values = Object.values(emails);
    let isError = false;
    values.forEach((value) => {
      if (!isValidEmail(value)) {
        isError = true;
      }
    });
    if (isError) {
      setIsError(true);
      return;
    }
    await axios.post('https://www.3fgolf.com/api/sendEmail', {
      type: 'invite',
      emails: values,
      code: leagueData.code,
      leagueName: leagueData.name,
    });
    if (onSuccess) onSuccess();
    onClose();
  };

  const generateInputs = () => {
    const result = Object.entries(emails).map((entry, idx) => {
      return (
        <div
          className="grid w-full grid-cols-[.5fr,5fr,.5fr] place-items-center gap-2"
          key={entry[0]}
        >
          <span className="text-sm">{idx + 1}</span>
          <input
            type="text"
            placeholder="email"
            name={entry[0]}
            className="w-full rounded-md p-2"
            value={emails[entry[0]]}
            onChange={onChangeHandler}
          />
          <button
            className=""
            onClick={() => onClickDeleteEmailHandler(entry[0])}
          >
            <TrashIcon width={20} height={20} />
          </button>
        </div>
      );
    });
    return result;
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onSuccess ? onSuccessHandler() : onCloseHandler();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex w-full flex-col">
                  <div>
                    <div className="mb-6 flex flex-col">
                      <span className="font-semibold">Invite Teams</span>
                      <span className="text-sm">
                        Enter in emails to send a league invitation
                      </span>
                    </div>
                    <div className="flex flex-col gap-2">
                      {generateInputs()}
                      <div className="relative flex w-full flex-row items-center gap-4">
                        {isError && (
                          <div className="absolute top-0 flex w-full flex-row items-center justify-center">
                            <span className="text-xs text-[#ff0033]">
                              Please provide valid email addresses
                            </span>
                          </div>
                        )}
                        <button
                          className="mb-2 mt-6 flex w-full flex-row items-center justify-center self-center rounded-lg border border-slate-500 p-1"
                          key="addPlayer"
                          onClick={onClickAddEmailHandler}
                        >
                          <div className="flex items-center justify-center">
                            <PlusIcon height={20} width={20} />
                            <span className="w-full text-xs">Add Email</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col items-center justify-center gap-2">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={onSubmit}
                      >
                        SEND
                      </button>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={() =>
                          onSuccess ? onSuccessHandler() : onCloseHandler()
                        }
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
