import { Transition, Dialog } from '@headlessui/react';
import {
  ArrowRightIcon,
  XCircleIcon,
  CalendarIcon,
  ArrowLeftIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useScoreByLeagueId } from '../../../hooks/useScoresByLeagueId';
import { useTeamPlayerHistory } from '../../../hooks/useTeamPlayerHistory';
import {
  formatNumberWithCommas,
  formatReadableDate,
} from '../../../utils/utils';
import { LoadingSkeleton } from '../../organisms/loadingSkeleton';

const LeagueHistoryModal = ({ onClose, leagueId, images }) => {
  const { data } = useScoreByLeagueId(leagueId);
  const [index, setIndex] = useState(0);
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    if (!data || Object.values(data).length === 0) return;
    let uniqueTournaments = {};
    data.forEach((d) => {
      if (!uniqueTournaments[d.tournament_id.id]) {
        uniqueTournaments[d.tournament_id.id] = d.tournament_id;
      }
    });
    const result = Object.values(uniqueTournaments).sort(
      (a: any, b: any) =>
        new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
    );
    setTournaments(result);
  }, [data]);

  const mapTournamentData = (data) => {
    const currentTournament = tournaments[index];
    const filteredData = data.filter(
      (d) => d.tournament_id.id === currentTournament.id
    );
    const results = filteredData.map((team, idx) => (
      <li className="flex justify-between" key={team.id}>
        <div className="flex w-full flex-row items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="mr-2 w-1 grow-0 text-sm">{idx + 1}</span>
            <Link
              to={`/league/${leagueId}/team/${team.team_id.id}`}
              key={team.id}
              className="flex items-center gap-2"
            >
              <img
                src={
                  images.find((image) => image[team.team_id.id])[
                    team.team_id.id
                  ]
                }
                width={30}
                height={30}
                className="aspect-square rounded-full border border-gray-500 object-cover"
              />
              <span className="w-[15ch] overflow-x-clip underline">
                {team.team_id.name}
              </span>
            </Link>
          </div>
          <div>{<div>{formatNumberWithCommas(team.score)}</div>}</div>
        </div>
      </li>
    ));
    return results;
  };

  const onClickHandler = (increment) => {
    if (index + increment < 0) return;
    if (index + increment > tournaments.length - 1) return;
    setIndex(index + increment);
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex flex-col items-center justify-center">
                  <div className="absolute right-0 top-0 m-1 flex w-full justify-end">
                    <button onClick={onClose}>
                      <XCircleIcon height={30} width={30} />
                    </button>
                  </div>
                  <div className="flex w-5/6 justify-between">
                    <button onClick={() => onClickHandler(1)}>
                      <ArrowLeftIcon height={30} width={30} />
                    </button>
                    {tournaments.length > 0 && (
                      <div className="flex flex-col items-center justify-center gap-2">
                        <span>{tournaments[index]?.name}</span>
                        <span className="text-sm">
                          {formatReadableDate(tournaments[index]?.start_date)}
                          &nbsp; - &nbsp;{' '}
                          {formatReadableDate(tournaments[index]?.end_date)}
                        </span>
                      </div>
                    )}
                    <button onClick={() => onClickHandler(-1)}>
                      <ArrowRightIcon height={30} width={30} />
                    </button>
                  </div>
                  <div className="m-5 flex w-5/6 flex-col rounded-md bg-white">
                    <div className="mt-5 flex w-full justify-between px-5 font-semibold text-black">
                      <div></div>
                      <span className="mr-12">Team</span>
                      <span>Total PTS</span>
                    </div>
                    <ul className="flex flex-col gap-5 px-5 pb-5 pt-5">
                      {data &&
                        images.length > 0 &&
                        data &&
                        tournaments.length > 0 &&
                        mapTournamentData(data)}
                    </ul>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default LeagueHistoryModal;
