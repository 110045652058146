import { supabase } from '../utils';

export const getData = (tradeIds) => {
  return supabase
    .from('trades_players')
    .select('*, player_id (*)')
    .in('transaction_id', tradeIds);
};

export const postData = ({ tradeId, tradesPlayers }) => {
  const payload = tradesPlayers.map((instance) => {
    return {
      transaction_id: tradeId,
      type: instance.type,
      player_id: instance.id,
    };
  });
  return supabase.from('trades_players').insert(payload);
};
