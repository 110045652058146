import { useSupabase } from '../hooksV2/useSupabase';
import { putData } from '../models/users/api';

export const useUpdateUser = () => {
  const { user } = useSupabase();

  const updateUser = async ({ firstName, lastName, username }) => {
    try {
      const result = await putData({
        id: user.id,
        firstName,
        lastName,
        username,
      });
      return { result: result[0], error: false };
    } catch (error) {
      return { result: null, error: true };
    }
  };

  return {
    updateUser,
  };
};
