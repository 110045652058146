import { supabase } from '../utils';
import {
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestError,
} from '@supabase/postgrest-js';
import { Team } from '../teams/api';
import { Player } from '../players/api';

export type Draft = {
  id: string;
  created_at: string;
  updated_at: string;
  draft_number: number;
  league_id: string;
  team_id: Team;
  player_id: Player;
};

export interface ApiResponse {
  data: Draft;
  error: PostgrestError;
}

export interface GetApiArgs {
  leagueId: string;
}

export interface PostApiArgs {
  draftEntries: {
    team_id: string;
    draft_number: number;
    league_id: string;
  }[];
}

export interface PutApiArgs {}

export const getData = ({
  leagueId,
}: GetApiArgs): PostgrestFilterBuilder<any, any, Draft[]> => {
  return supabase
    .from('draft')
    .select('*, team_id:teams(*), player_id:players(*)')
    .eq('league_id', leagueId);
};

export const postData = ({
  draftEntries,
}: PostApiArgs): PostgrestTransformBuilder<any, any, Draft[]> => {
  return supabase.from('draft').insert(draftEntries).select();
};

export const putData = ({
  team_id,
  player_id,
  draft_number,
}): PostgrestTransformBuilder<any, any, Draft[]> => {
  return supabase
    .from('draft')
    .update({
      player_id,
    })
    .eq('draft_number', draft_number)
    .eq('team_id', team_id)
    .select()
    .throwOnError();
};
