import { supabase } from '../utils';
import { getCurrentTimestamp } from '../../utils/utils';
import {
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestError,
} from '@supabase/postgrest-js';

export type League = {
  pic_url: string;
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  teams: string[] | null;
  code: string;
  team_player_limit: number;
  commissioner_id: string;
  isDrafted: boolean;
};

export interface ApiResponse {
  data: League;
  error: PostgrestError;
}

export interface GetApiArgs {
  id: string;
}

export interface PostApiArgs {
  name: string;
  players: number;
  id: string;
}

export interface GetByLeagueCodeApiArgs {
  code: string;
}

export interface PutApiArgs {
  id: string;
  isDrafted: boolean;
}

export const getData = (
  id: string
): PostgrestFilterBuilder<any, any, League[]> => {
  return supabase.from('leagues').select().eq('id', id);
};

export const getDataByLeagueCode = ({
  code,
}: GetByLeagueCodeApiArgs): PostgrestFilterBuilder<any, any, any[]> => {
  return supabase.from('leagues').select('id, name').eq('code', code);
};

export const postData = ({
  name,
  players,
  id,
}: PostApiArgs): PostgrestTransformBuilder<any, any, League[]> => {
  return supabase
    .from('leagues')
    .insert({
      name,
      team_player_limit: players,
      commissioner_id: id,
    })
    .select();
};

export const putData = ({
  isDrafted,
  id,
}: PutApiArgs): PostgrestTransformBuilder<any, any, League[]> => {
  return supabase
    .from('leagues')
    .update({
      isDrafted,
      drafted_date: getCurrentTimestamp(),
    })
    .eq('id', id)
    .select();
};

export const updateLeague = (league) => {
  return supabase
    .from('leagues')
    .update({
      ...league,
    })
    .eq('id', league.id);
};

export const putLeagueNameData = (name, id) => {
  return supabase.from('leagues').update({ name }).eq('id', id);
};

export const deleteLeagueData = (id) => {
  return supabase.from('leagues').delete().eq('id', id);
};
