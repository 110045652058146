import { useQuery } from 'react-query';
import {
  getDataByTournamentName,
  getDataFromView,
} from '../models/results/api';

export const useResultsByTournamentName = (name, year) => {
  return useQuery(
    ['useResultsByTournamentName', name, year],
    async () => getDataFromView(name, year).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      enabled: !!name && !!year,
    }
  );
};
