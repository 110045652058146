import { useEffect, useState, useRef } from 'react';
import { LeagueHeader } from '../organisms/leagueHeader';
import { MobileNavigation } from '../app/mobileNavigation';
import { postData } from '../../models/messages/api';
import { useMyTeam } from '../../hooks/useMyTeam';
import { useMyLeague } from '../../hooks/useMyLeague';
import { useMessageBoard } from '../../hooks/useMessageBoard';
import { isMobile, formattedMessageDate } from '../../utils/utils';
import { ArrowUpIcon } from '@heroicons/react/24/outline';

export const MessageBoardPage = () => {
  const { data: myLeagueData } = useMyLeague();
  const [message, setMessage] = useState<string>('');
  const { data: myTeamData } = useMyTeam();
  const { data } = useMessageBoard(myLeagueData?.id ?? '');
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const messageContainerRef = useRef(null);

  const adjustHeight = () => {
    const textarea = messageInputRef.current;
    const messageContainer = messageContainerRef.current;
    if (!textarea || !messageContainer) return;

    const baseHeight = 60;
    const borderAndPaddingHeight = 15;
    const previousHeight = textarea.clientHeight;

    textarea.style.height = 'auto';
    const scrollHeight = textarea.scrollHeight - borderAndPaddingHeight;

    let needToScroll = false;
    if (scrollHeight > baseHeight) {
      const currentHeight = Math.min(scrollHeight, 150);
      textarea.style.height = `${currentHeight}px`;
      needToScroll = currentHeight === 150;
    } else {
      textarea.style.height = `${baseHeight}px`;
    }

    const heightDifference =
      parseInt(textarea.style.height, 10) - previousHeight;
    const currentMarginBottom =
      parseInt(messageContainer.style.marginBottom, 10) || 270;
    const newMarginBottom = currentMarginBottom + heightDifference;
    messageContainer.style.marginBottom = `${newMarginBottom}px`;

    if (heightDifference > 0 || needToScroll) {
      messageContainer.scrollTop += heightDifference;
      textarea.scrollTop = textarea.scrollHeight;
    }
  };

  const onMessageChangeHandler = (e) => {
    setMessage(e.target.value);
  };

  const onSubmitMessageHandler = async (e) => {
    e.preventDefault();
    if (message.trim()) {
      await postData(message, myTeamData.id, myLeagueData.id);
      setMessage('');
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onKeyDownHandler = (e) => {
    if (!isMobile() && e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmitMessageHandler(e);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  const generateMessages = (messageData) => {
    messageData.sort((a, b) => {
      // Convert 'created_at' strings to Date objects
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      // Compare the dates to determine sort order
      return dateA.getTime() - dateB.getTime();
    });
    const results = messageData.map((message) => {
      const isMyMessage = message.team_id.id === myTeamData.id;
      return (
        <div
          key={message.id}
          className={`flex w-full flex-col py-2 ${
            isMyMessage ? 'items-end' : 'items-start'
          }`}
        >
          <div className="relative flex w-full flex-col gap-2 p-2">
            <div
              className={`flex w-fit rounded-md border border-primary bg-white px-2 py-1 ${
                isMyMessage ? 'self-end' : 'self-start'
              }`}
            >
              <span className="text-sm">{message.value}</span>
            </div>
            <span
              className={`absolute bottom-10 whitespace-nowrap text-[10px] text-gray-500 ${
                isMyMessage ? 'right-3' : 'left-3'
              }`}
            >
              {formattedMessageDate(message)}
            </span>
            <div
              className={`mt-3 flex items-center gap-2 ${
                isMyMessage ? 'flex-row-reverse self-end' : 'self-start'
              }`}
            >
              <img
                src={message.imageUrl ?? ''}
                height={28}
                width={28}
                className="flex aspect-square items-center rounded-full object-cover"
              />
              <span className="whitespace-nowrap text-xs">
                {message.team_id.name}
              </span>
            </div>
          </div>
        </div>
      );
    });
    return results;
  };

  return (
    <>
      <div className="min-h-full">
        <div className="bg-gray-800">
          <LeagueHeader leagueId={myLeagueData?.id} name={myLeagueData?.name} />
        </div>
        <main>
          <div className="mt-2 flex h-[80vh] flex-col justify-between">
            <div className="flex justify-center border-b border-accent">
              <div className="mx-4 flex h-10 w-full items-center justify-center rounded-md bg-white pb-2 text-sm">
                <span className="flex items-center font-semibold">
                  Message Board
                </span>
              </div>
            </div>
            <div className="fixed mt-[2.55rem] flex h-full w-full flex-col bg-slate-200">
              <div
                ref={messageContainerRef}
                className="flex flex-col overflow-y-scroll px-2"
                style={{ marginBottom: '270px' }}
              >
                {data && myTeamData && generateMessages(data)}
                <div ref={messagesEndRef}></div>
              </div>
              <div className="fixed bottom-[60px] flex w-full resize-y items-center justify-between border-t border-accent bg-white px-2">
                <textarea
                  ref={messageInputRef}
                  className="flex w-full resize-y items-center border-r border-accent px-2 py-4 placeholder:italic focus:outline-none"
                  value={message}
                  onChange={onMessageChangeHandler}
                  onKeyDown={onKeyDownHandler}
                  onInput={adjustHeight}
                  rows={1}
                  placeholder="Type a message here..."
                  style={{ height: '60px' }}
                />
                <button
                  className="ml-2 flex aspect-square h-8 items-center justify-center rounded-full border-2 border-primary p-px"
                  onClick={(e) => onSubmitMessageHandler(e)}
                >
                  <ArrowUpIcon
                    className="text-primary"
                    height={20}
                    width={20}
                  />
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
      <MobileNavigation />
    </>
  );
};
