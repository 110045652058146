import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { formatNumberWithCommas } from '../../../utils/utils';
import { usePlayerResultsByTeamId } from '../../../hooks/usePlayerResultsByTeam';
import {
  yearsDifferenceFromCurrent,
  formatPlayerName,
} from '../../../utils/utils';
import { clsx } from 'clsx';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { HandRaisedIcon } from '@heroicons/react/24/outline';
import { useSupabase } from '../../../hooksV2/useSupabase';

export const PlayerLookup = ({ onClose, team, onSelect, teamPlayers }) => {
  const { data: players } = usePlayerResultsByTeamId(team.id);
  const { supabase } = useSupabase();
  const [images, setImages] = useState([]);
  const fetchImages = async (players) => {
    const promises = [];
    const filteredPlayers = players.filter((player) => !!player.profile_url);
    for (const player of filteredPlayers) {
      const result = supabase.storage
        .from('golfers')
        .getPublicUrl(player.profile_url);
      promises.push(result);
    }
    const result = supabase.storage.from('golfers').getPublicUrl('unknown');
    promises.push(result);
    const results = await Promise.all(promises);
    const mapResults = results.map((result) => result.data.publicUrl);
    setImages(mapResults);
  };

  useEffect(() => {
    if (players) {
      fetchImages(players);
    }
  }, [players]);

  const generateTableData = (player, index, imageUrl) => {
    const formattedName = formatPlayerName(player.name);

    const yoe = player.turned_pro
      ? yearsDifferenceFromCurrent(player.turned_pro)
      : '-';

    const yoeClass = clsx({
      'text-gray-400': yoe === '-',
      'text-current': yoe !== '-',
    });

    const iconStyle =
      player.handedness === 'L' ? { transform: 'scaleX(-1)' } : {};

    let team = undefined;

    return (
      <div className="px-3 py-4 text-xs sm:pl-0">
        <div className="flex items-center">
          <span className="mr-5 lg:mx-5">{index + 1}</span>
          <div className="h-10 w-10 flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src={imageUrl ?? ''}
              alt=""
            />
          </div>
          <div className="ml-4 mr-auto flex flex-1 items-center justify-between">
            <div>
              {team && (
                <div className="flex flex-row items-center gap-2">
                  <ArrowRightIcon height={10} width={10} />
                  <span className="text-xs">{team.name}</span>
                </div>
              )}
              <div className="w-[110%] font-medium text-gray-900">
                {formattedName}
              </div>
              <div className="flex flex-row items-center gap-3">
                <div className="flex flex-row items-center gap-1">
                  <HandRaisedIcon style={iconStyle} height={15} width={15} />
                  <div>{player.handedness}</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <span>YOE:</span>
                  <div className={yoeClass}>{yoe}</div>
                </div>
              </div>
            </div>
            <div className="grid w-full grid-cols-2 place-items-end">
              <div className="flex flex-col items-center">
                <span className="w-4/5 text-center font-semibold">
                  Total PTS
                </span>
                <div>{formatNumberWithCommas(player.score)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const filteredPlayers = players.filter(
    (player) => !teamPlayers.find((instance) => instance.id == player.id)
  );

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="-my-2 overflow-x-auto lg:-mx-4">
                  <div className="min-w-full py-2 align-middle sm:px-2 lg:px-4">
                    <div className="min-w-full divide-y divide-gray-300">
                      <ul className="flex flex-col divide-y divide-gray-200">
                        {filteredPlayers &&
                          filteredPlayers.map((player, idx) => {
                            let imageUrl = images.find((playerImage) =>
                              playerImage.includes(player.profile_url)
                            );
                            if (!imageUrl) {
                              imageUrl = images.find((playerImage) =>
                                playerImage.includes('unknown')
                              );
                            }
                            return (
                              <li
                                key={player.id}
                                className="m-1 cursor-pointer rounded-md bg-white"
                                onClick={(e: any) =>
                                  onSelect(e, player, imageUrl)
                                }
                              >
                                {generateTableData(player, idx, imageUrl)}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
