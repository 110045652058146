import {
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
} from '@supabase/postgrest-js';
import { Player } from '../players/api';
import { Team } from '../teams/api';
import { supabase } from '../utils';

interface History {
  id: string;
  league_id: string;
  message: string;
  created_at: string;
  recieve_player: Player;
  recieve_team: Team;
  sent_player: Player;
  sender_team: Team;
}

interface PostApiArgs {
  recieve_team: string;
  sender_team: string | null;
  recieve_player: string;
  sent_player: string;
  league_id: string;
}

export const getData = (
  id: string
): PostgrestFilterBuilder<any, any, History[]> => {
  return supabase
    .from('history')
    .select(
      '*, recieve_player (*), recieve_team (*), sent_player(*), sender_team (*)'
    )
    .eq('league_id', id);
};

export const postData = ({
  recieve_team,
  sender_team,
  recieve_player,
  sent_player,
  league_id,
}: PostApiArgs): PostgrestTransformBuilder<any, any, History[]> => {
  return supabase
    .from('history')
    .insert({
      recieve_team,
      sender_team,
      recieve_player,
      sent_player,
      league_id,
    })
    .select();
};
