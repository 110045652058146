import { ArrowLeftIcon, TrophyIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useLeague } from '../../../hooks/useLeague';
import { useDraft } from '../../../hooks/useDraft';

export const DraftResults = ({ back }) => {
  const { leagueId } = useParams();
  const { data: leagueData, error } = useLeague(leagueId);
  const { data, isLoading } = useDraft({ leagueId });

  const mapDraftResults = () => {
    const result = data?.map((result) => {
      return (
        <div
          className="grid grid-cols-[1fr_4fr_4fr] items-center gap-2 p-2"
          key={result.id}
        >
          <div className="text-sm font-semibold">{result.draft_number + 1}</div>
          <div className="text-left text-sm">
            {result.player_id.first_name} {result.player_id.last_name}
          </div>
          <div className="text-right text-sm">{result.team_id.name}</div>
        </div>
      );
    });
    return result;
  };

  const DraftResultSkeleton = () => (
    <div className="animate-pulse">
      <div className="grid grid-cols-[1fr_4fr_4fr] gap-2 p-2">
        {[...Array(5)].map((_, index) => (
          <React.Fragment key={index}>
            <div className="h-4 rounded bg-gray-300"></div>
            <div className="col-span-2 h-4 rounded bg-gray-300"></div>
            <div className="col-span-2 h-4 rounded bg-gray-300"></div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  if (isLoading) {
    return <>{DraftResultSkeleton()}</>;
  }

  if (!leagueData.isDrafted) {
    return (
      <div className="flex w-full flex-col">
        <div className="flex justify-between">
          <button onClick={back}>
            <ArrowLeftIcon width={20} height={20} />
          </button>
          <span>Result</span>
        </div>
        <div className="mt-2 flex h-auto rounded-md bg-white p-5 align-middle">
          <span className="text-center">
            Come back after the draft to check the results!
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className="flex w-full flex-col">
      <div className="flex justify-between">
        <button onClick={back}>
          <ArrowLeftIcon width={20} height={20} />
        </button>
        <span>Result</span>
      </div>
      <div className="mt-2 flex flex-col rounded-md bg-white">
        {data && mapDraftResults()}
      </div>
    </div>
  );
};
