import { useCallback } from 'react';
import { useSupabase } from '../hooksV2/useSupabase';

type SignUpProps = {
  email: string;
  password: string;
};

export const useRegisterUser = () => {
  const { supabase } = useSupabase();

  const signUp = useCallback(
    async ({ email, password }: SignUpProps) => {
      return supabase.auth.signUp({
        email,
        password,
      });
    },
    [supabase]
  );

  return {
    signUp,
  };
};
