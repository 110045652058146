import { useCallback } from 'react';
import { putData } from '../models/trades/api';
import { getData } from '../models/trades_players/api';
import { deleteData, getDataByTeamIds } from '../models/teamPlayer/api';
import {
  putData as putTeamPlayerData,
  postDataByTrade,
} from '../models/teamPlayer/api';
import { useParams } from 'react-router';
import { postData } from '../models/history/api';

export const useTradeMachine = () => {
  const { leagueId } = useParams();

  const createTeamPlayerObject = (list, teamId) => {
    const result = [];
    list.forEach((instance) => {
      result.push({
        league_id: leagueId,
        player_id: instance.player_id.id,
        team_id: teamId,
      });
    });
    return result;
  };

  const swapPlayers = async (id, teamIds) => {
    const { data: tradedPlayers } = await getData([id]);
    const { data } = await getDataByTeamIds({ teamIds });

    const sendPlayersList = tradedPlayers.filter(
      (player) => player.type === 'sender'
    );
    const receivePlayersList = tradedPlayers.filter(
      (player) => player.type === 'receiver'
    );

    const culledSendPlayerData = Object.values(data).filter((d) =>
      sendPlayersList.find((player) => player.player_id.id === d.player_id)
    );
    const culledReceivePlayerData = Object.values(data).filter((d) =>
      receivePlayersList.find((player) => player.player_id.id === d.player_id)
    );

    const teamPlayerSender = createTeamPlayerObject(
      receivePlayersList,
      teamIds[0]
    );
    const teamPlayerObjectReciever = createTeamPlayerObject(
      sendPlayersList,
      teamIds[1]
    );

    const { error: senderPostError } = await postDataByTrade(teamPlayerSender);
    const { error: receiverPostError } = await postDataByTrade(
      teamPlayerObjectReciever
    );
    const historyPayload = [];
    receivePlayersList.forEach((playerData, idx) => {
      historyPayload.push({
        recieve_team: teamIds[0] as string,
        recieve_player: sendPlayersList[idx].player_id.id as string,
        sender_team: teamIds[1],
        sent_player: playerData.player_id.id as string,
        league_id: leagueId as string,
      });
    });
    for (let i = 0; i < receivePlayersList.length; i++) {
      await postData(historyPayload[i]);
    }

    const deleteIds = [];

    culledSendPlayerData.forEach((instance) => {
      deleteIds.push(instance.id);
    });
    culledReceivePlayerData.forEach((instance) => {
      deleteIds.push(instance.id);
    });

    const { error } = await deleteData(deleteIds);
  };

  const determineTrade = useCallback(async (type, tradeId, transactionId) => {
    if (type === 'accepted') {
      const { data } = await putData('accepted', transactionId);
      const sendTeamId = data[0].sender_team_id;
      const receiveTeamId = data[0].receiver_team_id;
      const teamIds = [sendTeamId, receiveTeamId];
      await swapPlayers(transactionId, teamIds);
    }
    if (type === 'rejected') {
      await putData('rejected', transactionId);
    }
  }, []);

  return {
    determineTrade,
  };
};
