import React from 'react';
import ReactDOM from 'react-dom';

interface IModal {
  children: any;
  onClick?: () => void;
}

export const Modal: React.FC<IModal> = ({ children, onClick }) => {
  const portalElement = document.getElementById('portal');

  const ele = (
    <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
      <div
        className="h-full w-full bg-black opacity-5"
        role="button"
        tabIndex={0}
        aria-label="modal-bg"
        onClick={onClick}
        onKeyDown={onClick}
      />
      <div
        className="fixed z-50 flex h-fit w-fit flex-col items-center justify-center bg-slate-800"
        role="none"
      >
        {children}
      </div>
    </div>
  );

  return portalElement ? ReactDOM.createPortal(ele, portalElement) : null;
};
