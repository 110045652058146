import { Transition, Dialog } from '@headlessui/react';
import { useEffect, useState, Fragment } from 'react';
import { useMyTeam } from '../../../hooks/useMyTeam';
import { useUpdateTeam } from '../../../hooks/useUpdateTeam';
import { useSupabase } from '../../../hooksV2/useSupabase';
import { updateData } from '../../../models/teams/api';
import { useQueryClient } from 'react-query';

export const MyTeamLeagueCreation = ({ leagueId, onClose, onSuccess }) => {
  const { data: myTeamData, isLoading } = useMyTeam(leagueId);
  const [image, setImage] = useState<Blob>();
  const [preview, setPreview] = useState<string>();
  const { supabase } = useSupabase();
  const queryClient = useQueryClient();
  const [form, setForm] = useState({
    teamName: '',
  });

  useEffect(() => {
    if (!isLoading && myTeamData) {
      setForm({
        teamName: myTeamData.name,
      });
    }
  }, [isLoading]);

  const uploadFile = async (file, payload) => {
    await supabase.storage
      .from('team-logos')
      .upload(`${leagueId}/${myTeamData.id}`, file, {
        contentType: file.type, // Set the MIME type here
        upsert: true, // Set to true if you want to overwrite existing files with the same name
      });
    await updateData({
      ...payload,
      pic_url: `${leagueId}/${myTeamData.id}`,
    });
    onClose();
  };

  const onSaveHandler = async (e) => {
    e.preventDefault();
    const payload = {
      ...myTeamData,
      name: form.teamName,
    };
    await uploadFile(image, payload);
    queryClient.invalidateQueries(['useTeamsByLeague', leagueId]);
    queryClient.invalidateQueries(['useTeam', myTeamData.id]);
    queryClient.invalidateQueries(['usePlayerTeamByLeagueId', leagueId]);
    onSuccess(`/league/${leagueId}`);
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image')) {
      setImage(file);

      // Create a URL for the image file for preview
      const url = URL.createObjectURL(file);
      setPreview(url);
    }
  };

  const getDefaultPhoto = async () => {
    const { data, error } = await supabase.storage
      .from('team-logos')
      .download(`${leagueId}/${myTeamData.id}`);
    if (data) {
      const url = URL.createObjectURL(data);
      setPreview(url);
      setImage(data);
    } else {
      const { data: defaultData } = await supabase.storage
        .from('team-logos')
        .download('user-default.png');
      const url = URL.createObjectURL(defaultData);
      setPreview(url);
      setImage(defaultData);
    }
  };

  useEffect(() => {
    if (myTeamData) {
      getDefaultPhoto();
    }
  }, [myTeamData]);

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onSuccess(`/league/${leagueId}`);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div>
                  <div className="mt-5 flex w-full justify-center">
                    <form
                      className="space-y-8 divide-y divide-gray-200"
                      onSubmit={(e: any) => onSaveHandler(e)}
                    >
                      <div className="space-y-8 divide-y divide-gray-200 rounded-md bg-primary p-5 sm:space-y-5">
                        <div className="space-y-6 sm:space-y-5">
                          <div className="space-y-6 sm:space-y-5">
                            <div className="flex flex-col">
                              <label
                                htmlFor="profilePic"
                                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                              >
                                Upload Team Picture
                              </label>
                              <div className="my-2 flex w-full items-center justify-around">
                                <div className="m-2 my-2 h-auto">
                                  <img
                                    src={preview}
                                    alt="team picture"
                                    height={50}
                                    width={50}
                                    className="my-4 flex aspect-square items-center rounded-full bg-white object-cover p-1 text-xs"
                                  />
                                </div>
                                <label
                                  htmlFor="file-input"
                                  className="cursor-pointer rounded bg-white px-4 py-1"
                                >
                                  Upload File
                                </label>
                                <input
                                  type="file"
                                  id="file-input"
                                  accept="image/*"
                                  className="hidden"
                                  onChange={handleImageChange}
                                />
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <label
                                htmlFor="name"
                                className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                              >
                                Team Name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="teamName"
                                  id="teamName"
                                  className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  value={form.teamName}
                                  onChange={onChangeHandler}
                                  maxLength={15}
                                  placeholder="(Max 15 chars.)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-5">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                          >
                            SAVE
                          </button>
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                            onClick={() => onSuccess(`/league/${leagueId}`)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
