import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { getCurrentYear } from '../utils/utils';
import { usePlayersScoring } from './usePlayersScoring';
import { usePlayerTeamByLeagueId } from './usePlayerTeamByLeagueId';

export const usePlayerResultsByTeamId = (teamId) => {
  const { leagueId } = useParams();
  const { data: playerTeamData, error } = usePlayerTeamByLeagueId(leagueId);
  const [players, setPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getPlayersResults } = usePlayersScoring();

  const fetchPlayers = useCallback(async () => {
    const golfers = playerTeamData
      .filter((player) => player.team_id.id === teamId)
      .map((player) => player.player_id);
    const { playersWithScores } = await getPlayersResults(
      golfers,
      getCurrentYear()
    );
    setIsLoading(false);
    setPlayers(playersWithScores);
  }, [playerTeamData, teamId]);

  useEffect(() => {
    if (playerTeamData && teamId) {
      setIsLoading(true);
      fetchPlayers();
    }
  }, [playerTeamData, teamId]);

  return {
    data: players,
    isLoading,
    error: error,
  };
};
