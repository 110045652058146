import { getDataByLeagueCode } from '../models/leagues/api';
import { useQuery } from 'react-query';

export const useLeagueByCode = (args) => {
  return useQuery(
    ['useLeagueByCode', args],
    async () =>
      getDataByLeagueCode({ code: args }).then((result) => result.data![0]),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      enabled: !!args,
    }
  );
};
