import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useJoinLeague } from '../../../hooks/useJoinLeague';
import { useLeagueByCode } from '../../../hooks/useLeagueByCode';
import pic from '../../../assets/newsfeed_placeholder.jpg';

export const JoinedLeagueModal = ({ onClose, leagueCode }) => {
  const { data, error } = useLeagueByCode(leagueCode);
  const { joinLeague } = useJoinLeague();
  const navigate = useNavigate();

  const onJoinLeagueHandler = async () => {
    const { data } = await joinLeague({ code: leagueCode });
    navigate(`/league/${data}?newUser=true`);
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex w-full flex-col">
                  {data && (
                    <>
                      <div className="flex w-full flex-col items-center justify-center">
                        <span className="text-center text-lg font-semibold">
                          You have been invited to join the league {data.name}!
                        </span>
                      </div>
                      <img src={pic} className="my-5 rounded-md" />
                      <div>
                        <div className="flex flex-col items-center justify-center gap-2">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                            onClick={onJoinLeagueHandler}
                          >
                            JOIN
                          </button>
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                            onClick={onClose}
                          >
                            CANCEL
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
