import React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Router } from './components/app/router';
import { QueryClientProvider, QueryClient } from 'react-query';
import { SupabaseProvider } from './providers/supabaseProvider';
import { ErrorBoundary } from './components/pages/errorBoundry';
import './styles/tailwind.css';

const queryClient = new QueryClient();

function App() {
  // google and facebook need to auth providers need to be configured
  return (
    <ErrorBoundary>
      <SupabaseProvider>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </SupabaseProvider>
    </ErrorBoundary>
  );
}

export default App;
