import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLeague } from '../../hooks/useLeague';
import { useMyTeam } from '../../hooks/useMyTeam';
import { useNotifications } from '../../hooks/useNotifications';
import { useTrades } from '../../hooks/useTrades';
import { MobileNavigation } from '../app/mobileNavigation';
import { LeagueHeader } from '../organisms/leagueHeader';
import {
  ArrowsRightLeftIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
} from '@heroicons/react/24/outline';
import { useTradeMachine } from '../../hooks/useTradeMachine';
import { useQueryClient } from 'react-query';
import { useSupabase } from '../../hooksV2/useSupabase';
import { Modal } from '../app/modal';
import { PlayerModal } from '../app/modals/playerModal';
import NoNotification from '../../assets/nonotification.png';

export const Notifications = () => {
  const { leagueId } = useParams();
  const { data: myTeamData } = useMyTeam();
  const { supabase } = useSupabase();
  const { data: leagueData } = useLeague(leagueId);
  const { data: notData } = useNotifications();
  const { determineTrade } = useTradeMachine();
  const [images, setImages] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [selectedPlayer, setSelectedPlayer] = useState<any>();
  const queryClient = useQueryClient();

  const onClickTradeSubmission = async (e, type, tradeId, transactionId) => {
    e.preventDefault();
    if (type === 'accept') {
      await determineTrade('accepted', tradeId, transactionId);
      queryClient.invalidateQueries(['useTrades', myTeamData.id]);
    }
    if (type === 'reject') {
      await determineTrade('rejected', tradeId, transactionId);
      queryClient.invalidateQueries(['useTrades', myTeamData.id]);
    }
  };

  const onClickDeleteTrade = async (tradeId, transactionId) => {
    await determineTrade('rejected', tradeId, transactionId);
    queryClient.invalidateQueries(['useTrades', myTeamData.id]);
  };

  const fetchPlayerImages = async (notifications) => {
    const players = notifications.reduce((acc, curr) => {
      const allPlayersForNotification = curr.players.map(
        (player) => player.player_id.profile_url
      );
      acc = [...acc, ...allPlayersForNotification];
      return acc;
    }, []);

    const playerSet = new Set<string>(players);
    const promises = [];
    playerSet.forEach((instance) => {
      const result = supabase.storage.from('golfers').getPublicUrl(instance);
      promises.push(result);
    });

    const results = await Promise.all(promises);
    const payload = results.map((data) => data.data.publicUrl);
    setImages(payload);
  };

  useEffect(() => {
    if (notData.length !== 0 && images.length === 0) {
      fetchPlayerImages(notData);
    }
  }, [notData, images]);

  const onClickPlayerHandler = (player, image) => {
    setSelectedImage(image);
    setSelectedPlayer(player);
  };

  const onClosePlayerHandler = () => {
    setSelectedImage(undefined);
    setSelectedPlayer(undefined);
  };

  const generateNotifications = (d) => {
    if (d.length === 0) {
      return (
        <div className="mx-2 flex flex-col items-center justify-center gap-4">
          <div className="mt-24 text-center">No notifications to display.</div>
          <img
            className="aspect-square rounded-lg object-cover"
            src={NoNotification}
            alt=""
          />
        </div>
      );
    }
    const result = d.map((instance) => {
      const { name: senderName, id: senderId } = instance.sender_team_id;
      const { name: receiverName, id: receiverId } = instance.receiver_team_id;
      const senderPlayers = instance.players
        ? instance.players.filter((p) => p.type === 'sender')
        : [];
      const receiverPlayers = instance.players
        ? instance?.players.filter((p) => p.type === 'receiver')
        : [];

      return (
        <div
          key={instance.id}
          className="mx-2 flex flex-col items-center justify-center rounded-md border-2 border-black p-2"
        >
          <div className="grid w-full grid-cols-3">
            <div className="ml-10 flex flex-col items-center">
              <span className="font-semibold">Sender</span>
              <div className="whitespace-nowrap text-center text-xs font-medium">
                {senderName}
              </div>
              <ul className="my-4 flex h-full w-full flex-col justify-center gap-4">
                {senderPlayers.map((player) => {
                  const playerImage = images.find((image) =>
                    image.includes(player.player_id.profile_url)
                  );
                  return (
                    <button
                      key={player.player_id.id}
                      className="flex h-[35px] flex-row items-center gap-2"
                      onClick={() => onClickPlayerHandler(player, playerImage)}
                    >
                      <img
                        src={playerImage}
                        alt="golfer image"
                        height={30}
                        width={30}
                        className="rounded-full border"
                      />
                      <span className="text-left text-sm">
                        {player.player_id.abbr_name}
                      </span>
                    </button>
                  );
                })}
              </ul>
            </div>
            <div className="mt-2 flex flex-col items-center">
              <ArrowsRightLeftIcon height={30} width={30} />
            </div>
            <div className="-ml-10 flex flex-col items-center">
              <span className="font-semibold">Receiver</span>
              <div className="whitespace-nowrap text-center text-xs font-medium">
                {receiverName}
              </div>
              <ul className="my-4 flex h-full w-full flex-col justify-center gap-4">
                {receiverPlayers.map((player) => {
                  const playerImage = images.find((image) =>
                    image.includes(player.player_id.profile_url)
                  );
                  return (
                    <button
                      key={player.player_id.id}
                      className="flex h-[35px] flex-row items-center gap-2"
                      onClick={() => onClickPlayerHandler(player, playerImage)}
                    >
                      <img
                        src={playerImage}
                        alt="golfer image"
                        height={30}
                        width={30}
                        className="rounded-full border"
                      />
                      <span className="text-left text-sm">
                        {player.player_id.abbr_name}
                      </span>
                    </button>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="my-2 flex w-full flex-row justify-center gap-2">
            {senderId === myTeamData.id && (
              <>
                <div className="flex flex-row items-center gap-2 rounded-md border-2 p-2">
                  <span>Pending</span>
                </div>
                <div>
                  <button
                    className="flex flex-row items-center gap-2 rounded-md border-2 p-2"
                    onClick={() =>
                      onClickDeleteTrade(instance.id, instance.transaction_id)
                    }
                  >
                    Cancel Trade
                  </button>
                </div>
              </>
            )}
            {senderId !== myTeamData?.id && (
              <>
                <button
                  type="button"
                  className="flex flex-row items-center gap-2 rounded-md border-2 border-primary p-2 text-primary"
                  onClick={(e) =>
                    onClickTradeSubmission(
                      e,
                      'accept',
                      instance.id,
                      instance.transaction_id
                    )
                  }
                >
                  <HandThumbUpIcon height={30} width={30} />
                  <span className="text-sm text-black">Accept Trade</span>
                </button>
                <button
                  type="button"
                  onClick={(e) =>
                    onClickTradeSubmission(
                      e,
                      'reject',
                      instance.id,
                      instance.transaction_id
                    )
                  }
                  className="flex flex-row items-center gap-2 rounded-md border-2 border-accent p-2 text-accent"
                >
                  <HandThumbDownIcon height={30} width={30} />
                  <span className="text-sm text-black">Reject Trade</span>
                </button>
              </>
            )}
          </div>
        </div>
      );
    });
    return result;
  };

  return (
    <div>
      <LeagueHeader leagueId={leagueData?.id} name={leagueData?.name} />
      <div className="my-5 mb-20 flex w-full flex-col gap-2">
        {notData && generateNotifications(notData)}
      </div>
      <MobileNavigation />
      {selectedImage && selectedPlayer && (
        <Modal>
          <PlayerModal
            imageUrl={selectedImage}
            player={selectedPlayer.player_id}
            onClose={onClosePlayerHandler}
          />
        </Modal>
      )}
    </div>
  );
};
