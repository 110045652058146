import { putLeagueNameData, updateLeague } from '../models/leagues/api';

export const useUpdateLeague = () => {
  const updateLeagueName = ({ name, id }) => {
    return putLeagueNameData(name, id);
  };

  const updateLeagueData = async (league) => {
    return updateLeague(league);
  };

  return {
    updateLeagueName,
    updateLeagueData,
  };
};
