import { supabase } from '../utils';

export const getData = (id) => {
  return supabase.from('messages').select('*').eq('league_id', id);
};

export const postData = (message, teamId, leagueId) => {
  return supabase.from('messages').insert({
    value: message,
    team_id: teamId,
    league_id: leagueId,
  });
};
