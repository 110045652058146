import { supabase } from '../utils';
import {
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestError,
} from '@supabase/postgrest-js';
import { Team } from '../teams/api';
import { Tournament } from '../tournaments/api';

interface TeamScore {
  id: string;
  created_at: Date;
  score: number;
  team_id: Team;
  tournament_id: Tournament;
}

export interface ApiResponse {
  data: TeamScore[];
  error: PostgrestError;
}

export interface ApiArgs {
  teamId: string;
}

export const getData = (
  teamId: ApiArgs
): PostgrestFilterBuilder<any, any, TeamScore[]> => {
  return supabase
    .from('scores')
    .select('*, tournament_id (*), team_id (*)')
    .eq('team_id', teamId);
};

export const getDataByLeagueId = (
  leagueId: string
): PostgrestFilterBuilder<any, any, TeamScore[]> => {
  return supabase
    .from('scores')
    .select('*, tournament_id (*), team_id (*)')
    .eq('league_id', leagueId);
};
