import { supabase } from '../utils';
import { PostgrestFilterBuilder } from '@supabase/postgrest-js';

export type User = {
  first_name: string | null;
  last_name: string | null;
  id: string;
  leagues: string[] | null;
  username: string | null;
  email: string;
  isNew: boolean;
};
export interface ApiResponse {
  data: User[];
}

export interface ApiArgs {
  id: string;
}

export interface PutApiArgs {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
}

export const getData = ({
  id,
}: ApiArgs): PostgrestFilterBuilder<any, any, User[]> => {
  return supabase.from('users').select('*').eq('id', id).throwOnError();
};

export const putData = ({
  id,
  firstName,
  lastName,
  username,
}: PutApiArgs): PostgrestFilterBuilder<any, any, User[] | null> => {
  return supabase
    .from('users')
    .update({
      username,
      first_name: firstName,
      last_name: lastName,
      isNew: false,
    })
    .eq('id', id)
    .throwOnError();
};
