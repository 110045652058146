import { ArrowLeftIcon, TrophyIcon } from '@heroicons/react/24/outline';
import { SCORING_SYSTEM } from '../../../utils/constants';
import { appendOrdinalSuffix } from '../../../utils/utils';
import { useState } from 'react';
import Dropdown from 'react-dropdown';

const dropdownOptions = [
  {
    value: 'regular',
    label: 'Regular',
  },
  {
    value: 'signature',
    label: 'Signature',
  },
  {
    value: 'major',
    label: 'Major',
  },
  {
    value: 'playoffs',
    label: 'Playoffs',
  },
];

export const ScoringSettings = ({ back }) => {
  const [type, setType] = useState('regular');

  const onOptionSelectHandler = (value) => {
    setType(value.value);
  };

  const determineMultiplier = () => {
    if (type === 'regular') return 1;
    if (type === 'signature') return 1.5;
    if (type === 'major') return 2;
    if (type === 'playoffs') return 2;
    return 1;
  };

  const generateScoringTable = () => {
    const scoringEntries = Object.entries(SCORING_SYSTEM).map(
      ([place, points], idx) => (
        <tr key={idx}>
          <td className="flex items-center">
            {idx === 0 && (
              <TrophyIcon className="text-accent" width={20} height={20} />
            )}
          </td>
          <td>{appendOrdinalSuffix(parseInt(place))}</td>
          <td>{points * determineMultiplier()}</td>
        </tr>
      )
    );

    return (
      <table className="w-64 divide-y divide-gray-200 text-sm leading-tight">
        <thead>
          <tr className="text-right">
            <th></th>
            <th>Place</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white text-right">
          {scoringEntries}
        </tbody>
      </table>
    );
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center justify-between">
        <button onClick={back}>
          <ArrowLeftIcon width={20} height={20} />
        </button>
        <span>Scoring Rules</span>
        <Dropdown
          className="w-min-[15ch] z-10 flex items-center"
          options={dropdownOptions}
          onChange={onOptionSelectHandler}
          value={type}
        />
      </div>
      <div className="mt-2 overflow-x-auto rounded-md bg-white p-4">
        {generateScoringTable()}
      </div>
    </div>
  );
};
