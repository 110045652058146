import { useParams } from 'react-router-dom';
import { useLeague } from '../../hooks/useLeague';
import { useTeamsByLeague } from '../../hooks/useTeamsByLeague';
import { MobileNavigation } from '../app/mobileNavigation';
import { LeagueHeader } from '../organisms/leagueHeader';
import { Link } from 'react-router-dom';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useImages } from '../../hooks/useImages';
import { formatNumberWithCommas, formatReadableDate } from '../../utils/utils';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  ArrowLeftIcon,
  ArrowsRightLeftIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
  ArrowRightIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import { Modal } from '../app/modal';
import { InviteTeamsModal } from '../app/modals/inviteTeamsModal';
import { useMyTeam } from '../../hooks/useMyTeam';
import { useUser } from '../../hooks/useUser';
import { NewTeamEditModal } from '../app/modals/newTeamEditModal';
import { useTeamScores } from '../../hooks/useTeamScores';
import { useHistory } from '../../hooks/useHistory';
import LeagueHistoryModal from '../app/modals/leagueHistoryModal';

export const LeagueOverviewPage = () => {
  const { leagueId } = useParams();
  const [searchParams] = useSearchParams();
  const { data } = useLeague(leagueId);
  const { data: teamsData } = useTeamsByLeague(leagueId);
  const { images } = useImages(teamsData, 'team-logos');
  const { data: teamScores } = useTeamScores(teamsData, leagueId);
  const [showInvitePopup, setShowInvitePopup] = useState<boolean>();
  const [showTeamEditPopup, setShowTeamEditPopup] = useState<boolean>();
  const { data: myUserData } = useUser();
  const { data: historyData } = useHistory({ leagueId });
  const [showHistory, setShowHistory] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const onClickInviteHandler = () => {
    setShowInvitePopup(true);
  };

  const onCloseInviteHandler = () => {
    setShowInvitePopup(false);
  };

  const removeQueryParams = () => {
    navigate(location.pathname, { replace: true });
  };

  const copyToClipboard = async (code) => {
    try {
      await navigator.clipboard.writeText(code);
      toast.success('Code copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const onCloseHandler = () => {
    setShowInvitePopup(false);
    setShowTeamEditPopup(false);
    setShowHistory(false);
    removeQueryParams();
  };

  useEffect(() => {
    if (searchParams.get('newUser')) {
      setShowTeamEditPopup(true);
    }
  }, [searchParams]);

  if (teamScores) {
    teamScores?.sort((a, b) => b.score - a.score);
  }

  const onClickLeagueHistory = () => {
    setShowHistory(true);
  };

  const generateHistoryData = (data) => {
    data.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    const results = data.map((historyInstance) => {
      const {
        recieve_player,
        recieve_team,
        sender_team,
        sent_player,
        created_at,
      } = historyInstance;
      return (
        <div
          className="flex w-full flex-col justify-center gap-2 p-2"
          key={created_at}
        >
          <span className="text-xs font-semibold">
            {formatReadableDate(created_at)}
          </span>
          <div className="grid w-full grid-cols-5 gap-2">
            <div className="col-span-2 flex flex-col items-start justify-center">
              <span className="text-xs">{recieve_team.name}</span>
              <span>{recieve_player.name}</span>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              <ArrowsRightLeftIcon height={24} width={24} />
            </div>
            <div className="col-span-2 flex flex-col items-start justify-center">
              <span className="text-xs">
                {sender_team?.name ?? 'Free Agent Pickup'}
              </span>
              <span>{sent_player.name}</span>
            </div>
          </div>
        </div>
      );
    });
    return results;
  };
  return (
    <>
      <div className="min-h-full overflow-x-hidden">
        <div className="bg-gray-800">
          <LeagueHeader leagueId={leagueId} name={data?.name} />
        </div>
        <div className="flex flex-row justify-center">
          {!data ||
            (!data?.isDrafted && (
              <>
                <div className="flex items-center justify-center">
                  <button
                    type="button"
                    onClick={() => navigate(`/league/${leagueId}/draft`)}
                    className="font-xlg m-2 flex items-center justify-center rounded-lg border-2 border-accent p-3 font-medium focus:bg-primary"
                  >
                    Enter the Draft!
                  </button>
                </div>
                {teamsData?.find((team) => !team.user_id) &&
                  myUserData?.id === data?.commissioner_id && (
                    <div className="flex items-center gap-2">
                      <div
                        className="my-2 flex justify-center"
                        onClick={onClickInviteHandler}
                      >
                        <div>
                          <button
                            type="button"
                            className="flex flex-col items-center rounded-md border-2 border-[#94a3b8] p-2 text-xs"
                          >
                            <EnvelopeIcon height={20} width={20} />
                            <span className="ml-2 text-xs">Invite Teams</span>
                          </button>
                        </div>
                      </div>
                      <div className="my-2 flex justify-center">
                        <button
                          type="button"
                          onClick={() => copyToClipboard(data?.code ?? '')}
                          className="flex flex-col items-center rounded-md border-2 border-[#94a3b8] p-2 text-xs"
                        >
                          <PencilSquareIcon height={20} width={20} />
                          <span>Copy code</span>
                        </button>
                      </div>
                    </div>
                  )}
              </>
            ))}
        </div>
        <main className="flex w-screen flex-col items-center bg-primary pb-16">
          <div className="flex w-5/6 flex-row justify-between rounded-md bg-primary"></div>
          <div className="m-5 flex w-5/6 flex-col rounded-md bg-white">
            <div className="flex flex-row justify-end">
              <button
                onClick={onClickLeagueHistory}
                className="m-2 w-full rounded-md bg-primary p-2 text-xs font-semibold text-white"
              >
                Weekly History
              </button>
            </div>
            <div className="mt-2 flex w-full justify-between px-5 font-semibold text-black">
              <div></div>
              <span className="mr-12">Team</span>
              <span>Total PTS</span>
            </div>
            <ul className="flex flex-col gap-5 px-5 pb-5 pt-5">
              {teamsData &&
                images.length > 0 &&
                teamScores &&
                teamScores.map((team, idx) => (
                  <li className="flex justify-between" key={team.id}>
                    <div className="flex w-full flex-row items-center justify-between">
                      <div className="flex items-center gap-2">
                        <span className="mr-2 w-1 grow-0 text-sm">
                          {idx + 1}
                        </span>
                        <Link
                          to={`/league/${leagueId}/team/${team.team_id.id}`}
                          key={team.id}
                          className="flex items-center gap-2"
                        >
                          <img
                            src={
                              images.find((image) => image[team.team_id.id])[
                                team.team_id.id
                              ]
                            }
                            width={30}
                            height={30}
                            className="aspect-square rounded-full border border-gray-500 object-cover"
                          />
                          <span className="w-[15ch] overflow-x-clip underline">
                            {team.team_id.name}
                          </span>
                        </Link>
                        {!team.team_id.user_id && (
                          <>
                            <ExclamationTriangleIcon
                              height={20}
                              width={20}
                              className="text-orange-700"
                              data-tooltip-id="waiting"
                              data-tooltip-content="Waiting for team to join"
                            />
                            <Tooltip id="waiting" />
                          </>
                        )}
                      </div>
                      <div>
                        {data?.isDrafted && (
                          <div>{formatNumberWithCommas(team.score)}</div>
                        )}
                        {!data?.isDrafted && (
                          <div className="mr-7 text-gray-500">-</div>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div className="m-5 flex w-5/6 flex-col rounded-md bg-white">
            <span className="ml-2 mt-2 font-semibold">Recent Activity</span>
            <div>
              {historyData?.length > 0 && generateHistoryData(historyData)}
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
      <MobileNavigation />
      {showInvitePopup && leagueId && (
        <Modal>
          <InviteTeamsModal
            onClose={onCloseInviteHandler}
            leagueId={leagueId}
          />
        </Modal>
      )}
      {showTeamEditPopup && data && (
        <Modal>
          <NewTeamEditModal onClose={onCloseHandler} league={data} />
        </Modal>
      )}
      {showHistory && (
        <Modal>
          <LeagueHistoryModal
            onClose={onCloseHandler}
            leagueId={leagueId}
            images={images}
          />
        </Modal>
      )}
    </>
  );
};
