import { useQuery } from 'react-query';
import { getDataByLeagueId } from '../models/teamPlayer/api';

export const usePlayerTeamByLeagueId = (leagueId: string) => {
  return useQuery(
    ['usePlayerTeamByLeagueId', leagueId],
    async () => getDataByLeagueId(leagueId).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      enabled: !!leagueId,
    }
  );
};
