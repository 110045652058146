import React from 'react';
import { NewsFeed } from '../organisms/newsFeed';
import { MobileNavigation } from '../app/mobileNavigation';

export function NewsPage() {
  return (
    <div className="-mt-28 mb-24">
      <NewsFeed />
      <MobileNavigation />
    </div>
  );
}
