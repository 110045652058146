import { useQuery } from 'react-query';
import { getData } from '../models/teamScores/api';

export const useTeamScores = (args, leagueId) => {
  return useQuery(
    ['useTeamScores', leagueId],
    async () => getData({ teamData: args }).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      enabled: args?.length > 0 && !!leagueId,
    }
  );
};
