import { supabase } from '../utils';
import {
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestError,
} from '@supabase/postgrest-js';
import { Team } from '../teams/api';
import { Tournament } from '../tournaments/api';

interface TeamPlayerHistory {
  id: string;
  created_at: Date;
  score: number;
  team_id: Team;
  tournamentId: Tournament;
}

export interface ApiResponse {
  data: TeamPlayerHistory[];
  error: PostgrestError;
}

export interface ApiArgs {
  teamId: string;
  tournamentId: string;
}

export const getData = ({
  teamId,
  tournamentId,
}: ApiArgs): PostgrestFilterBuilder<any, any, TeamPlayerHistory[]> => {
  return supabase
    .from('team_player_history')
    .select('*, player (*), results (*)')
    .eq('team', teamId)
    .eq('tournament', tournamentId);
};
