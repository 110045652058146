import React from 'react';
import { SupabaseClient, User } from '@supabase/supabase-js';
import { useContext } from 'react';
import { SupabaseContext } from '../providers/supabaseProvider';

type Data = {
  supabase: SupabaseClient<any, 'public', any>;
  user: any;
  isLoading: boolean;
  setUser: React.Dispatch<React.SetStateAction<{} | User | undefined>>;
};

export const useSupabase = (): Data => {
  const { supabase, user, isLoading, setUser } = useContext(SupabaseContext);
  return {
    supabase,
    user,
    isLoading,
    setUser,
  };
};
