import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { LoadingSkeleton } from '../../organisms/loadingSkeleton';
import { MyTeam } from './myTeam';

export const NewTeamEditModal = ({ league, onClose }) => {
  if (!league) {
    <div className="my-36 flex w-full justify-center">
      <LoadingSkeleton />
    </div>;
  }

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col">
                    <span className="text-2xl font-semibold">
                      {league.name}
                    </span>
                    <span className="text-sm">League Settings</span>
                  </div>
                  <MyTeam back={onClose} />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
