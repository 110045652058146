import { Transition, Dialog } from '@headlessui/react';
import { XCircleIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useTournamentsByPlayer } from '../../../hooks/useTournamentsByPlayer';
import {
  calculateAge,
  inchesToFeetAndInches,
  yearsDifferenceFromCurrent,
  getTournamentYear,
  getPreviousYear,
} from '../../../utils/utils';

export const PlayerDraftModal = ({
  imageUrl,
  player,
  onClose,
  onSubmit,
  isMyPick,
}) => {
  const { data } = useTournamentsByPlayer(player.id, getPreviousYear());

  const sortedInstance = data?.sort((a, b) => {
    // Convert date strings to Date objects for comparison
    const dateA: any = new Date(a.tournament_id.start_date);
    const dateB: any = new Date(b.tournament_id.start_date);

    // Compare the dates
    return dateB - dateA;
  });

  const mapTournamentData = (tournamentData) => {
    let top10positions = 0;
    let tournamentsPlayedIn = 0;
    let cutsMade = 0;
    let wins = 0;
    tournamentData.forEach((tournament) => {
      const { position } = tournament;
      tournamentsPlayedIn += 1;
      if (position <= 70) cutsMade += 1;
      if (position <= 10) top10positions += 1;
      if (position === 1) wins += 1;
    });
    return (
      <div className="mt-2 flex flex-col gap-4 rounded-lg bg-white p-5">
        <div className="flex items-center justify-between">
          <span className="text-xs">Events Played</span>
          <span className="ml-[2ch] h-px grow border"></span>
          <span className="ml-2 w-[3ch] text-right">{tournamentsPlayedIn}</span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-xs">Cuts Made</span>
          <span className="ml-[2ch] h-px grow border"></span>
          <span className="ml-2 w-[3ch] text-right">{cutsMade}</span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-xs">Top 10</span>
          <span className="ml-[2ch] h-px grow border"></span>
          <span className="ml-2 w-[3ch] text-right">{top10positions}</span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-xs">Wins</span>
          <span className="ml-[2ch] h-px grow border"></span>
          <span className="ml-2 w-[3ch] text-right">{wins}</span>
        </div>
      </div>
    );
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex flex-col items-center justify-center">
                  <button
                    className="absolute right-0 top-0 m-1 flex w-full justify-end"
                    onClick={onClose}
                  >
                    <XCircleIcon height={30} width={30} />
                  </button>
                  <div className="flex flex-row items-center">
                    <img
                      className="h-20 w-20 rounded-full"
                      src={imageUrl ?? ''}
                      alt=""
                    />
                    <div className="ml-2 flex flex-col justify-center">
                      <span>
                        {player.first_name} {player.last_name}
                      </span>
                      <div className="mr-20 grid w-fit grid-cols-[1fr,1fr,1fr,.5fr,.5fr] gap-2">
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">AGE</span>
                          <span className="text-black">
                            {calculateAge(player.birthday)}
                          </span>
                        </div>
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">HEIGHT</span>
                          <span className="text-black">
                            {inchesToFeetAndInches(player.height)}
                          </span>
                        </div>
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">WEIGHT</span>
                          <span className="text-black">{player.weight}</span>
                        </div>
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">EXP</span>
                          <span className="text-black">
                            {yearsDifferenceFromCurrent(player.turned_pro)}
                          </span>
                        </div>
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">HAND</span>
                          <span className="text-black">
                            {player.handedness}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-2 w-full">
                    <div className="flex items-center justify-center">
                      <button
                        type="button"
                        onClick={(e) => (isMyPick ? onSubmit(e) : '')}
                        className={`inline-flex w-full justify-center rounded-md border border-gray-900 ${
                          isMyPick ? 'bg-primary' : 'bg-slate-400'
                        } px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none`}
                      >
                        Draft
                      </button>
                    </div>
                  </div>
                  <div className="w-full">
                    {sortedInstance && (
                      <span className="text-lg font-semibold">
                        {sortedInstance[0].year ?? getTournamentYear()}{' '}
                        Tournament Summary
                      </span>
                    )}
                    {!sortedInstance && (
                      <span className="text-lg font-semibold">
                        Season Tournaments
                      </span>
                    )}
                    <div>
                      {sortedInstance && mapTournamentData(sortedInstance)}
                      {!sortedInstance && (
                        <div className="w-full">
                          {/* Tournaments Title Placeholder */}
                          <div className="mb-2 h-4 w-40 animate-pulse bg-gray-300"></div>
                          {/* Tournaments List Placeholder */}
                          {[...Array(3)].map((_, index) => (
                            <div
                              className="mt-2 grid animate-pulse grid-cols-[3fr,1fr,1fr] items-center gap-4"
                              key={index}
                            >
                              {/* Tournament Name and Start Date Placeholders */}
                              <div className="flex flex-col">
                                <div className="mb-1 h-4 w-3/4 bg-gray-300"></div>
                                <div className="h-4 w-1/2 bg-gray-300"></div>
                              </div>
                              {/* Position Placeholder */}
                              <div className="flex flex-col">
                                <div className="mb-1 h-2 w-24 bg-gray-300"></div>
                                <div className="h-4 w-8 bg-gray-300"></div>
                              </div>
                              {/* PTS Placeholder */}
                              <div className="flex flex-col">
                                <div className="mb-1 h-2 w-12 bg-gray-300"></div>
                                <div className="h-4 w-8 bg-gray-300"></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
