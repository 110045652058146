import React, { useEffect, useMemo, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import { useTeams } from '../../hooks/useTeams';
import { TeamManagementSection } from '../organisms/teamManagmentSection';
import { withPageAuthRequired } from '../hoc/withPageAuthRequired';
import { LoadingSkeleton } from '../organisms/loadingSkeleton';
import { MobileNavigation } from '../app/mobileNavigation';
import Header from '../organisms/header';
import { useSearchParams } from 'react-router-dom';
import { Modal } from '../app/modal';
import { JoinedLeagueModal } from '../app/modals/joinedLeagueModal';
import { useJoinLeague } from '../../hooks/useJoinLeague';
import { useLeagueByCode } from '../../hooks/useLeagueByCode';
import { DashboardUserAccountSettings } from '../app/modals/DashboardUserAccountSettings';
import { useSupabase } from '../../hooksV2/useSupabase';
import { ChangePassword } from '../app/modals/changePassword';

export const DashboardPage = withPageAuthRequired(() => {
  const { data, isFetched } = useUser();
  const { user } = useSupabase();
  const { data: teamData, isFetched: isTeamDataFetched } = useTeams(data);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showJoinedPopup, setShowJoinedPopup] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const [showUserSettingsPopup, setShowUserSettingsPopup] =
    useState<boolean>(false);
  const [joinedLeagueCode, setJoinedLeagueCode] = useState('');
  const { data: leagueData } = useLeagueByCode(
    searchParams.get('code') ?? null
  );

  const loading = useMemo(
    () => !isFetched || !isTeamDataFetched,
    [isFetched, isTeamDataFetched]
  );

  const onCloseHandler = () => {
    setJoinedLeagueCode(undefined);
    setShowJoinedPopup(false);
    setShowChangePassword(false);
  };

  const onCloseHandlerSettings = () => {
    setShowUserSettingsPopup(false);
  };

  useEffect(() => {
    const code = searchParams.get('code');
    if (leagueData) {
      setShowJoinedPopup(true);
      setJoinedLeagueCode(code);
    }
  }, [leagueData]);

  useEffect(() => {
    if (data) {
      if (data.isNew) {
        setShowUserSettingsPopup(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (user.showPasswordRecovery) {
      setShowChangePassword(true);
    }
  }, [user]);

  return (
    <>
      <div className="min-h-full">
        <header>
          <Header />
        </header>
        <main>
          <div className="mx-auto max-w-7xl  px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-3xl">
              <div className="mx-auto mb-[60px] max-w-7xl py-6 sm:px-6 lg:px-8">
                {loading ? (
                  <div className="my-36 flex w-full justify-center">
                    <LoadingSkeleton />
                  </div>
                ) : teamData && teamData.length > 0 ? (
                  <TeamManagementSection teams={teamData} />
                ) : (
                  <div className="my-10 w-full text-center">
                    <h3 className="mt-2 text-lg font-medium text-gray-900">
                      No Leagues Found
                    </h3>
                    <p className="mx-auto mt-1 flex items-center justify-center text-sm text-primary">
                      Click the button above, then Create a League
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
        <MobileNavigation />
      </div>
      {showJoinedPopup && joinedLeagueCode && !showUserSettingsPopup && (
        <Modal>
          <JoinedLeagueModal
            onClose={onCloseHandler}
            leagueCode={joinedLeagueCode}
          />
        </Modal>
      )}
      {showUserSettingsPopup && (
        <Modal>
          <DashboardUserAccountSettings onClose={onCloseHandlerSettings} />
        </Modal>
      )}
      {showChangePassword && (
        <Modal>
          <ChangePassword onClose={onCloseHandler} />
        </Modal>
      )}
    </>
  );
});
