import { useCallback } from 'react';
import { useSupabase } from '../hooksV2/useSupabase';
import { CONSTANTS } from '../utils/constants';

export const useLogout = () => {
  const { supabase } = useSupabase();

  const logout = useCallback(async () => {
    await supabase.auth.signOut();
    window.location.href = CONSTANTS.app.basePath;
  }, [supabase]);

  return {
    logout,
  };
};
