import React from 'react';
import { Container } from '../landing/Container';
import { Link } from 'react-router-dom';

export function Hero() {
  return (
    <div className="py-12 lg:py-32">
      <Container>
        <div className="lg:grid lg:grid-cols-12 lg:gap-y-20">
          <div className="relative z-10 mx-auto max-w-2xl text-white lg:col-span-7 lg:max-w-none xl:col-span-7">
            <h1 className="text-3xl font-bold lg:col-span-9 lg:whitespace-nowrap lg:text-6xl">
              Play Fantasy Football for GOLF
            </h1>
            <p className="mt-10 text-base lg:w-5/6 lg:text-lg">
              The first ever fantasy golf league that allows the users to draft
              golfers and keep them for the entire golf season. No more choosing
              different golfers on a week-by-week basis and then not being able
              to choose them again. YOU KEEP YOUR GOLFERS UNLESS YOU DECIDE TO
              TRADE OR DROP DURING THE SEASON.
            </p>
            <div className="mt-8 flex flex-wrap gap-x-6 gap-y-4"></div>
          </div>
        </div>
        <Link
          to="/register"
          className="group mt-20 w-36 text-2xl font-bold text-white"
        >
          play now{' '}
          <span className="inline-block transition-transform duration-300 ease-in-out group-hover:translate-x-2">
            →
          </span>
        </Link>
      </Container>
    </div>
  );
}
