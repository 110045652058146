import { useQuery } from 'react-query';
import { getData } from '../models/trades/api';

export const useTrades = (teamId) => {
  return useQuery(
    ['useTrades', teamId],
    async () => getData(teamId).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      enabled: !!teamId,
    }
  );
};
