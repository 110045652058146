import { useQuery } from 'react-query';
import { getDataByLeagueId } from '../models/teams/api';

export const useTeamsByLeague = (args) => {
  return useQuery(
    ['useTeamsByLeague', args],
    async () => getDataByLeagueId(args).then((result) => result!.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
    }
  );
};
