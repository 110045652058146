import { useQuery } from 'react-query';
import { getDataByPlayerId } from '../models/results/api';

export const useResults = (playerId, year) => {
  return useQuery(
    ['usePlayers', playerId, year],
    async () => getDataByPlayerId(playerId, year).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      enabled: !!playerId && !!year,
    }
  );
};
