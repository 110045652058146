import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSupabase } from '../../../hooksV2/useSupabase';
import { Player } from '../../../models/players/api';
import { Modal } from '../../app/modal';
import { PlayerModal } from '../../app/modals/playerModal';
import {
  yearsDifferenceFromCurrent,
  formatPlayerName,
} from '../../../utils/utils';
import { ArrowRightIcon, HandRaisedIcon } from '@heroicons/react/24/outline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { usePlayerTeamByLeagueId } from '../../../hooks/usePlayerTeamByLeagueId';
import { useParams } from 'react-router';
import { useLeague } from '../../../hooks/useLeague';
import { Team } from '../../../models/teams/api';
import { useMyTeam } from '../../../hooks/useMyTeam';
import { TradingModal } from '../../app/modals/TradingModal';
import { FreeAgentModal } from '../../app/modals/FreeAgentModal';
import {
  formatNumberWithCommas,
  isDayBetweenThursdayAndMonday,
} from '../../../utils/utils';

interface PlayerWithScore extends Player {
  score: number;
  places: number;
  rank: number;
}

type Props = {
  players: PlayerWithScore[];
  owner?: string;
  description?: string;
  isMyTeam?: boolean;
  title?: string;
  selectedOption?: any;
};

export function PlayerDisplaySection({
  players,
  selectedOption = undefined,
}: Props) {
  const { supabase } = useSupabase();
  const { leagueId } = useParams();
  const [images, setImages] = useState([]);
  const [playerSelected, setPlayerSelected] = useState();
  const [playerImage, setPlayerImage] = useState();
  const [sortMode, setSortMode] = useState<string>('rank');
  const [openTradingModal, setOpenTradingModal] = useState<boolean>(false);
  const [clickedAmount, setClickedAmount] = useState<number>(1);
  const [teamAssociation, setTeamAssociation] = useState<Team>();
  const { data, error } = usePlayerTeamByLeagueId(leagueId);
  const [openFreeAgentModal, setOpenFreeAgentModal] = useState<boolean>(false);
  const { data: myTeamData } = useMyTeam();
  const { data: leagueData } = useLeague(leagueId);

  let filteredTeams = data;
  if (selectedOption && !!selectedOption.value) {
    if (selectedOption.value === 'available') {
      filteredTeams = filteredTeams.filter((team) => team.team_id.id);
    } else {
      filteredTeams = filteredTeams.filter(
        (team) => team.team_id.id === selectedOption.value
      );
    }
  }

  const fetchImages = async (players) => {
    const promises = [];
    const filteredPlayers = players.filter((player) => !!player.profile_url);
    for (const player of filteredPlayers) {
      const result = supabase.storage
        .from('golfers')
        .getPublicUrl(player.profile_url);
      promises.push(result);
    }
    const result = supabase.storage.from('golfers').getPublicUrl('unknown');
    promises.push(result);
    const results = await Promise.all(promises);
    const mapResults = results.map((result) => result.data.publicUrl);
    setImages(mapResults);
  };

  useEffect(() => {
    if (players) {
      fetchImages(players);
    }
  }, [players]);

  const onClickTradeHandler = (e: any, state, player) => {
    e.preventDefault();
    e.stopPropagation();

    const team = filteredTeams.find(
      (instance) => instance.player_id.id === player.id
    )?.team_id;

    let imageUrl = images.find((playerImage) =>
      playerImage.includes(player.profile_url)
    );
    if (!imageUrl) {
      imageUrl = images.find((playerImage) => playerImage.includes('unknown'));
    }
    setPlayerImage(imageUrl);
    setPlayerSelected(player);
    setTeamAssociation(team);
    setOpenTradingModal(state);
  };

  const onClickPickupHandler = (e, state, player) => {
    e.stopPropagation();
    let imageUrl = images.find((playerImage) =>
      playerImage.includes(player.profile_url)
    );
    if (!imageUrl) {
      imageUrl = images.find((playerImage) => playerImage.includes('unknown'));
    }
    setPlayerImage(imageUrl);
    setPlayerSelected(player);
    setOpenFreeAgentModal(state);
  };

  const generateTableData = (player: PlayerWithScore, index, playerImages) => {
    let imageUrl = playerImages.find((playerImage) =>
      playerImage.includes(player.profile_url)
    );
    if (!imageUrl) {
      imageUrl = playerImages.find((playerImage) =>
        playerImage.includes('unknown')
      );
    }

    const formattedName = formatPlayerName(player.name);

    const yoe = player.turned_pro
      ? yearsDifferenceFromCurrent(player.turned_pro)
      : '-';

    const yoeClass = clsx({
      'text-gray-400': yoe === '-',
      'text-current': yoe !== '-',
    });

    const iconStyle =
      player.handedness === 'L' ? { transform: 'scaleX(-1)' } : {};

    let team = undefined;
    if (leagueData.isDrafted && filteredTeams) {
      team = filteredTeams.find(
        (instance) => instance.player_id.id === player.id
      )?.team_id;
    }

    return (
      <div className="h-[100px] py-4 pl-2 pr-3 text-sm sm:pl-0">
        <div className="relative flex h-full items-center">
          <span className="mr-5 lg:mx-5">{index + 1}</span>
          <div className="h-10 w-10 flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src={imageUrl ?? ''}
              alt=""
            />
          </div>
          {data && team && (
            <div className="absolute top-0 -mt-3 ml-[5.5rem] flex w-full flex-row items-center gap-2">
              <ArrowRightIcon height={10} width={10} />
              <span className="whitespace-nowrap text-xs">{team.name}</span>
            </div>
          )}
          <div className="absolute top-0 -mt-3 flex w-full">
            {team &&
              myTeamData?.id !== team?.id &&
              !isDayBetweenThursdayAndMonday() && (
                <div className="flex w-full items-center justify-end">
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-md border border-transparent bg-primary px-1 py-0.5 text-xs font-medium text-white shadow-sm"
                    onClick={(e) => onClickTradeHandler(e, true, player)}
                  >
                    Trade
                  </button>
                </div>
              )}
            {!team &&
              leagueData.isDrafted &&
              !isDayBetweenThursdayAndMonday() && (
                <div className="flex w-full items-center justify-end">
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-md border border-transparent bg-primary px-1 py-0.5 text-xs font-medium text-white shadow-sm"
                    onClick={(e) => onClickPickupHandler(e, true, player)}
                  >
                    Pickup
                  </button>
                </div>
              )}
            {team &&
              myTeamData?.id !== team?.id &&
              isDayBetweenThursdayAndMonday() && (
                <div className="flex w-full items-center justify-end">
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-md border border-transparent bg-slate-400 px-1 py-0.5 text-xs font-medium text-white shadow-sm"
                  >
                    Trade
                  </button>
                </div>
              )}
            {!team &&
              leagueData.isDrafted &&
              isDayBetweenThursdayAndMonday() && (
                <div className="flex w-full items-center justify-end">
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-md border border-transparent bg-slate-400 px-1 py-0.5 text-xs font-medium text-white shadow-sm"
                  >
                    Pickup
                  </button>
                </div>
              )}
          </div>
          <div className="ml-4 flex flex-1 items-center justify-between">
            <div className="flex flex-col">
              <div className="font-medium text-gray-900">{formattedName}</div>
              <div className="flex flex-row items-center gap-3">
                <div className="flex flex-row items-center gap-1">
                  <HandRaisedIcon style={iconStyle} height={15} width={15} />
                  <div>{player.handedness}</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <span>YOE:</span>
                  <div className={yoeClass}>{yoe}</div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 whitespace-nowrap">
              <div className="flex flex-col items-center">
                <span className="font-semibold">Total PTS</span>
                <div className="text-sm">
                  {formatNumberWithCommas(player.score)}
                </div>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-sm font-semibold">Rank</span>
                <div>
                  {player.rank
                    ? formatNumberWithCommas(player.rank, false)
                    : 'NR'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onPlayerClickHandler = (e: any, player) => {
    e.preventDefault();
    let imageUrl = images.find((playerImage) =>
      playerImage.includes(player.profile_url)
    );
    if (!imageUrl) {
      imageUrl = images.find((playerImage) => playerImage.includes('unknown'));
    }
    setPlayerSelected(player);
    setPlayerImage(imageUrl);
    const team = filteredTeams.find(
      (instance) => instance.player_id.id === player.id
    )?.team_id;
    setTeamAssociation(team);
  };

  const onModalClose = () => {
    setPlayerImage(undefined);
    setPlayerSelected(undefined);
    setOpenTradingModal(false);
    setTeamAssociation(undefined);
    setPlayerImage(undefined);
    setOpenFreeAgentModal(false);
  };

  const onClickSortHandler = (sort: string) => {
    if (sortMode === sort) {
      setClickedAmount((prevAmount) => (prevAmount === 1 ? 2 : 1));
    } else {
      setClickedAmount(1);
    }
    setSortMode(sort);
  };

  const getSortedPlayers = () => {
    let result = players;
    if (selectedOption?.value) {
      if (selectedOption.value === 'available') {
        result = players.filter(
          (player) =>
            filteredTeams.find(
              (teamPlayer) => teamPlayer.player_id.id === player.id
            ) === undefined
        );
      } else {
        result = players.filter(
          (player) =>
            filteredTeams.find(
              (teamPlayer) => teamPlayer.player_id.id === player.id
            ) !== undefined
        );
      }
    }
    const ascending = clickedAmount === 1;
    const compareYOE = (a, b) => {
      if (!a.turned_pro && !b.turned_pro) {
        return 0;
      }
      if (!a.turned_pro) {
        return 1;
      }
      if (!b.turned_pro) {
        return -1;
      }
      return ascending
        ? a.turned_pro - b.turned_pro
        : b.turned_pro - a.turned_pro;
    };

    if (sortMode === 'pts') {
      result.sort((a, b) =>
        ascending ? b.score - a.score : a.score - b.score
      );
    } else if (sortMode === 'exp') {
      result.sort(compareYOE);
    } else if (sortMode === 'rank') {
      result.sort((a, b) => {
        if (!a.rank && !b.rank) {
          return 0;
        }
        if (!a.rank) {
          return 1;
        }
        if (!b.rank) {
          return -1;
        }
        return ascending ? a.rank - b.rank : b.rank - a.rank;
      });
    }
    return result;
  };

  return (
    <>
      <div className="bg-green">
        <div className="my-2 flex flex-row justify-around whitespace-nowrap">
          <div className="w-[5ch]">
            <button
              type="button"
              className="rounded-full bg-primary px-2.5 py-1 text-sm font-semibold text-white shadow-sm duration-300 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => onClickSortHandler('exp')}
            >
              EXP
            </button>
            {sortMode === 'exp' &&
              (clickedAmount === 1 ? (
                <ArrowDropUpIcon className="text-primary" />
              ) : (
                <ArrowDropDownIcon className="text-primary" />
              ))}
          </div>
          <div className="w-[5ch]">
            <button
              type="button"
              className="rounded-full bg-primary px-2.5 py-1 text-sm font-semibold text-white shadow-sm duration-300 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => onClickSortHandler('pts')}
            >
              PTS
            </button>
            {sortMode === 'pts' &&
              (clickedAmount === 1 ? (
                <ArrowDropUpIcon className="text-primary" />
              ) : (
                <ArrowDropDownIcon className="text-primary" />
              ))}
          </div>
          <div className="w-[12ch]">
            <button
              type="button"
              className="rounded-full bg-primary px-2.5 py-1 text-sm font-semibold text-white shadow-sm duration-300 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => onClickSortHandler('rank')}
            >
              WORLD RANK
            </button>
            {sortMode === 'rank' &&
              (clickedAmount === 1 ? (
                <ArrowDropUpIcon className="text-primary" />
              ) : (
                <ArrowDropDownIcon className="text-primary" />
              ))}
          </div>
        </div>
        <div className="flex w-screen flex-col overflow-hidden bg-primary lg:px-8">
          <div className="mb-4 lg:-mx-4">
            <div className="min-w-full py-2 align-middle sm:px-2 lg:px-4">
              <div className="min-w-full divide-y divide-gray-300">
                <ul className="mb-10 flex flex-col divide-y divide-gray-200">
                  {players &&
                    getSortedPlayers().map((player, idx) => (
                      <li
                        key={player.id}
                        className="m-1 cursor-pointer rounded-md bg-white"
                        onClick={(e: any) => onPlayerClickHandler(e, player)}
                      >
                        {generateTableData(player, idx, images)}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {playerSelected &&
        playerImage &&
        !openTradingModal &&
        !openFreeAgentModal && (
          <Modal>
            <PlayerModal
              imageUrl={playerImage}
              player={playerSelected}
              onClose={onModalClose}
              team={teamAssociation}
            />
          </Modal>
        )}
      {openTradingModal && playerSelected && teamAssociation && (
        <Modal>
          <TradingModal
            onClose={onModalClose}
            player={playerSelected}
            team={teamAssociation}
            image={playerImage}
          />
        </Modal>
      )}
      {openFreeAgentModal && playerSelected && (
        <Modal>
          <FreeAgentModal
            onClose={onModalClose}
            player={playerSelected}
            image={playerImage}
          />
        </Modal>
      )}
    </>
  );
}
