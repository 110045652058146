import React from 'react';
import { useQuery } from 'react-query';
import { getData } from '../models/history/api';

type Props = {
  leagueId: string;
};

export const useHistory = (args: Props) => {
  return useQuery(
    ['useHistory', args.leagueId],
    async () => getData(args.leagueId).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
      enabled: !!args.leagueId,
    }
  );
};
