/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

export function NavLinks({ paths }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const timeoutRef = useRef(null);

  return (
    <>
      {paths.map(([label, href], index) => (
        <NavLink
          key={label}
          to={href}
          className="group relative -mx-3 -my-2 rounded-lg px-3 py-2 text-base text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0"
          onMouseEnter={() => {
            if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
            }
            setHoveredIndex(index);
          }}
          onMouseLeave={() => {
            timeoutRef.current = setTimeout(() => {
              setHoveredIndex(null);
            }, 200);
          }}
        >
          <AnimatePresence>
            {hoveredIndex === index && (
              <motion.span
                className="absolute inset-0 rounded-lg bg-gray-100"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.15 } }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15 },
                }}
              />
            )}
          </AnimatePresence>
          <span
            className={clsx(
              'relative z-10',
              paths[0][0] !== 'Features'
                ? 'text-white duration-300 group-hover:text-gray-700'
                : ''
            )}
          >
            {label}
          </span>
        </NavLink>
      ))}
    </>
  );
}
