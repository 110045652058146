import { useQueryClient } from 'react-query';
import {
  postDataForFreeAgency,
  deleteDataByTeamIdPlayerId,
} from '../models/teamPlayer/api';
import { postData } from '../models/history/api';

export const usePickupFreeAgent = () => {
  const queryClient = useQueryClient();

  const pickupFreeAgent = async (leagueId, player, teamId, replacedPlayer) => {
    const { data } = await postDataForFreeAgency(leagueId, player, teamId);
    await deleteDataByTeamIdPlayerId(teamId, replacedPlayer.id);
    queryClient.invalidateQueries(['usePlayerTeamByLeagueId', leagueId]);
    const instance = data[0];
    const payload = {
      recieve_team: teamId as string,
      recieve_player: replacedPlayer.id as string,
      sender_team: null,
      sent_player: player.id as string,
      league_id: leagueId as string,
    };
    await postData(payload);
  };

  return {
    pickupFreeAgent,
  };
};
