import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabase } from '../../hooksV2/useSupabase';
import { Footer } from '../landing/Footer';
import { Header } from '../landing/Header';
import { Hero } from '../landing/Hero';
import { Reasons } from '../landing/Reasons';
import { NewsFeed } from '../organisms/newsFeed';
import { Reviews } from '../landing/Reviews';
import HeroImg from '../../assets/hero.jpg';

export const LandingPage = () => {
  const { user } = useSupabase();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.id) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  return (
    <>
      <div className="relative">
        <img
          src={HeroImg}
          alt="Hero Image"
          className="absolute inset-0 -z-10 h-full w-full object-cover brightness-75"
        />
        <Header />
        <Hero />
      </div>
      <Reasons />
      <NewsFeed />
      <Reviews />
      <Footer />
    </>
  );
};
