import { ArrowLeftIcon, TrophyIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useMyTeam } from '../../../hooks/useMyTeam';
import { useUpdateLeague } from '../../../hooks/useUpdateLeague';
import { useLeague } from '../../../hooks/useLeague';
import { useSupabase } from '../../../hooksV2/useSupabase';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';

export const GeneralSettings = ({ back }) => {
  const { updateLeagueData } = useUpdateLeague();
  const { leagueId } = useParams();
  const { data: leagueData } = useLeague(leagueId);
  const [image, setImage] = useState<Blob>();
  const [preview, setPreview] = useState<string>();
  const { supabase } = useSupabase();
  const queryClient = useQueryClient();
  const [form, setForm] = useState({
    leagueName: '',
  });

  useEffect(() => {
    if (leagueData) {
      setForm({
        leagueName: leagueData.name,
      });
    }
  }, [leagueData]);

  const uploadFile = async (file, payload) => {
    await supabase.storage.from('league-logos').upload(`${leagueId}`, file, {
      contentType: file.type, // Set the MIME type here
      upsert: true, // Set to true if you want to overwrite existing files with the same name
    });
    await updateLeagueData({
      ...payload,
      pic_url: leagueId,
    });
    back();
  };

  const onSaveHandler = async (e) => {
    e.preventDefault();
    const payload = {
      ...leagueData,
      name: form.leagueName,
    };
    await uploadFile(image, payload);
    queryClient.invalidateQueries(['useLeague', leagueId]);
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image')) {
      setImage(file);

      // Create a URL for the image file for preview
      const url = URL.createObjectURL(file);
      setPreview(url);
    }
  };

  const getDefaultPhoto = async () => {
    const { data, error } = await supabase.storage
      .from('league-logos')
      .download(leagueData.pic_url);
    const url = URL.createObjectURL(data);
    setPreview(url);
    setImage(data);
  };

  useEffect(() => {
    if (leagueData) {
      getDefaultPhoto();
    }
  }, [leagueData]);

  return (
    <div>
      <div className="mt-5 flex w-full justify-center">
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={(e: any) => onSaveHandler(e)}
        >
          <div className="space-y-8 divide-y divide-gray-200 rounded-md bg-primary p-5 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="flex flex-col">
                  <label
                    htmlFor="profilePic"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    Upload League Picture
                  </label>
                  <div className="my-2 flex items-center justify-around">
                    <div className="m-2 my-2 h-auto">
                      <img
                        src={preview}
                        alt="league picture"
                        height={50}
                        width={50}
                        className="my-4 flex aspect-square items-center rounded-full bg-white object-cover p-1"
                      />
                    </div>
                    <label
                      htmlFor="file-input"
                      className="cursor-pointer rounded bg-white px-4 py-1"
                    >
                      Upload File
                    </label>
                    <input
                      type="file"
                      id="file-input"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    League Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="leagueName"
                      id="leagueName"
                      className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={form.leagueName}
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex flex-col items-center justify-center gap-2">
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                SAVE
              </button>
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                onClick={back}
              >
                BACK
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
