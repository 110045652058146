import { useQuery } from 'react-query';
import { getData } from '../models/trades_players/api';

export const useTradesPlayers = (tradeIds) => {
  return useQuery(
    ['useTradesPlayers', tradeIds],
    async () => getData(tradeIds).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      enabled: tradeIds.length !== 0,
    }
  );
};
