import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { deleteLeagueData } from '../../../models/leagues/api';
import { useQueryClient } from 'react-query';
import { useUser } from '../../../hooks/useUser';

export const LeagueListItemModal = ({ onClose, leagueId }) => {
  const queryClient = useQueryClient();
  const { data } = useUser();

  const onClickHandler = async (id) => {
    try {
      await deleteLeagueData(id);
      queryClient.invalidateQueries(['useTeams', data?.id]);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-white px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <button
                  className="rounded-lg border-2 border-red-500 px-4 py-2 text-red-500 duration-300 hover:bg-red-500 hover:text-white"
                  onClick={() => onClickHandler(leagueId)}
                >
                  Delete ?
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
