import IFrame from 'react-iframe';
import { MobileNavigation } from '../app/mobileNavigation';

export const IntroPage = () => {
  return (
    <>
      <div className="w-full">
        <IFrame
          url="https://docs.google.com/document/d/1OBb3D6mura0xfdF9Fz4ll2AJvMLfOXlOsheqk3kLVkM/edit"
          className="min-h-screen min-w-full"
        />
        <MobileNavigation />
      </div>
    </>
  );
};
