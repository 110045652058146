import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import ErrorImage from '../../assets/error_boundary.png';
import { Link } from 'react-router-dom';

export const WaitingPage = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="text-lg">Check your email!</h1>
      <div className="flex flex-col items-center">
        <h2 className="text-sm">Confirm your account for 3FGolf!</h2>
        <img
          className="mt-6 aspect-square w-80 rounded-xl object-cover"
          src={ErrorImage}
          alt="Error"
        />
      </div>
    </div>
  );
};
