import IFrame from 'react-iframe';
import { MobileNavigation } from '../app/mobileNavigation';

export const FedexPage = () => {
  return (
    <>
      <div className="w-full">
        <IFrame
          url="https://www.pgatour.com/fedexcup"
          className="min-h-screen min-w-full"
        />
        <MobileNavigation />
      </div>
    </>
  );
};
