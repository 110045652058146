import { Transition, Dialog } from '@headlessui/react';
import {
  ArrowRightIcon,
  XCircleIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { useScore } from '../../../hooks/useScores';
import { useTeamPlayerHistory } from '../../../hooks/useTeamPlayerHistory';
import { formatNumberWithCommas } from '../../../utils/utils';
import { LoadingSkeleton } from '../../organisms/loadingSkeleton';

export const ScoreHistoryModal = ({ onClose, teamId }) => {
  const { data } = useScore(teamId);
  const [selectedTournament, setSelectedTournament] = useState();
  const { data: playerTeamHistoryData } = useTeamPlayerHistory({
    teamId,
    tournamentId: selectedTournament,
  });

  let sum = 0;
  data?.forEach((d) => {
    sum += d.score;
  });

  const onTournamentSelect = (tournamentId) => {
    if (selectedTournament === tournamentId) {
      setSelectedTournament(undefined);
      return;
    }
    setSelectedTournament(tournamentId);
  };

  const mapPlayerTeamHistory = (playerTeamHistory) => {
    const result = playerTeamHistory.map((d) => {
      console.log(d);
      return (
        <div className="flex flex-row justify-between p-2" key={d.id}>
          <div className="text-sm">{d.player.abbr_name}</div>
          <div className="flex flex-row gap-2">
            <div className="flex flex-col items-center">
              <div className="text-sm">Pos</div>
              <span>{d?.results?.position ?? 'DNS'}</span>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-sm">Pts</div>
              <span>{formatNumberWithCommas(d.score)}</span>
            </div>
          </div>
        </div>
      );
    });
    return result;
  };

  const mapTournamentData = (d) => {
    console.log(d);
    d?.sort(
      (a, b) =>
        new Date(a.tournament_id.start_date).getTime() -
        new Date(b.tournament_id.start_date).getTime()
    );
    const results = d?.map((tournament) => {
      const rounded =
        tournament.tournament_id.id === selectedTournament
          ? 'rounded-t-md'
          : 'rounded-md';
      return (
        <div className="flex w-full flex-col rounded-md" key={tournament.id}>
          <div className={`flex flex-col ${rounded}`}>
            <div className="flex flex-col gap-2 rounded-lg bg-white p-3">
              <div className="flex w-full justify-end">
                <button
                  className="rounded-md bg-primary p-2 text-xs text-white"
                  onClick={() =>
                    onTournamentSelect(tournament.tournament_id.id)
                  }
                >
                  Details
                </button>
              </div>
              <div>
                <span className="font-semibold">
                  {tournament.tournament_id.name}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-sm">Points Earned</span>
                <span className="ml-[2ch] h-px grow border"></span>
                <span className="ml-2 text-right text-sm">
                  {formatNumberWithCommas(tournament?.score ?? 0)}
                </span>
              </div>
            </div>
          </div>
          <div className="w-full rounded-b-md bg-slate-300">
            <div className="flex w-full flex-col gap-2">
              {selectedTournament === tournament.tournament_id.id &&
                !playerTeamHistoryData && (
                  <div className="flex flex-row justify-center p-2">
                    <LoadingSkeleton />
                  </div>
                )}
              {selectedTournament === tournament.tournament_id.id &&
                playerTeamHistoryData &&
                playerTeamHistoryData.length > 0 && (
                  <div className="flex w-full flex-col">
                    {mapPlayerTeamHistory(playerTeamHistoryData)}
                  </div>
                )}
              {selectedTournament === tournament.tournament_id.id &&
                playerTeamHistoryData &&
                playerTeamHistoryData.length === 0 && (
                  <div className="flex flex-row justify-center">
                    <span>No data available for this tournament</span>
                  </div>
                )}
            </div>
          </div>
        </div>
      );
    });
    return results;
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex flex-col items-center justify-center">
                  <div className="absolute right-0 top-0 m-1 flex w-full justify-end">
                    <button onClick={onClose}>
                      <XCircleIcon height={30} width={30} />
                    </button>
                  </div>
                  <div className="mx-10 my-2 w-full">
                    <span className="flex flex-col text-lg font-semibold">
                      Team Points Earned
                    </span>
                    <span>
                      Total Points: {formatNumberWithCommas(sum ?? 0)}
                    </span>
                    <div className="flex flex-col gap-2">
                      {!data ||
                        (data?.length > 0 && <>{mapTournamentData(data)}</>)}
                      {!data ||
                        (data?.length === 0 && (
                          <div className="w-full">
                            {/* Tournaments Title Placeholder */}
                            <div className="mb-2 h-4 w-40 animate-pulse bg-gray-300"></div>
                            {/* Tournaments List Placeholder */}
                            {[...Array(3)].map((_, index) => (
                              <div
                                className="mt-2 grid animate-pulse grid-cols-[3fr,1fr,1fr] items-center gap-4"
                                key={index}
                              >
                                {/* Tournament Name and Start Date Placeholders */}
                                <div className="flex flex-col">
                                  <div className="mb-1 h-4 w-3/4 bg-gray-300"></div>
                                  <div className="h-4 w-1/2 bg-gray-300"></div>
                                </div>
                                {/* Position Placeholder */}
                                <div className="flex flex-col">
                                  <div className="mb-1 h-2 w-24 bg-gray-300"></div>
                                  <div className="h-4 w-8 bg-gray-300"></div>
                                </div>
                                {/* PTS Placeholder */}
                                <div className="flex flex-col">
                                  <div className="mb-1 h-2 w-12 bg-gray-300"></div>
                                  <div className="h-4 w-8 bg-gray-300"></div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
