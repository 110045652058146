import { supabase } from '../utils';
import {
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestError,
} from '@supabase/postgrest-js';
import { League } from '../leagues/api';
import { Player } from '../players/api';
export type Team = {
  pic_url: string;
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  user_id: string;
  league_id: League;
  team_num: number;
};

export interface ApiResponse {
  data: Team[];
  error: PostgrestError;
}

export interface ApiArgs {
  id: string;
}

export interface PostApiArgs {
  teams: {
    userId?: string;
    leagueId: string;
    team_num: number;
    name: string;
  }[];
}

export interface PatchApiArgs {
  teamId: string;
  userId: string;
}

export interface PutNameApiArgs {
  teamId: string;
  name: string;
}

export const getData = ({
  id,
}: ApiArgs): PostgrestFilterBuilder<any, any, any[]> => {
  return supabase
    .from('teams')
    .select('*, league_id:leagues(*)')
    .eq('user_id', id);
};

export const getDataWithUser = (
  id
): PostgrestFilterBuilder<any, any, any[]> => {
  return supabase.from('teams').select('*, user_id:users(*)').eq('id', id);
};

export const getDataByTeamId = ({
  id,
}: ApiArgs): PostgrestFilterBuilder<any, any, any[]> => {
  return supabase.from('teams').select('*').eq('id', id);
};

export const getDataByTeamIdWithUser = ({
  id,
}: ApiArgs): PostgrestFilterBuilder<any, any, any[]> => {
  return supabase
    .from('teams')
    .select('*, user_id("first_name", "last_name")')
    .eq('id', id);
};

export const getDataByLeagueId = (
  id: string
): PostgrestFilterBuilder<any, any, Team[]> => {
  return supabase.from('teams').select('*').eq('league_id', id);
};

export const postData = ({
  teams,
}: PostApiArgs): PostgrestTransformBuilder<any, any, Team[]> => {
  const payload = [];

  teams.forEach((team) => {
    payload.push({
      user_id: team.userId ?? null,
      league_id: team.leagueId,
      name: team.name,
      team_num: team.team_num,
    });
  });
  return supabase.from('teams').insert(payload).select();
};

export const putNameData = ({ teamId, name }: PutNameApiArgs) => {
  return supabase
    .from('teams')
    .update({
      name: name,
    })
    .eq('id', teamId)
    .select();
};

export const putData = ({
  teams,
}: PostApiArgs): PostgrestTransformBuilder<any, any, Team[]> => {
  const payload = [];

  teams.forEach((team) => {
    payload.push({
      user_id: team.userId ?? null,
      league_id: team.leagueId,
      name: team.name,
      team_num: team.team_num,
    });
  });
  return supabase.from('teams').insert(payload).select();
};

export const patchData = ({
  teamId,
  userId,
}: PatchApiArgs): PostgrestTransformBuilder<any, any, Team[]> => {
  return supabase
    .from('teams')
    .update({
      user_id: userId,
    })
    .eq('id', teamId)
    .select();
};

export const updateData = (team) => {
  return supabase
    .from('teams')
    .update({
      ...team,
    })
    .eq('id', team.id);
};
