/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useInView } from 'framer-motion';
import { Container } from './Container';

const reviews = [
  {
    title: 'A Game Changer!',
    body: "I've tried multiple fantasy sports apps, but 3FGolf stands out. The interface is user-friendly, and the real-time stats keep me hooked every weekend!",
    author: 'JakeThompson92',
    rating: 5,
  },
  {
    title: 'So Much Fun!',
    body: "My friends and I compete weekly, and it's added a whole new layer of excitement to watching golf tournaments.",
    author: 'LucyM_45',
    rating: 5,
  },
  {
    title: 'Good, but could be better.',
    body: 'I love the concept, but the app sometimes lags during major tournaments. Hope they fix this soon.',
    author: 'DarrenK',
    rating: 3.5,
  },
  {
    title: 'For Every Golf Lover!',
    body: 'If you love golf, this is the app for you. Simple as that.',
    author: 'TeeTimeTony',
    rating: 4.5,
  },
  {
    title: 'Stellar App!',
    body: 'The features and detailed stats give me everything I need to craft the perfect team. Kudos to the developers!',
    author: 'GolfGuru2023',
    rating: 5,
  },
  {
    title: 'Makes Golf More Exciting!',
    body: "Never thought I'd be this engrossed in golf. Thanks, 3FGolf!",
    author: 'Nina_Wild',
    rating: 4,
  },
  {
    title: 'A Bit Confusing.',
    body: 'Took me a while to navigate and understand. A tutorial would be helpful for newbies.',
    author: 'AndyPebbles',
    rating: 3,
  },
  {
    title: 'Love It!',
    body: 'The competition, the strategy, the thrill! 3FGolf delivers on all fronts.',
    author: 'SwingKing77',
    rating: 5,
  },
  {
    title: 'Perfect for Group Play',
    body: "Organized a league with my coworkers. We're all addicted now!",
    author: 'HelenT_89',
    rating: 4.5,
  },
  {
    title: 'Could Use More Features',
    body: "It's a solid app but would love to see more in-depth analysis and player insights.",
    author: 'ProPuttPeter',
    rating: 4,
  },
  {
    title: 'Refreshing Take on Fantasy Sports!',
    body: 'Diverse from typical fantasy football. A must-try for anyone looking for something different.',
    author: 'StacyR34',
    rating: 5,
  },
  {
    title: 'A bit glitchy.',
    body: 'Encountered a few bugs. Hoping for a smoother experience in the future updates.',
    author: 'GregD_56',
    rating: 3,
  },
  {
    title: "Can't Stop Playing!",
    body: "It's become a weekend ritual for me. Highly recommended for golf enthusiasts!",
    author: 'BirdieBeth',
    rating: 5,
  },
  {
    title: 'Fairly Good',
    body: "Offers a lot, but there's room for improvement. Eager to see what the next updates bring.",
    author: 'FairwayFred',
    rating: 4,
  },
];

function StarIcon(props) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  );
}

function StarRating({ rating }) {
  return (
    <div className="flex">
      {[...Array(5).keys()].map((index) => (
        <StarIcon
          key={index}
          className={clsx(
            'h-5 w-5',
            rating > index ? 'fill-cyan-500' : 'fill-gray-300'
          )}
        />
      ))}
    </div>
  );
}

function Review({ title, body, author, rating, className, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = [
      '0s',
      '0.1s',
      '0.2s',
      '0.3s',
      '0.4s',
      '0.5s',
    ];
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ];
  }, []);

  return (
    <figure
      className={`animate-fade-in rounded-3xl bg-white p-6 opacity-0 shadow-md shadow-gray-900/5 ${className}`}
      style={{ animationDelay }}
      {...props}
    >
      <blockquote className="text-gray-900">
        <StarRating rating={rating} />
        <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
          {title}
        </p>
        <p className="mt-3 text-base leading-7">{body}</p>
      </blockquote>
      <figcaption className="mt-3 text-sm text-gray-600 before:content-['–_']">
        {author}
      </figcaption>
    </figure>
  );
}

function splitArray(array, numParts) {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function ReviewColumn({ reviews, className, reviewClassName, msPerPixel = 0 }) {
  let columnRef = useRef(null);
  let [columnHeight, setColumnHeight] = useState(0);
  let duration = `${columnHeight * msPerPixel}ms`;
  useEffect(() => {
    if (!columnRef.current) {
      return;
    }
    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current?.offsetHeight ?? 0);
    });

    resizeObserver.observe(columnRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={columnRef}
      className={`animate-marquee space-y-8 py-4 ${className}`}
      style={{ '--marquee-duration': duration }}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName?.(reviewIndex % reviews.length)}
          {...review}
        />
      ))}
    </div>
  );
}

function ReviewGrid() {
  let containerRef = useRef(null);
  let isInView = useInView(containerRef, { once: true, amount: 0.4 });
  let columns = splitArray(reviews, 3);
  let column1 = columns[0];
  let column2 = columns[1];
  let column3 = splitArray(columns[2], 2);
  return (
    <div
      ref={containerRef}
      className="relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={[...column1, ...column3.flat(), ...column2]}
            reviewClassName={(reviewIndex) =>
              clsx(
                reviewIndex >= column1.length + column3[0].length &&
                  'md:hidden',
                reviewIndex >= column1.length && 'lg:hidden'
              )
            }
            msPerPixel={10}
          />
          <ReviewColumn
            reviews={[...column2, ...column3[1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex) =>
              reviewIndex >= column2.length ? 'lg:hidden' : ''
            }
            msPerPixel={15}
          />
          <ReviewColumn
            reviews={column3.flat()}
            className="hidden lg:block"
            msPerPixel={10}
          />
        </>
      )}
      <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-50" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-50" />
    </div>
  );
}

export function Reviews() {
  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className="pb-16 pt-20 sm:pb-24 sm:pt-32"
    >
      <Container>
        <h2
          id="reviews-title"
          className="text-3xl font-medium tracking-tight text-gray-900 sm:text-center"
        >
          Rave Reviews from the Greens!
        </h2>
        <p className="mt-2 text-lg text-gray-600 sm:text-center">
          Hear from our passionate community of golf enthusiasts!
        </p>
        <ReviewGrid />
      </Container>
    </section>
  );
}
