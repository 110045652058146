import { useCallback } from 'react';
import { postData } from '../models/leagues/api';
import { postData as postTeamData } from '../models/teams/api';
import { useUser } from './useUser';
import { postData as postDraft } from '../models/draft/api';
import { postData as postTeamScores } from '../models/teamScores/api';
import { usePlayers } from './usePlayers';
import { isOdd } from '../utils/utils';

type TeamArgs = {
  name: string;
  leagueId: string;
  userId?: string;
  team_num: number;
};

type Draft = {
  team_id: string;
  draft_number: number;
  league_id: string;
};

export const useCreateLeague = () => {
  const { data } = useUser();
  const { data: players } = usePlayers();

  const generateTeams = (numOfTeams: number, leagueId, userId): TeamArgs[] => {
    const defaultTeams = [];
    for (let i = 0; i < numOfTeams; i++) {
      if (i === 0)
        defaultTeams.push({ name: 'Team 1', leagueId, userId, team_num: i });
      else {
        defaultTeams.push({ name: `Team ${i + 1}`, leagueId, team_num: i });
      }
    }
    return defaultTeams;
  };

  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  const generateDraft = (teams, numOfPlayers): Draft[] => {
    const draft = [];
    const rounds = numOfPlayers;
    let draftNumber = 0;
    for (let i = 0; i < rounds; i++) {
      let roundTeams = [...teams];
      if (isOdd(i)) roundTeams = roundTeams.reverse();
      roundTeams.forEach((team) => {
        draft.push({
          team_id: team.id,
          draft_number: draftNumber,
          league_id: team.league_id,
        });
        draftNumber++;
      });
    }
    return draft;
  };

  const generateTeamScores = (teams) => {
    const result = teams.map((team) => {
      return {
        teamId: team.id,
      };
    });
    return result;
  };

  const createLeague = useCallback(
    async ({ name, numOfTeams, numOfPlayers }) => {
      if (!data) return;
      try {
        const leagueCreationResponse = await postData({
          name,
          players: numOfPlayers,
          id: data.id,
        });
        const { id } = leagueCreationResponse.data![0];
        const generatedTeams = generateTeams(numOfTeams, id, data.id);
        const result = await postTeamData({ teams: generatedTeams });
        const shuffledTeams = shuffleArray(result.data);
        const generatedDraft = generateDraft(shuffledTeams, numOfPlayers);
        await postDraft({ draftEntries: generatedDraft });
        // create teamScores
        const generatedTeamScores = generateTeamScores(shuffledTeams);
        await postTeamScores({ teamScores: generatedTeamScores });
        return { leagueId: id };
      } catch (err) {
        console.error(err);
      }
    },
    [data, players]
  );

  return {
    createLeague,
  };
};
