import React, { useEffect, useState, useMemo } from 'react';
import { usePlayers } from '../../hooks/usePlayers';
import { ArrowLeftIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { LeagueNavigation } from '../organisms/leagueNavigation';
import { LoadingSkeleton } from '../organisms/loadingSkeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { useLeague } from '../../hooks/useLeague';
import { MobileNavigation } from '../app/mobileNavigation';
import { PlayerDisplaySection } from '../organisms/playerDisplaySection';
import { usePlayersScoring } from '../../hooks/usePlayersScoring';
import { LeagueHeader } from '../organisms/leagueHeader';
import { formatPlayerName } from '../../utils/utils';
import { useTeamsByLeague } from '../../hooks/useTeamsByLeague';
import Dropdown from 'react-dropdown';

export const LeaguePlayers = () => {
  const { leagueId } = useParams();
  const { data } = usePlayers();
  const { data: leagueData, isLoading } = useLeague(leagueId);
  const { data: teamsData } = useTeamsByLeague(leagueId);
  const [players, setPlayers] = useState<any>();
  const { getPlayersResults } = usePlayersScoring();
  const [search, setSearch] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<any>({
    value: '',
    label: 'All',
  });

  const isDrafted = leagueData?.isDrafted ?? false;
  useEffect(() => {
    const fetchPlayers = async () => {
      const { playersWithScores } = await getPlayersResults(
        data,
        leagueData.isDrafted
      );
      setPlayers(playersWithScores);
    };
    if (data && leagueData) {
      console.log(data, leagueData);
      fetchPlayers();
    }
  }, [data, isDrafted]);

  const onChangeSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value.toLowerCase());
  };

  const filteredPlayers = useMemo(() => {
    if (!search) return players;
    return players?.filter((player) => {
      const formattedName = formatPlayerName(player.name);
      return (
        player.name.toLowerCase().includes(search) ||
        formattedName.toLowerCase().includes(search)
      );
    });
  }, [search, players]);

  const generateOptions = () => {
    if (!teamsData) return;
    const result = [
      { value: '', label: 'All' },
      { value: 'available', label: 'Available' },
    ];
    const options = teamsData.reduce((acc, curr) => {
      const team = acc.find((option) => option.value === curr.id);
      if (!team)
        acc.push({
          value: curr.id,
          label: curr.name,
        });
      return acc;
    }, result);
    return options;
  };

  const onOptionSelectHandler = (value) => {
    setSelectedOption(value);
  };

  const dropdownOptions = generateOptions();

  return (
    <div>
      <div className="min-h-full overflow-x-hidden">
        <div className="border border-b-2 border-l-0 border-r-0 border-t-0 border-gray-400">
          <div className="bg-gray-800">
            <LeagueHeader
              name={leagueData?.name ?? ''}
              leagueId={leagueData?.id ?? ''}
            />
          </div>
          <main>
            {isLoading ? (
              <div className="my-36 flex w-full justify-center">
                <LoadingSkeleton />
              </div>
            ) : (
              <div className="flex flex-col">
                <div className="flex flex-row items-center justify-evenly gap-4 p-2 lg:justify-between">
                  <div className="border-1 flex w-full flex-row items-center gap-3 rounded-md border border-gray-800 p-2">
                    <MagnifyingGlassIcon height={20} width={20} />
                    <input
                      type="text"
                      value={search}
                      onChange={onChangeSearchHandler}
                      placeholder="Search by player name"
                      className="w-full outline-none"
                    />
                  </div>
                </div>
                <div className="w-full p-2">
                  <Dropdown
                    className="flex w-full flex-nowrap items-center text-sm"
                    options={dropdownOptions}
                    onChange={onOptionSelectHandler}
                    value={selectedOption}
                  />
                </div>
                <div className="mt-2">
                  <PlayerDisplaySection
                    players={filteredPlayers}
                    selectedOption={selectedOption}
                  />
                </div>
              </div>
            )}
          </main>
        </div>
        <MobileNavigation />
      </div>
    </div>
  );
};
