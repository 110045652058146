import React, { useEffect, useState, useMemo } from 'react';
import { createClient } from '@supabase/supabase-js';
import { CONSTANTS } from '../utils/constants';
import { User } from '@supabase/supabase-js';

interface Props {
  children: React.ReactNode;
}

interface SupabaseUser extends User {
  showPasswordRecovery?: boolean;
}

export const SupabaseContext = React.createContext({
  supabase: createClient(
    CONSTANTS.supabase.url ?? '',
    CONSTANTS.supabase.anonKey ?? ''
  ),
  user: undefined,
  setUser: (user) => user,
  isLoading: false,
});

export const SupabaseProvider: React.FC<Props> = ({ children }) => {
  const supabase = useMemo(
    () =>
      createClient(
        CONSTANTS.supabase.url ?? '',
        CONSTANTS.supabase.anonKey ?? ''
      ),
    []
  );
  const [user, setUser] = useState<SupabaseUser | {} | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const values = {
    supabase,
    user,
    isLoading,
    setUser,
  };
  useEffect(() => {
    if (!supabase) return;

    const checkForActiveUserSession = async () => {
      const response = await supabase.auth.getUser();
      setUser(response?.data?.user ?? {});
      setIsLoading(false);
    };
    checkForActiveUserSession();
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log(event);
        if (event === 'PASSWORD_RECOVERY') {
          setUser({
            ...session?.user,
            showPasswordRecovery: true,
          });
          setIsLoading(false);
          console.log(event);
        } else {
          setUser(session?.user ?? {});
          setIsLoading(false);
        }
      }
    );

    return () => {
      listener.subscription.unsubscribe();
    };
  }, [supabase]);

  return (
    <SupabaseContext.Provider value={values}>
      {children}
    </SupabaseContext.Provider>
  );
};
