import React from 'react';
import { useQuery } from 'react-query';
import { getData } from '../models/draft/api';

type Props = {
  leagueId: string;
};

export const useDraft = (args: Props) => {
  return useQuery(
    ['useDraft', args.leagueId],
    async () =>
      getData(args).then((result) =>
        result.data.sort((a, b) => a.draft_number - b.draft_number)
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 1,
      enabled: !!args.leagueId,
    }
  );
};
