import React, { useEffect, useState } from 'react';
import { Container } from '../../landing/Container';
import placeholderImage from '../../../assets/newsfeed_placeholder.jpg';

interface NewsArticle {
  NewsID: number;
  Title: string;
  Url: string;
  Updated: string;
  Source: string;
  OriginalSource: string;
  OriginalSourceUrl: string;
  imageUrl?: string;
}

const imageCache = JSON.parse(localStorage.getItem('imageCache') || '{}');

export const NewsFeed = () => {
  const [articles, setArticles] = useState<NewsArticle[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchNewsAndImages = async () => {
    try {
      const newsResponse = await fetch(
        `https://api.sportsdata.io/golf/v2/json/News?key=${process.env.REACT_APP_NEWSFEED_KEY}`
      );
      const newsData = await newsResponse.json();
      console.log(newsData);

      for (let i = 0; i < newsData.length; i++) {
        const title = newsData[i].Title;

        if (imageCache[title]) {
          newsData[i].imageUrl = imageCache[title];
        } else {
          try {
            const url = `https://image-search13.p.rapidapi.com/?q=${encodeURIComponent(
              title
            )}`;
            const options = {
              method: 'GET',
              headers: {
                'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY,
                'X-RapidAPI-Host': 'image-search13.p.rapidapi.com',
              },
            };

            const imageResponse = await fetch(url, options);
            const imageData = await imageResponse.json();

            if (
              imageData &&
              imageData.response &&
              imageData.response.images &&
              imageData.response.images.length > 0
            ) {
              const imageUrl = imageData.response.images[0].image.url;
              newsData[i].imageUrl = imageUrl;
              imageCache[title] = imageUrl;
              localStorage.setItem('imageCache', JSON.stringify(imageCache));
            } else {
              newsData[i].imageUrl = placeholderImage;
            }
          } catch (error) {
            console.error(`Error fetching image for ${title}:`, error);
            newsData[i].imageUrl = placeholderImage;
          }
        }
      }

      setArticles(newsData);
    } catch (error) {
      console.error('Error fetching news:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsAndImages();
  }, []);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return new Date(dateString)
      .toLocaleDateString('en-US', options)
      .toUpperCase();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="grid grid-cols-1 gap-y-8 pt-36 lg:grid-cols-2">
      {articles.map((article, index) => (
        <div
          key={article.NewsID}
          className="flex flex-col justify-between gap-2"
        >
          {article.imageUrl && index < 2 && (
            <img
              src={article.imageUrl || placeholderImage}
              alt={article.Title}
              className="h-96 w-full rounded-lg object-cover lg:w-[95%]"
            />
          )}

          <p className="text-xs font-medium text-gray-500">
            {formatDate(article.Updated)}
          </p>
          <a
            href={article.Url}
            target="_blank"
            rel="noopener noreferrer"
            className="pr-6"
          >
            <h3 className="text-2xl font-semibold duration-300 hover:text-accent">
              {article.Title}
            </h3>
          </a>
          <p className="font-medium">{article.Source}</p>
          <p className="text-xs">
            Source:{' '}
            <a
              href={article.OriginalSourceUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="duration-300 hover:text-accent"
            >
              {article.OriginalSource}
            </a>
          </p>
          <hr className="border-b-px col-span-2 mb-0.5 mt-6 border-gray-200" />
        </div>
      ))}
    </Container>
  );
};
