import { useCallback } from 'react';
import { useSupabase } from '../hooksV2/useSupabase';
import { CONSTANTS } from '../utils/constants';

type SignInWithPasswordProps = {
  email: string;
  password: string;
};

type SignInWithOAuthProps = {
  provider: ProviderTypes;
  code?: string;
};

export type ProviderTypes = 'google' | 'facebook' | 'github';

export const useSignInUser = () => {
  const { supabase } = useSupabase();

  const signInWithPassword = useCallback(
    async ({ email, password }: SignInWithPasswordProps) => {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      return { data, error };
    },
    [supabase]
  );

  const signInWithOAuth = useCallback(
    async ({ provider, code }: SignInWithOAuthProps) => {
      console.log(code);
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: code
            ? `${CONSTANTS.app.redirectUrl}?code=${code}`
            : CONSTANTS.app.redirectUrl,
          skipBrowserRedirect: false,
        },
      });
      return { data, error };
    },
    [supabase]
  );

  return {
    signInWithPassword,
    signInWithOAuth,
  };
};
