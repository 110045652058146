import { Transition, Dialog } from '@headlessui/react';
import { XCircleIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useTournamentsByPlayer } from '../../../hooks/useTournamentsByPlayer';

export const DraftModal = ({ player, onClose, onSubmit }) => {
  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex flex-col items-center justify-center">
                  <button
                    className="absolute right-0 top-0 m-1 flex w-full justify-end"
                    onClick={onClose}
                  >
                    <XCircleIcon height={30} width={30} />
                  </button>
                  <div className="my-2 flex w-full flex-col gap-4">
                    <div className="text-center">
                      <span className="text-lg">
                        Are you sure you want to draft {player.first_name}{' '}
                        {player.last_name}?
                      </span>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2">
                      <button
                        type="button"
                        onClick={(e) => onSubmit(e)}
                        className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none"
                      >
                        Draft
                      </button>
                      <button
                        type="button"
                        onClick={(e) => onClose()}
                        className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
