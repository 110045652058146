import { supabase } from '../utils';
import { PostgrestFilterBuilder, PostgrestError } from '@supabase/postgrest-js';

export type Player = {
  id: string;
  created_at: string;
  updated_at: string;
  first_name: string;
  last_name: string;
  country: string;
  turned_pro: number;
  height: number;
  weight: number;
  birthday: Date;
  residence: string;
  birth_place: string;
  handedness: string;
  abbr_name: string;
  name: string;
  updated: Date;
  member: boolean;
  college: string;
  profile_url: string;
};

export interface ApiResponse {
  data: Player[];
  error: PostgrestError;
}

export interface ApiArgs {}

export const getData = (): PostgrestFilterBuilder<any, any, Player[]> => {
  return supabase
    .from('players')
    .select('*')
    .or('rank.not.is.null,isException.eq.true');
};
