import { useQuery } from 'react-query';
import { useSupabase } from '../hooksV2/useSupabase';
import { getData } from '../models/users/api';

export const useUser = () => {
  const { user: args } = useSupabase();
  return useQuery(
    ['useUser'],
    async () => getData(args).then((result) => result.data![0]),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!args?.id,
      retry: 1,
    }
  );
};
