import React from 'react';
import { Container } from './Container';
import clsx from 'clsx';

const reasons = [
  {
    reason: 'KEEP THE GOLFERS YOU DRAFT',
    description:
      'The golfers you draft on your team, stay on your team, unless YOU decide to trade or drop during the fantasy golf season.',
  },
  {
    reason: "FANTASY FOOTBALL 'LIKE' SCORING",
    description:
      'Depending on the tournament, points are awarded on a per golfer basis dpending on finishing place, and then totaled by team for all golfers started that tourney.',
  },
  {
    reason: 'FANTASY GOLF DIFFERENCE',
    description:
      'Instead of Head 2 Head matchups each week, teams will accumulate points throughout the fantasy golf season.',
  },
];

export function Reasons() {
  return (
    <div className="bg-black py-24 text-white">
      <Container>
        <h1 className="text-center text-3xl font-bold lg:col-span-9 lg:whitespace-nowrap lg:text-6xl">
          WHY PLAY 3FGOLF?
        </h1>
        <div className="mt-12 grid-cols-1 gap-14 lg:mt-20 lg:grid lg:grid-cols-3">
          {reasons.map(({ reason, description }, index) => (
            <div
              key={reason}
              className={clsx('mt-12 flex flex-col gap-4 text-center lg:mt-0', {
                'lg:w-[110%]': index === 1,
              })}
            >
              <h2 className="font-semibold">{reason}</h2>
              <p className="leading-relaxed">{description}</p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}
