import { useQuery } from 'react-query';
import { getData } from '../models/teamPlayerHistory/api';

export const useTeamPlayerHistory = (args) => {
  return useQuery(
    ['useTeamPlayerHistory', args?.tournamentId],
    async () => getData(args).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: !!args?.tournamentId,
      retry: 1,
    }
  );
};
