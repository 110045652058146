import { useEffect, useState, useCallback } from 'react';
import { useSupabase } from '../hooksV2/useSupabase';

export const useImages = (teams, bucket) => {
  const { supabase } = useSupabase();
  const [images, setImages] = useState([]);

  const getImages = async () => {
    const results = [];
    for (const team of teams) {
      const { data } = await supabase.storage
        .from(bucket)
        .download(team.pic_url);
      const url = URL.createObjectURL(data);
      results.push({ [team.id]: url });
    }
    setImages(results);
  };

  useEffect(() => {
    if (teams) {
      getImages();
    }
  }, [teams]);

  return {
    images,
  };
};
