import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { useResultsByTournamentName } from '../../../hooks/useResultsByTournamentName';
import { TrophyIcon, XCircleIcon } from '@heroicons/react/24/outline';
import {
  formatPlayerName,
  getPreviousYear,
  getTournamentYear,
} from '../../../utils/utils';
import { useState } from 'react';
import { useSupabase } from '../../../hooksV2/useSupabase';

export const TournamentDetailsModal = ({
  tournament,
  onClose,
  hasCompleted,
}) => {
  const { data } = useResultsByTournamentName(
    tournament.name,
    hasCompleted ? getTournamentYear() : getPreviousYear()
  );
  const [images, setImages] = useState([]);
  const { supabase } = useSupabase();

  const fetchImages = async (players) => {
    const promises = [];
    const filteredPlayers = players.filter((player) => !!player.profile_url);
    for (const player of filteredPlayers) {
      const result = supabase.storage
        .from('golfers')
        .getPublicUrl(player.profile_url);
      promises.push(result);
    }
    const result = supabase.storage.from('golfers').getPublicUrl('unknown');
    promises.push(result);
    const results = await Promise.all(promises);
    const mapResults = results.map((result) => result.data.publicUrl);
    setImages(mapResults);
  };

  useEffect(() => {
    if (data) {
      fetchImages(data);
    }
  }, [data]);

  const generateTournamentResults = (tournaments) => {
    const results = tournaments.map((tourny, index) => {
      let imageUrl = images.find((playerImage) =>
        playerImage.includes(tourny.profile_url)
      );
      if (!imageUrl) {
        imageUrl = images.find((playerImage) =>
          playerImage.includes('unknown')
        );
      }
      return (
        <div
          className="flex w-80 flex-row items-center justify-between gap-3 rounded-md border border-black bg-white p-2"
          key={tourny.id}
        >
          <div className="flex flex-row items-center gap-2">
            <img
              src={imageUrl}
              height={30}
              width={30}
              className="rounded-full border"
            />
            <span className="text-sm">{formatPlayerName(tourny.name)}</span>
          </div>
          <div>
            <div className="relative flex flex-col items-center">
              <span className="text-sm">Finished</span>
              <div className="flex items-center">
                {tourny.position === 1 && (
                  <TrophyIcon
                    className="absolute ml-2 text-accent"
                    height={24}
                    width={24}
                  />
                )}
                <span className="text-sm">{tourny.position}</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return results;
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <button
                  className="absolute right-0 top-0 m-1"
                  onClick={onClose}
                >
                  <XCircleIcon height={30} width={30} />
                </button>
                <div className="flex w-full flex-col">
                  <div className="flex justify-end"></div>
                  <div className="my-2 flex flex-col justify-center">
                    <span className="text-lg font-semibold">
                      {tournament.name}
                    </span>
                    <span className="text-sm">
                      {hasCompleted ? getTournamentYear() : getPreviousYear()}{' '}
                      Tournament Results
                    </span>
                  </div>
                  <div className="flex w-full flex-col items-center gap-2">
                    {data && generateTournamentResults(data)}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
