import React from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSkeleton } from '../organisms/loadingSkeleton';
import { MobileNavigation } from '../app/mobileNavigation';
import { useLeague } from '../../hooks/useLeague';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { useTournaments } from '../../hooks/useTournaments';
import { format, parseISO, isPast } from 'date-fns';
import { useState } from 'react';
import { LeagueHeader } from '../organisms/leagueHeader';
import { capitalizeFirstLetter } from '../../utils/utils';
import clsx from 'clsx';
import FlagHole from '../../assets/golf.png';
import Regular from '../../assets/regular.png';
import Major from '../../assets/major.png';
import Playoffs from '../../assets/playoffs.png';
import Signature from '../../assets/signature.png';
import { Modal } from '../app/modal';
import { TournamentDetailsModal } from '../app/modals/tournamentDetails';

export const LeagueSchedule = () => {
  const { teamId, leagueId } = useParams();
  const { data: leagueData } = useLeague(leagueId);
  const { data: scheduleData, isLoading } = useTournaments();
  const [showModal, setShowModal] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState();

  const isPastEvent = (tournament) => {
    const currentYear = new Date().getFullYear();
    const tournamentYear = tournament.year;
    const endDate = parseISO(tournament.end_date);
    if (tournamentYear < currentYear) {
      return true;
    } else if (tournamentYear > currentYear) {
      return false;
    }
    return isPast(endDate);
  };

  const formatDateRange = (startDateStr, endDateStr) => {
    const startDate = parseISO(startDateStr);
    const endDate = parseISO(endDateStr);

    if (startDate.getMonth() === endDate.getMonth()) {
      // Format the date range as 'Feb 24 - 26'
      const formattedStartDate = format(startDate, 'MMM d');
      const formattedEndDate = format(endDate, 'd');
      return (
        <div className="whitespace-nowrap text-xs">
          {formattedStartDate} - {formattedEndDate}
        </div>
      );
    } else {
      // Format the date range as 'Feb 24 - Mar 26' (if the months are different)
      const formattedStartDate = format(startDate, 'MMM d');
      const formattedEndDate = format(endDate, 'MMM d');
      return (
        <div className="whitespace-nowrap text-xs">
          {formattedStartDate} - {formattedEndDate}
        </div>
      );
    }
  };

  const getLivTournamentLocation = (name) => {
    if (name.includes('LIV Golf Invitational')) {
      const nameArr = name.split(' ');
      let locationArr = nameArr.slice(3);
      const result = locationArr.join(' ');
      return result;
    }
    return name;
  };

  const getTournamentIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'regular':
        return <img className="mr-2 h-5 w-5" src={Regular} alt="Regular" />;
      case 'major':
        return <img className="mr-2 h-5 w-5" src={Major} alt="Major" />;
      case 'playoffs':
        return <img className="mr-2 h-5 w-5" src={Playoffs} alt="Playoffs" />;
      case 'signature':
        return <img className="mr-2 h-5 w-5" src={Signature} alt="Signature" />;
      default:
        return null;
    }
  };

  const onClickHandler = (tournament) => {
    setShowModal(true);
    setSelectedTournament(tournament);
  };

  const onCloseHandler = (tournamentId) => {
    setShowModal(false);
    setSelectedTournament(undefined);
  };

  return (
    <>
      <div>
        <div className="min-h-full">
          <div className="bg-gray-800">
            <LeagueHeader leagueId={leagueData?.id} name={leagueData?.name} />
          </div>
          <main>
            {isLoading ? (
              <div className="my-36 flex w-full justify-center">
                <LoadingSkeleton />
              </div>
            ) : (
              <div className="mb-14 mt-2">
                <div className="flex justify-center">
                  <div className="mx-4 mb-2 flex h-10 w-full items-center rounded-md bg-white text-sm">
                    <span className="flex items-center font-semibold">
                      {scheduleData.length > 0 ? scheduleData[0].year : ''}{' '}
                      Tournaments
                    </span>
                  </div>
                </div>
                <div className="flex justify-center bg-white">
                  <div className="w-full rounded-md bg-primary p-2">
                    {scheduleData.length > 0 &&
                      scheduleData.map((schedule, idx) => {
                        const pastEvent = isPastEvent(schedule);
                        return (
                          <div
                            className={clsx(
                              'my-2 flex h-20 justify-between gap-4 rounded-md px-3 text-sm',
                              {
                                'bg-gray-400': pastEvent,
                                'bg-white': !pastEvent,
                              }
                            )}
                            key={schedule.id}
                          >
                            <div className="relative flex items-center">
                              <img className="w-[8ch]" src={FlagHole} alt="" />
                              <span className="absolute top-0 -ml-[.75ch] mt-[4ch] w-full text-center text-[7px] tracking-tight text-white">
                                {idx + 1}
                              </span>
                            </div>
                            <div className="my-1 flex h-full w-full flex-col justify-between">
                              <div className="mt-1 flex w-full flex-row items-center justify-between">
                                <span className="line-clamp-2 flex h-full w-[200px] text-left font-medium leading-5">
                                  {getLivTournamentLocation(schedule.name)}
                                </span>
                                <button
                                  type="button"
                                  className="flex items-center justify-center self-start whitespace-nowrap rounded-md border border-transparent bg-primary px-1 py-0.5 text-xs font-medium text-white shadow-sm"
                                  onClick={(e) => onClickHandler(schedule)}
                                >
                                  View Results
                                </button>
                              </div>
                              <span className="mb-2 grid w-full grid-cols-2 place-content-center">
                                <span className="flex items-center">
                                  {getTournamentIcon(schedule.type)}
                                  {capitalizeFirstLetter(schedule.type)}
                                </span>
                                <span className="flex items-center">
                                  <CalendarDaysIcon
                                    className="mr-2"
                                    width={20}
                                    height={20}
                                  />
                                  {formatDateRange(
                                    schedule.start_date,
                                    schedule.end_date
                                  )}
                                </span>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>
        <MobileNavigation />
      </div>
      {showModal && selectedTournament && (
        <Modal>
          <TournamentDetailsModal
            onClose={onCloseHandler}
            tournament={selectedTournament}
            hasCompleted={isPastEvent(selectedTournament)}
          />
        </Modal>
      )}
    </>
  );
};
