import IFrame from 'react-iframe';
import { MobileNavigation } from '../app/mobileNavigation';

export const QualityPage = () => {
  return (
    <div className="w-full">
      <IFrame
        url="https://www.pgatour.com/article/news/golfbet/major-qualifiers"
        className="min-h-screen min-w-full"
      />
      <MobileNavigation />
    </div>
  );
};
