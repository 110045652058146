import { useParams } from 'react-router';
import { useMessages } from './useMessages';
import { supabase } from '../models/utils';
import { useEffect, useState } from 'react';
import { getData } from '../models/messages/api';
import { getDataByTeamId } from '../models/teams/api';

export const useMessageBoard = (leagueId) => {
  const [messages, setMessages] = useState([]);
  const [teamMap, setTeamMap] = useState({});

  const fetchImage = async (message) => {
    const { data } = await supabase.storage
      .from('team-logos')
      .download(`${leagueId}/${message.team_id}`);
    let pic = data;
    if (!pic) {
      if (teamMap[message.team_id]) return;
      const { data: defaultPictureData } = await supabase.storage
        .from('team-logos')
        .download('user-default.png');
      pic = defaultPictureData;
    }
    return { data: pic };
  };

  const fetchMessages = async () => {
    const { data, error } = await getData(leagueId);
    const teamMap = await data.reduce(async (acc, curr) => {
      const acculumlator = await acc;
      if (!acculumlator[curr.team_id]) {
        const { data: imageData } = await fetchImage(curr);
        const { data: teamData } = await getDataByTeamId({ id: curr.team_id });
        const result = URL.createObjectURL(imageData);
        acculumlator[curr.team_id] = { imageUrl: result, team: teamData[0] };
      }
      return acculumlator;
    }, {});
    const messages = data.map((message) => {
      return {
        ...message,
        imageUrl: teamMap[message.team_id].imageUrl,
        team_id: teamMap[message.team_id].team,
      };
    });
    setTeamMap(teamMap);
    setMessages([...messages]);
  };

  useEffect(() => {
    if (leagueId && messages.length === 0) {
      fetchMessages();
    }
  }, [leagueId]);

  useEffect(() => {
    if (supabase && leagueId) {
      supabase
        .channel(leagueId)
        .on(
          'postgres_changes',
          { event: 'INSERT', schema: 'public', table: 'messages' },
          async (payload) => {
            if (payload.new.league_id !== leagueId) return;
            const newMessage = payload.new;
            let imageUrl = teamMap[newMessage.team_id]?.imageUrl;
            let team = teamMap[newMessage.team_id]?.team;
            if (!imageUrl) {
              const { data } = await fetchImage(newMessage);
              const result = URL.createObjectURL(data);
              const { data: teamData } = await getDataByTeamId({
                id: newMessage.team_id,
              });
              teamMap[newMessage.team_id] = {
                imageUrl: result,
                team: teamData,
              };
              setTeamMap({ ...teamMap, [newMessage.team_id]: result });
              imageUrl = result;
              team = teamData;
            }
            newMessage.imageUrl = imageUrl;
            newMessage.team_id = team;
            setMessages((currentMessages) => [...currentMessages, newMessage]);
          }
        )
        .subscribe((status) => {
          // console.log(status);
        });
    }
    return () => {
      supabase.channel(leagueId).unsubscribe();
    };
  }, [supabase, leagueId, teamMap]);

  return {
    status: 'listening',
    data: messages,
  };
};
