import { Player } from '../models/players/api';
import { getResults } from '../models/results/api';
import { SCORING_SYSTEM } from '../utils/constants';
import {
  determineMultiplier,
  getCurrentYear,
  getPreviousYear,
  getTournamentYear,
} from '../utils/utils';

export const usePlayersScoring = () => {
  const getPlayersResults = async (players: Player[], isDrafted) => {
    const { data: playerResults } = await getResults({
      playerIds: players ? players.map((row) => row?.id) : [],
      year: isDrafted ? getCurrentYear() : getPreviousYear(),
    });
    const filteredResults = playerResults.filter((player) => !player.status);
    const playerScores = filteredResults.reduce((acc, curr) => {
      let score =
        (SCORING_SYSTEM[curr.position] ?? 0) *
        determineMultiplier(curr.tournament_id.type);

      if (curr.tied && score > 0) {
        let tiedScore = 0;
        for (let i = 0; i < curr.num_tied; i++) {
          tiedScore +=
            (SCORING_SYSTEM[curr.position + i] ?? 0) *
            determineMultiplier(curr.tournament_id.type);
        }
        score = tiedScore / curr.num_tied;
      }
      if (acc[curr.player_id]) {
        acc[curr.player_id].score += score;
        acc[curr.player_id].places.push(curr.position);
      } else {
        acc[curr.player_id] = {
          score: 0,
          places: [],
        };
        acc[curr.player_id].score = score;
        acc[curr.player_id].places.push(curr.position);
      }
      return acc;
    }, {});

    const playersWithScores = players?.reduce((acc, curr) => {
      if (!playerScores[curr.id]) {
        curr['score'] = 0;
        curr['places'] = 0;
        acc.push(curr);
        return acc;
      }
      curr['score'] = (
        Math.round(playerScores[curr.id].score * 100) / 100
      ).toFixed(2);
      if (Number(curr['score']) === 0) curr['score'] = 0;
      const sum = playerScores[curr.id].places?.reduce(
        (place, curr) => curr + place
      );
      curr['places'] = Math.ceil(sum / playerScores[curr.id].places.length);
      acc.push(curr);
      return acc;
    }, []);
    return { playersWithScores };
  };
  return { getPlayersResults };
};
