import React, { useState } from 'react';
import {
  HomeIcon,
  NewspaperIcon,
  EllipsisHorizontalIcon,
  AcademicCapIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { DashboardSettingsModal } from '../modals/dashboardSettings';

const NavigationLinks = [
  {
    url: '/dashboard',
    name: 'Home',
    logo: HomeIcon,
  },
  {
    url: '/intro',
    name: 'How To Play',
    logo: AcademicCapIcon,
  },
  {
    url: '/fedex',
    name: 'FedEx',
    logo: NewspaperIcon,
  },
  {
    url: '/qualify',
    name: 'Qualifiers',
    logo: TrophyIcon,
  },
];

export const MobileNavigation = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const onClickHandler = (e) => {
    setShowModal(true);
  };
  const onCloseHandler = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="z-99 fixed bottom-0 left-0 flex h-[60px] w-full flex-row items-center justify-center bg-[#3F3E3E]">
        <div className="flex w-80 flex-row items-end justify-around p-2">
          {NavigationLinks.map((nav) => (
            <div className="text-white" key={nav.url}>
              <Link
                to={nav.url}
                className="flex flex-col items-center focus:text-primary"
              >
                <nav.logo className="h-5 text-sm" />
                <span className="text-xs">{nav.name}</span>
              </Link>
            </div>
          ))}
          <button
            onClick={(e) => onClickHandler(e)}
            className="flex flex-col items-center justify-end"
          >
            <EllipsisHorizontalIcon
              className="text-white"
              height={30}
              width={30}
            />
            <span className="text-xs text-white">Settings</span>
          </button>
        </div>
      </div>
      {showModal && <DashboardSettingsModal onClose={onCloseHandler} />}
    </>
  );
};
