import { supabase } from '../utils';

export const getData = (teamId) => {
  return supabase
    .from('trades')
    .select('*, sender_team_id (*), receiver_team_id(*)')
    .or(`sender_team_id.eq.${teamId},receiver_team_id.eq.${teamId}`)
    .eq('status', 'pending');
};

export const postData = ({ trade, tradeId, leagueId }) => {
  return supabase.from('trades').insert({
    transaction_id: tradeId,
    sender_team_id: trade.senderTeamId,
    receiver_team_id: trade.receiverTeamId,
    league_id: leagueId,
  });
};

export const putData = (status, id): any => {
  return supabase
    .from('trades')
    .update({ status })
    .eq('transaction_id', id)
    .select('*');
};
