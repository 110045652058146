import {
  differenceInYears,
  parseISO,
  parse,
  format,
  fromUnixTime,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { SCORING_SYSTEM, TOURNAMENT_TYPES } from './constants';

export const calculateAge = (dateString) => {
  const birthDate = parseISO(dateString);
  const today = new Date();
  return differenceInYears(today, birthDate);
};

export const inchesToFeetAndInches = (inches) => {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return `${feet}' ${remainingInches}"`;
};

export const yearsDifferenceFromCurrent = (year) => {
  const inputDate = new Date(year, 0, 1); // January 1 of the given year
  const today = new Date();
  return differenceInYears(today, inputDate);
};

export const formatDate = (inputDate) => {
  // Parse the input date string
  const date = parse(inputDate, 'yyyy-MM-dd', new Date());

  // Format the parsed date
  return format(date, 'MMM do, yyyy');
};

export const mapPositionToPts = (position) => {
  return SCORING_SYSTEM[position];
};

export const getLocationFromTournamentName = (name) => {
  if (name.includes('LIV Golf Invitational')) {
    const nameArr = name.split(' ');
    let locationArr = nameArr.slice(3);
    const result = locationArr.join(' ');
    return result;
  }
  return name;
};

export const convertUnixTimestampToReadable = (unixTimestamp) => {
  // Convert the Unix timestamp to a JavaScript Date object
  const date = fromUnixTime(unixTimestamp);

  // Format the date in a human-readable format
  // The format string can be adjusted according to your needs
  const readableDate = format(date, 'PPPppp');

  return readableDate;
};

export const convertUnixTimestampToTimezone = (
  unixTimestamp,
  timezone: any
) => {
  // Convert Unix timestamp to a JavaScript Date object
  const date = fromUnixTime(unixTimestamp);

  // Convert the UTC date to the specified timezone
  const zonedDate = utcToZonedTime(date, timezone);

  // Format the date into a human-readable format
  // Adjust the format string as needed
  const formattedDate = format(zonedDate, 'MMM. do h:mma yyyy');

  return formattedDate;
};

export const appendOrdinalSuffix = (number) => {
  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits === 11 || lastTwoDigits === 12 || lastTwoDigits === 13) {
    return `${number}th`;
  } else if (lastDigit === 1) {
    return `${number}st`;
  } else if (lastDigit === 2) {
    return `${number}nd`;
  } else if (lastDigit === 3) {
    return `${number}rd`;
  } else {
    return `${number}th`;
  }
};

export const getPreviousYear = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();
  // Check if the current month is past September or if it is September and the day is past the 15th
  return currentMonth > 8 || (currentMonth === 8 && currentDay > 15)
    ? currentYear
    : currentYear - 1;
};

export const getCurrentYear = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();
  // Check if the current month is past September or if it is September and the day is past the 15th
  return currentMonth > 8 || (currentMonth === 8 && currentDay > 15)
    ? currentYear + 1
    : currentYear;
};

export const formatNumberWithCommas = (number, isDecimal = true) => {
  let result = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
  if (!isDecimal) {
    result = new Intl.NumberFormat('en-US').format(number);
  }
  return result;
};

export const isEven = (n) => {
  return n % 2 == 0;
};

export const isOdd = (n) => {
  return Math.abs(n % 2) == 1;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return string; // return the original string if it's empty or not a string
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const determineMultiplier = (type) => {
  return TOURNAMENT_TYPES[type] ?? 1;
};

export const getTournamentYear = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();
  // Check if the current month is past September or if it is September and the day is past the 15th
  return currentMonth > 8 || (currentMonth === 8 && currentDay > 15)
    ? currentYear + 1
    : currentYear;
};

export const formatPlayerName = (name) => {
  if (name.includes(',')) {
    return name.split(',').reverse().join(' ').trim();
  }
  return name;
};

export const base64Encode = (str) => {
  // Encode the String
  try {
    // For Unicode string handling
    const encoded = window.btoa(unescape(encodeURIComponent(str)));
    return encoded;
  } catch (e) {
    console.error('Base64 encoding failed:', e);
    return null;
  }
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const formattedMessageDate = (message) => {
  return format(parseISO(message.created_at), 'EEE, MMM d • p');
};

export const isValidEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const determinedPts = (result) => {
  let score =
    result.status === 'CUT'
      ? 0
      : (SCORING_SYSTEM[result.position] ?? 0) *
        determineMultiplier(result.tournament_id.type);
  if (result.tied && score > 0) {
    let tiedScore = 0;
    for (let i = 0; i < result.num_tied; i++) {
      tiedScore +=
        (SCORING_SYSTEM[result.position + i] ?? 0) *
          determineMultiplier(result.tournament_id.type) ?? 0;
    }
    score = tiedScore / result.num_tied;
  }
  return score.toFixed(2);
};

// pointCalculation(results) {
//   let score = (SCORING_SYSTEM[results.position] ?? 0) * (this.determineMultiplier(results.tournament_id.type));
//   if (results.tied && score > 0) {
//     let tiedScore = 0;
//     for (let i = 0; i < results.num_tied; i++) {
//       tiedScore += SCORING_SYSTEM[results.position + i] * (this.determineMultiplier(results.tournament_id.type)) ?? 0;
//     }
//     score = tiedScore / results.num_tied;
//   }
//   return score.toFixed(2);
// }

export const isDayBetweenThursdayAndMonday = (): boolean => {
  const today = new Date();
  const dayOfWeek = today.getDay();

  // In JavaScript & TypeScript, Sunday - Saturday : 0 - 6
  // Therefore, Thursday - Sunday : 4 - 0
  return dayOfWeek <= 1 || dayOfWeek >= 4;
};

export const formatReadableDate = (dateString: string): string => {
  const date = parseISO(dateString);
  return format(date, 'MMM do, yyyy');
};

export const getCurrentTimestamp = () => {
  // Get the current date and time
  const now = new Date();

  // Format the date and time to the PostgreSQL 'timestamp' format
  const dateFormat = 'yyyy-MM-dd HH:mm:ss';
  const formattedDate = format(now, dateFormat);

  return formattedDate;
};
