import React from 'react';
import { NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Popover } from '@headlessui/react';
import { Container } from './Container';
import { Logo } from './Logo';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { SoundCloudIcon } from './StockLogos';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { NavLinks } from './NavLinks';

function MenuIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M5 6h14M5 18h14M5 12h14"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="white"
      />
    </svg>
  );
}

function ChevronUpIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path
        d="M17 14l-5-5-5 5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function MobileNavLink(props) {
  return (
    <Popover.Button
      as={NavLink}
      className="block text-base leading-7 tracking-tight text-gray-700"
      {...props}
    />
  );
}

export function Header() {
  return (
    <header>
      <nav>
        <Container className="relative z-50 flex justify-between py-8 text-white">
          <div className="relative z-10 flex items-center lg:gap-4">
            <div className="hidden lg:flex lg:gap-10">
              <NavLinks
                paths={[
                  ['SIGN UP', '/register'],
                  ['LOG IN', '/login'],
                  ['CONTACT', '/contact'],
                ]}
              />
            </div>
          </div>
          <NavLink href="/" aria-label="Home">
            <div className="flex items-center justify-center">
              <Logo className="h-10 w-auto" />
              &nbsp;&nbsp;
              <span className="text-3xl font-bold">3FGolf</span>
            </div>
          </NavLink>
          <div className="flex items-center gap-6 lg:hidden">
            <Popover>
              {({ open }) => (
                <>
                  <Popover.Button
                    className="ui-not-focus-visible:outline-none relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900"
                    aria-label="Toggle site navigation"
                  >
                    {({ open }) =>
                      open ? (
                        <ChevronUpIcon className="h-6 w-6" />
                      ) : (
                        <MenuIcon className="h-6 w-6" />
                      )
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                        >
                          <div className="space-y-4">
                            <MobileNavLink to="/login">Log In</MobileNavLink>
                            <MobileNavLink href="/#features">
                              Features
                            </MobileNavLink>
                            <MobileNavLink href="/#reviews">
                              Reviews
                            </MobileNavLink>
                          </div>
                          <div className="mt-8 flex flex-col gap-4">
                            <NavLink to="login" variant="outline">
                              Log in
                            </NavLink>
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
          </div>
          <div className="hidden items-center gap-6 lg:flex">
            <a href="#">
              <InstagramIcon />
            </a>
            <a href="#">
              <TwitterIcon />
            </a>
            <a href="#">
              <SoundCloudIcon className="h-6 w-6" />
            </a>
            <a href="#">
              <YouTubeIcon />
            </a>
          </div>
        </Container>
      </nav>
    </header>
  );
}
