import { useMyTeam } from './useMyTeam';
import { useTrades } from './useTrades';
import { useTradesPlayers } from './useTradesPlayers';

export const useNotifications = () => {
  const { data: myTeamData } = useMyTeam();
  const { data } = useTrades(myTeamData?.id ?? '');

  const tradeIds = data?.map((d) => d.transaction_id) ?? [];
  const { data: tradesPlayersData } = useTradesPlayers(tradeIds);

  const tradeData = [];

  data?.forEach((d) => {
    const filtered = tradesPlayersData?.filter(
      (player) => player.transaction_id === d.transaction_id
    );
    if (filtered) {
      tradeData.push({
        ...d,
        players: filtered,
      });
    }
  });

  return {
    data: tradeData,
  };
};
