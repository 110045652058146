import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useJoinLeague } from '../../../hooks/useJoinLeague';

type Form = {
  code: string;
};

type Props = {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  onBack: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (data) => void;
};

export function InviteModal({ onClose, onBack, onSuccess }: Props) {
  const { joinLeague } = useJoinLeague();
  const [form, setForm] = useState<Form>({
    code: '',
  });
  const onSubmitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { data } = await joinLeague({ code: form.code });
    onSuccess(data);
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <div>
        <div className="mt-3 text-left">
          <Dialog.Title
            as="h3"
            className="text-center text-lg font-medium leading-6 text-gray-900"
          >
            Join League
          </Dialog.Title>
          <div className="my-5">
            <p className="text-center text-sm text-gray-500">
              ↓ Paste in your league code below ↓
            </p>
            <div>
              <div className="mt-1 rounded-md border border-gray-800">
                <input
                  type="text"
                  name="code"
                  id="code"
                  value={form.code}
                  onChange={onChangeHandler}
                  className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="3456789"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
        >
          Submit
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
          onClick={() => onBack(false)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}
