import React, { useState, useCallback, useEffect } from 'react';
import { LeagueNavigation } from '../leagueNavigation';
import { ArrowSmallLeftIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { Logo } from '../../landing/Logo';
import { Modal } from '../../app/modal';
import { LeagueSettingsModal } from '../../app/modals/leagueSettings';
import { useLeagueDataByParams } from '../../../hooks/useLeagueDataByParams';
import {
  BellIcon,
  ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/outline';
import { useNotifications } from '../../../hooks/useNotifications';
import { useLeague } from '../../../hooks/useLeague';
import { useLocation } from 'react-router-dom';

type Props = {
  name: string;
  leagueId: string;
};

export const LeagueHeader = (props: Props) => {
  const navigate = useNavigate();
  const { leagueId } = useParams();
  const { data } = useLeagueDataByParams();
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const { data: notData } = useNotifications();
  const [dot, showDot] = useState(false);
  const [numOfNotifications, setNumOfNotifications] = useState<number>(0);
  const { data: leagueData } = useLeague(leagueId);
  const location = useLocation();

  useEffect(() => {
    if (notData.find((notification) => notification.status === 'pending')) {
      setNumOfNotifications(notData.length);
      showDot(true);
    } else {
      setNumOfNotifications(0);
      showDot(false);
    }
  }, [notData]);

  const onCloseHandler = useCallback((value) => {
    setShowSettings(value);
  }, []);

  const handleBackClick = () => {
    if (location.pathname === `/league/${leagueId}`) {
      navigate('/dashboard');
    } else if (!leagueData?.isDrafted) {
      navigate(`/league/${leagueId}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div className="flex w-full items-center justify-between bg-gray-800 px-2 py-2">
        <button onClick={handleBackClick}>
          <ArrowSmallLeftIcon width={25} height={25} className="text-white" />
        </button>
        <div className="flex flex-row items-center justify-center">
          <Logo />
          <h2 className="m-2 text-2xl text-white">{props.name}</h2>
        </div>
        <div className="flex flex-row items-center gap-3">
          <Link to={`/league/${leagueId}/message`} className="relative">
            <ChatBubbleLeftEllipsisIcon
              height={30}
              width={30}
              className="text-white"
            />
          </Link>
          <Link to={`/league/${leagueId}/notifications`} className="relative">
            <BellIcon height={30} width={30} className="text-white" />
            {dot && numOfNotifications && (
              <div className="absolute right-0 top-0 flex h-[25] w-[25] items-center justify-center">
                <span className="flex h-[15px] w-[15px] items-center justify-center rounded-full bg-red-700 text-[8pt] text-white">
                  {numOfNotifications}
                </span>
              </div>
            )}
          </Link>
          <button onClick={() => onCloseHandler(true)}>
            <Cog6ToothIcon height={30} width={30} className="text-white" />
          </button>
        </div>
      </div>
      <LeagueNavigation leagueId={props.leagueId} />
      {showSettings && (
        <Modal>
          <LeagueSettingsModal
            onClose={() => onCloseHandler(false)}
            league={data}
          />
        </Modal>
      )}
    </>
  );
};
