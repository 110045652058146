import { useQuery } from 'react-query';
import { getData } from '../models/teams/api';

export const useTeams = (args) => {
  return useQuery(
    ['useTeams', args?.id],
    async () => getData(args).then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: !!args?.id,
      retry: 1,
    }
  );
};
