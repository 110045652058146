import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTeam } from '../../hooks/useTeam';
import { PlayerDisplaySection } from '../organisms/playerDisplaySection';
import { LoadingSkeleton } from '../organisms/loadingSkeleton';
import { MobileNavigation } from '../app/mobileNavigation';
import { useLeague } from '../../hooks/useLeague';
import { LeagueHeader } from '../organisms/leagueHeader';
import { usePlayerResultsByTeamId } from '../../hooks/usePlayerResultsByTeam';
import DraftTeam from '../../assets/draftteam.png';
import { formatNumberWithCommas } from '../../utils/utils';
import { useTeamScores } from '../../hooks/useTeamScores';
import { Modal } from '../app/modal';
import { ScoreHistoryModal } from '../app/modals/scoreHistoryModal';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CursorArrowRippleIcon,
} from '@heroicons/react/24/outline';
import { useTeamsByLeague } from '../../hooks/useTeamsByLeague';
import TeamScoreHistoryModal from '../app/modals/teamScoreHistoryModal';

export const TeamOverviewPage = () => {
  const { teamId, leagueId } = useParams();
  const { data: leagueData } = useLeague(leagueId);
  const { data, isLoading } = useTeam(teamId);
  const { data: teamsData } = useTeamsByLeague(leagueId);
  const { data: teamScores } = useTeamScores(teamsData, leagueId);
  const { data: players } = usePlayerResultsByTeamId(teamId);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(0);

  const team = teamScores?.find((score: any) => score.team_id.id === teamId);
  console.log(teamScores);

  const firstName = data?.user_id?.first_name;
  const lastName = data?.user_id?.last_name;

  const onCloseHandler = () => {
    setShowScoreModal(false);
  };

  const onClickHandler = () => {
    setShowScoreModal(true);
  };

  const onClickHistoryHandler = (increment: number) => {
    if (increment + historyIndex < 0) return;
    setHistoryIndex(historyIndex + increment);
  };

  const onCloseHistoryHandler = (increment: number) => {
    setHistoryIndex(0);
  };

  return (
    <div className="flex min-h-full flex-col">
      <LeagueHeader leagueId={leagueData?.id} name={leagueData?.name} />
      <main className="w-full pb-5">
        <div className="">
          <div className="ml-3 flex flex-col">
            <h1 className="ml-2 text-lg font-bold tracking-tight text-black">
              {data?.name ?? ''}
            </h1>
            <h2 className="text-sm">{`${
              firstName && lastName
                ? `(${data?.user_id?.first_name} ${data?.user_id?.last_name})`
                : ''
            }`}</h2>
          </div>
          <div className="my-2 flex flex-row items-center justify-between">
            <div className="">
              <button onClick={() => onClickHistoryHandler(1)}>
                <ArrowLeftIcon height={30} width={30} />
              </button>
            </div>
            <button
              className="ml-2 flex flex-row items-center gap-2 rounded-md border-2 p-2"
              onClick={onClickHandler}
            >
              <CursorArrowRippleIcon height={15} width={15} />
              <h1 className="font-semibold">Total Points:</h1>
              <div>{formatNumberWithCommas(team?.score ?? 0)}</div>
            </button>
            <div>
              <button onClick={() => onClickHistoryHandler(-1)}>
                <ArrowRightIcon height={30} width={30} />
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="my-36 flex w-full justify-center">
            <LoadingSkeleton />
          </div>
        ) : (
          <div className="mt-2">
            {leagueData?.isDrafted ? (
              <PlayerDisplaySection players={players} title={data?.name} />
            ) : (
              <div className="fixed flex h-screen w-screen flex-col items-center bg-primary">
                <div className="mx-2 flex flex-col items-center justify-center gap-4">
                  <div className="mt-10 text-center text-white">
                    Come back to see your team after the draft!
                  </div>
                  <img
                    className="aspect-square rounded-lg object-cover"
                    src={DraftTeam}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </main>
      <MobileNavigation />
      {showScoreModal && (
        <Modal>
          <ScoreHistoryModal onClose={onCloseHandler} teamId={teamId} />
        </Modal>
      )}
      {historyIndex > 0 && (
        <Modal>
          <TeamScoreHistoryModal
            onClose={onCloseHistoryHandler}
            teamId={teamId}
          />
        </Modal>
      )}
    </div>
  );
};
