import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LoginPage } from '../pages/login';
import { LandingPage } from '../pages/landing';
import { RegisterPage } from '../pages/register';
import { DashboardPage } from '../pages/dashboard';
import { ErrorPage } from '../pages/error';
import { LeagueOverviewPage } from '../pages/leagueOverview';
import { TeamOverviewPage } from '../pages/teamOverview';
import { NewsPage } from '../pages/news';
import { DraftPage } from '../pages/leagueOverview/draft/index';
import { LeagueSchedule } from '../pages/leagueSchedule';
import { LeaguePlayers } from '../pages/leaguePlayers';
import { Notifications } from '../pages/notifications';
import { MessageBoardPage } from '../pages/messageBoard';
import { WaitingPage } from '../pages/waiting';
import { QualityPage } from '../pages/qualify';
import { FedexPage } from '../pages/fedex';
import { IntroPage } from '../pages/intro';
import { PasswordReset } from '../pages/passwordReset';
import { PasswordChange } from '../pages/passwordChange';

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/waiting" element={<WaitingPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="league/:leagueId" element={<LeagueOverviewPage />} />
      <Route
        path="league/:leagueId/notifications"
        element={<Notifications />}
      />
      <Route path="league/:leagueId/draft" element={<DraftPage />} />
      <Route path="league/:leagueId/message" element={<MessageBoardPage />} />
      <Route path="league/:leagueId/schedule" element={<LeagueSchedule />} />
      <Route path="league/:leagueId/players" element={<LeaguePlayers />} />
      <Route path="news" element={<NewsPage />} />
      <Route path="fedex" element={<FedexPage />} />
      <Route path="intro" element={<IntroPage />} />
      <Route path="qualify" element={<QualityPage />} />
      <Route path="passwordReset" element={<PasswordReset />} />
      <Route path="passwordChange" element={<PasswordChange />} />
      <Route
        path="league/:leagueId/team/:teamId"
        element={<TeamOverviewPage />}
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>
);
