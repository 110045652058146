import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSupabase } from '../hooksV2/useSupabase';
import { Player } from '../models/players/api';
import { Draft, putData } from '../models/draft/api';
import { useDraft } from './useDraft';
import { usePlayers } from './usePlayers';
import { useTeamsByLeague } from './useTeamsByLeague';
import { getData } from '../models/draft/api';
import { postData } from '../models/teamPlayer/api';
import { useNavigate } from 'react-router-dom';
import { putData as putLeagueData } from '../models/leagues/api';
import { useQueryClient } from 'react-query';
import { useLeague } from './useLeague';

type Props = {
  leagueId: string;
  myTeamId: string;
};

interface CalculatedPlayer extends Player {
  status: string;
}

type DraftPlayerProps = {
  teamId: string;
  playerId: string;
  draftNumber: number;
};

export const useDraftMachine = ({ leagueId, myTeamId }: Props) => {
  const { supabase } = useSupabase();
  const { data: players } = usePlayers();
  const { data: draftData, isFetched: draftIsFetched } = useDraft({ leagueId });
  const [availablePlayers, setAvailablePlayers] = useState<Player[]>();
  const [draftedPlayers, setDraftedPlayers] = useState<Draft[]>([]);
  const [currentDraftNumber, setCurrentDraftNumber] = useState<number>(0);
  const { data: leagueData } = useLeague(leagueId);
  const navigate = useNavigate();
  const { data: teamsData } = useTeamsByLeague(leagueId);
  const queryClient = useQueryClient();

  const highestDraftNumber = useMemo(() => {
    if (!teamsData || !leagueData) return 99999;
    return teamsData.length * leagueData.team_player_limit - 1;
  }, [leagueData, teamsData]);

  const draftPlayer = useCallback(
    async ({ teamId, playerId, draftNumber }: DraftPlayerProps) => {
      const result = await putData({
        team_id: teamId,
        player_id: playerId,
        draft_number: draftNumber,
      });
      queryClient.invalidateQueries(['useDraft', leagueId]);
      if (draftNumber === highestDraftNumber) {
        const { data } = await getData({ leagueId: leagueId });
        const payload = data.filter((instance) => !!instance.player_id);
        const { error } = await postData({ drafts: payload });
        const { error: leagueError } = await putLeagueData({
          id: leagueId,
          isDrafted: true,
        });
        queryClient.invalidateQueries(['useLeagueScoring', leagueId]);
        queryClient.invalidateQueries(['useLeague', leagueId]);
        queryClient.invalidateQueries(['useDraft', leagueId]);
      }
    },
    [highestDraftNumber, leagueId, draftData]
  );

  useEffect(() => {
    if (!myTeamId) return;
    supabase
      .channel('any')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'draft' },
        (payload: any) => {
          if (
            draftedPlayers.find(
              (player) => player.draft_number === payload.new.draft_number
            )
          )
            return;
          const newPlayer = {
            ...payload.new,
            player_id: availablePlayers.find(
              (player) => player.id === payload.new.player_id
            ),
            team_id: teamsData.find((team) => team.id === payload.new.team_id),
          };
          setDraftedPlayers((players) => [...players, newPlayer]);
          setAvailablePlayers((players) =>
            [...players]
              .filter((player) => player.id !== payload.new.player_id)
              .sort(function (a, b) {
                var textA = a.last_name.toUpperCase();
                var textB = b.last_name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
          );
          queryClient.invalidateQueries(['useDraft', leagueId]);
          if (payload.new.draft_number === highestDraftNumber) {
            setCurrentDraftNumber(highestDraftNumber);
            queryClient.invalidateQueries(['useLeagueScoring', leagueId]);
            queryClient.invalidateQueries(['useLeague', leagueId]);
          }
          setCurrentDraftNumber((num) => num + 1);
        }
      )
      .subscribe();

    return () => {
      supabase.channel('any').unsubscribe();
    };
  }, [
    supabase,
    highestDraftNumber,
    availablePlayers,
    myTeamId,
    draftedPlayers,
    teamsData,
  ]);

  const filterPlayers = (playerData: Draft[]) => {
    const draftedPlayers = [];
    for (let i = 0; i < playerData.length; i++) {
      if (playerData[i]?.player_id) {
        draftedPlayers.push(playerData[i]);
      } else {
        break;
      }
    }
    return draftedPlayers;
  };

  useEffect(() => {
    if (players && draftIsFetched && teamsData) {
      const draftedPlayers = filterPlayers(draftData);
      const availPlayers = players
        .filter((player) => {
          for (let i = 0; i < draftedPlayers.length; i++) {
            if (draftedPlayers[i].player_id.id === player.id) return false;
          }
          return true;
        })
        .sort(function (a, b) {
          var textA = a.last_name.toUpperCase();
          var textB = b.last_name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

      setCurrentDraftNumber(draftedPlayers.length);
      setDraftedPlayers(draftedPlayers);
      setAvailablePlayers(availPlayers);
    }
  }, [players, draftIsFetched, draftData, teamsData]);

  return {
    availablePlayers,
    draftedPlayers,
    draftingOrder: draftData,
    currentDraftNumber,
    draftPlayer,
  };
};
