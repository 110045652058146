import { useParams } from 'react-router';
import { useTeamsByLeague } from './useTeamsByLeague';
import { useUser } from './useUser';

export const useMyTeam = (leagueIdProps = undefined) => {
  const { leagueId } = useParams();
  const { data: userData, isLoading } = useUser();
  const { data: teamsData, isLoading: teamsLoading } = useTeamsByLeague(
    leagueIdProps ?? leagueId
  );

  return {
    data:
      teamsData && userData
        ? teamsData.find((team) => team.user_id === userData.id)
        : undefined,
    isLoading: teamsLoading || isLoading,
  };
};
