import { useEffect, useState, Fragment } from 'react';
import { useUser } from '../../../hooks/useUser';
import { useUpdateUser } from '../../../hooks/useUpdateUser';
import { Transition, Dialog } from '@headlessui/react';
import { useQueryClient } from 'react-query';

export const DashboardUserAccountSettings = ({ onClose }) => {
  const { data } = useUser();
  const { updateUser } = useUpdateUser();
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    username: '',
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
  });
  const queryClient = useQueryClient();

  const validate = () => {
    let tempErrors = {
      firstName: '',
      lastName: '',
    };
    if (!form.firstName.trim()) tempErrors.firstName = 'First Name is required';
    if (!form.lastName.trim()) tempErrors.lastName = 'Last Name is required';

    setErrors(tempErrors);
    return Object.values(tempErrors).filter((value) => !!value).length === 0;
  };

  const onChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    // Optionally, validate on change
    // validate();
  };

  const onSubmit = async () => {
    console.log(validate());
    if (validate()) {
      const { error } = await updateUser({
        firstName: form.firstName,
        lastName: form.lastName,
        username: form.username,
      });
      if (error) console.log(error);
      setForm({
        ...form,
        firstName: '',
        lastName: '',
      });
      queryClient.invalidateQueries(['useUser']);
      onClose();
    }
  };

  useEffect(() => {
    if (data) {
      setForm({
        firstName: data.first_name,
        lastName: data.last_name,
        username: data.username,
      });
    }
  }, [data]);

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit w-full transform items-center overflow-hidden rounded-lg bg-white px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex w-full flex-col gap-4 rounded-md bg-primary p-4">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={form.firstName}
                        onChange={onChangeHandler}
                      />
                      {errors.firstName && (
                        <span className="text-red-500">{errors.firstName}</span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                    >
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={form.lastName}
                        onChange={onChangeHandler}
                      />
                      {errors.lastName && (
                        <span className="text-red-500">{errors.lastName}</span>
                      )}
                    </div>
                  </div>
                  <div className="mt-2 flex flex-col gap-3 border-t-2 p-2">
                    <button
                      className="w-full rounded-lg border-2 bg-white px-4 py-2 text-slate-700 duration-300 hover:bg-accent hover:text-white"
                      onClick={onSubmit}
                    >
                      Save
                    </button>
                    <button
                      className="w-full rounded-lg border-2 bg-white px-4 py-2 text-slate-700 duration-300 hover:bg-accent hover:text-white"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
