import { useCallback } from 'react';
import { postData } from '../models/trades_players/api';
import { postData as postTrade } from '../models/trades/api';
import { v4 as uuidv4 } from 'uuid';
import { getDataWithUser } from '../models/teams/api';
import axios from 'axios';
import { useMyLeague } from './useMyLeague';

type trade = {
  senderTeamId: string;
  receiverTeamId: string;
  leagueId: string;
};

export const useCreateTrade = () => {
  const { data: leagueData } = useMyLeague();
  const createTrade = useCallback(
    async (players, trade: trade) => {
      const tradeId = uuidv4();
      const { data: tradeData, error: tradeError } = await postTrade({
        trade,
        tradeId,
        leagueId: trade.leagueId,
      });
      const { data, error } = await postData({
        tradeId,
        tradesPlayers: players,
      });
      const { data: teamData } = await getDataWithUser(trade.receiverTeamId);
      const { data: senderTeamData } = await getDataWithUser(
        trade.senderTeamId
      );
      axios.post('https://www.3fgolf.com/api/sendEmail', {
        receiverEmail: teamData[0].user_id.email,
        teamName: senderTeamData[0].name,
        leagueName: leagueData.name,
      });
      return { error: tradeError || error, data: tradeData && data };
    },
    [leagueData]
  );

  return {
    createTrade,
  };
};
