import { useParams } from 'react-router';
import { useLeague } from './useLeague';

export const useMyLeague = () => {
  const { leagueId } = useParams();
  const { data, isLoading } = useLeague(leagueId);

  return {
    data,
    isLoading,
  };
};
