import { Transition, Dialog } from '@headlessui/react';
import {
  ArrowRightIcon,
  XCircleIcon,
  CalendarIcon,
} from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { useTournamentsByPlayer } from '../../../hooks/useTournamentsByPlayer';
import {
  calculateAge,
  inchesToFeetAndInches,
  yearsDifferenceFromCurrent,
  getTournamentYear,
  getCurrentYear,
  getPreviousYear,
  determinedPts,
  formatDate,
} from '../../../utils/utils';
import { useMyLeague } from '../../../hooks/useMyLeague';

export const PlayerModal = ({
  imageUrl,
  player,
  onClose,
  team = undefined,
}) => {
  const { data: myLeagueData } = useMyLeague();
  const { data } = useTournamentsByPlayer(
    player.id,
    myLeagueData.isDrafted ? getCurrentYear() : getPreviousYear()
  );

  console.log(data);

  const sortedTournaments =
    data?.sort((a, b) => {
      // Convert date strings to Date objects for comparison
      const dateA: any = new Date(a.tournament_id.start_date);
      const dateB: any = new Date(b.tournament_id.start_date);

      // Compare the dates
      return dateB - dateA;
    }) ?? [];

  const mapTournamentData = (tournamentData) => {
    let top10positions = 0;
    let tournamentsPlayedIn = 0;
    let cutsMade = 0;
    let wins = 0;
    tournamentData.forEach((tournament) => {
      const { position } = tournament;
      tournamentsPlayedIn += 1;
      if (position <= 70) cutsMade += 1;
      if (position <= 10) top10positions += 1;
      if (position === 1) wins += 1;
    });
    return (
      <>
        <div className="mt-2 flex flex-col gap-4 rounded-lg bg-white p-5">
          <div className="flex items-center justify-between">
            <span className="text-xs">Events Played</span>
            <span className="ml-[2ch] h-px grow border"></span>
            <span className="ml-2 w-[3ch] text-right">
              {tournamentsPlayedIn}
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-xs">Cuts Made</span>
            <span className="ml-[2ch] h-px grow border"></span>
            <span className="ml-2 w-[3ch] text-right">{cutsMade}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-xs">Top 10</span>
            <span className="ml-[2ch] h-px grow border"></span>
            <span className="ml-2 w-[3ch] text-right">{top10positions}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-xs">Wins</span>
            <span className="ml-[2ch] h-px grow border"></span>
            <span className="ml-2 w-[3ch] text-right">{wins}</span>
          </div>
        </div>
      </>
    );
  };

  const mapTournamentDetails = (tournamentData) => {
    const results = tournamentData.map((data) => {
      const { name, start_date, id, end_date } = data.tournament_id;
      const { position } = data;
      return (
        <div
          className="mt-2 grid grid-cols-[10fr,1fr,1fr] items-center gap-2 rounded-lg border bg-white p-5"
          key={id}
        >
          <div className="flex flex-col justify-between gap-2">
            <div
              className={`${
                name.length > 20 ? 'text-xs' : 'text-sm'
              } leading-5`}
            >
              {name}
            </div>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-xs">POS</span>
            <span className="text-xs">{position}</span>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-xs">PTS</span>
            <span className="text-xs">{determinedPts(data)}</span>
          </div>
          <div className="col-span-3 flex w-full items-center text-xs">
            <CalendarIcon className="mr-2" width={20} height={20} />
            <div className="flex w-full flex-row items-center justify-between">
              <span>{formatDate(start_date)}</span>
              <span className="ml-[2ch] h-px grow border"></span>
              <span className="ml-[2ch]">{formatDate(end_date)}</span>
            </div>
          </div>
        </div>
      );
    });
    return results;
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex flex-col items-center justify-center">
                  <div className="absolute right-0 top-0 m-1 flex w-full justify-between">
                    <div></div>
                    {team && (
                      <div className="mr-10 flex flex-row items-center gap-1">
                        <ArrowRightIcon height={15} width={15} />
                        <span className="text-sm">{team.name}</span>
                      </div>
                    )}
                    <button onClick={onClose}>
                      <XCircleIcon height={30} width={30} />
                    </button>
                  </div>
                  <div className="flex flex-row items-center">
                    <img
                      className="h-20 w-20 rounded-full"
                      src={imageUrl ?? ''}
                      alt=""
                    />
                    <div className="ml-2 flex flex-col justify-center">
                      <span>
                        {player.first_name} {player.last_name}
                      </span>
                      <div className="mr-20 grid w-fit grid-cols-[1fr,1fr,1fr,.5fr,.5fr] gap-2">
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">AGE</span>
                          <span className="text-black">
                            {calculateAge(player.birthday)}
                          </span>
                        </div>
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">HEIGHT</span>
                          <span className="text-black">
                            {inchesToFeetAndInches(player.height)}
                          </span>
                        </div>
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">WEIGHT</span>
                          <span className="text-black">{player.weight}</span>
                        </div>
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">EXP</span>
                          <span className="text-black">
                            {yearsDifferenceFromCurrent(player.turned_pro)}
                          </span>
                        </div>
                        <div className="flex flex-col items-end">
                          <span className="text-xs text-gray-600">HAND</span>
                          <span className="text-black">
                            {player.handedness}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    {sortedTournaments.length > 0 && (
                      <span className="text-lg font-semibold">
                        {sortedTournaments[0].year ?? ''} Tournaments Summary
                      </span>
                    )}
                    {!sortedTournaments ||
                      (sortedTournaments.length === 0 && (
                        <span className="text-lg font-semibold">
                          {getTournamentYear()} Season Tournaments
                        </span>
                      ))}
                    <div>
                      {sortedTournaments && (
                        <>
                          <div>{mapTournamentData(sortedTournaments)}</div>
                          <div>
                            <span className="text-lg font-semibold ">
                              Tournament Results
                            </span>
                            <div className="my-2 flex flex-col gap-2 rounded-md bg-white p-5">
                              {mapTournamentDetails(sortedTournaments)}
                            </div>
                          </div>
                        </>
                      )}
                      {!sortedTournaments && (
                        <div className="w-full">
                          {/* Tournaments Title Placeholder */}
                          <div className="mb-2 h-4 w-40 animate-pulse bg-gray-300"></div>
                          {/* Tournaments List Placeholder */}
                          {[...Array(3)].map((_, index) => (
                            <div
                              className="mt-2 grid animate-pulse grid-cols-[3fr,1fr,1fr] items-center gap-4"
                              key={index}
                            >
                              {/* Tournament Name and Start Date Placeholders */}
                              <div className="flex flex-col">
                                <div className="mb-1 h-4 w-3/4 bg-gray-300"></div>
                                <div className="h-4 w-1/2 bg-gray-300"></div>
                              </div>
                              {/* Position Placeholder */}
                              <div className="flex flex-col">
                                <div className="mb-1 h-2 w-24 bg-gray-300"></div>
                                <div className="h-4 w-8 bg-gray-300"></div>
                              </div>
                              {/* PTS Placeholder */}
                              <div className="flex flex-col">
                                <div className="mb-1 h-2 w-12 bg-gray-300"></div>
                                <div className="h-4 w-8 bg-gray-300"></div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
