import React, { useEffect, useState } from 'react';
import { Team } from '../../../models/teams/api';
import { TrashIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { useSupabase } from '../../../hooksV2/useSupabase';
import { Modal } from '../../app/modal';
import { LeagueListItemModal } from '../../app/modals/leagueListItemModal';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../hooks/useUser';

type Props = {
  teams: Team[] | undefined | null;
};

export const TeamManagementSection = ({ teams }: Props) => {
  const { data } = useUser();
  const { supabase } = useSupabase();
  const [images, setImages] = useState<string[]>();
  const [teamImages, setTeamImages] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [selectedLeagueId, setSelectedLeagueId] = useState<string>();
  const navigate = useNavigate();

  const getLogos = async () => {
    const promises = [];
    const promises2 = [];
    for (const team of teams) {
      const result = supabase.storage
        .from('league-logos')
        .download(team.league_id.pic_url);
      promises.push(result);

      const result2 = supabase.storage
        .from('team-logos')
        .download(team.pic_url);
      promises2.push(result2);
    }
    const results = await Promise.all(promises);
    const teamImagesResults: any = await Promise.allSettled(promises2);

    const teamImages = [];
    teamImagesResults.forEach((image) => {
      if (image.value.data) {
        const url = URL.createObjectURL(image.value.data);
        teamImages.push(url);
      } else {
        teamImages.push('');
      }
    });
    setTeamImages(teamImages);
    const mapResults = results.map((result) =>
      URL.createObjectURL(result.data)
    );
    setImages(mapResults);
  };

  const onClickHandler = (e, state, leagueId) => {
    e.stopPropagation();
    setShowDeletePopup(state);
    setSelectedLeagueId(leagueId);
  };

  const onClickLeagueHandler = (path) => {
    navigate(path);
  };

  useEffect(() => {
    getLogos();
  }, []);

  return (
    <>
      <ul role="list" className="flex flex-col gap-1">
        {images &&
          teams?.map((team, idx) => (
            <button
              key={team.id}
              onClick={() =>
                onClickLeagueHandler(`/league/${team.league_id.id}`)
              }
              className="flex-1 items-center justify-center rounded-br-lg border border-transparent text-sm font-medium text-gray-700 duration-300 hover:text-gray-500"
            >
              <li className="border-1 col-span-1 ml-2 mr-2 flex flex-row divide-gray-200 rounded-lg border border-black bg-slate-300 py-2">
                <div className="flex w-[70%] flex-col">
                  <div className="flex items-center justify-start gap-2 px-2 py-1">
                    <img
                      className="aspect-square h-10 w-10 flex-shrink-0 rounded-full bg-gray-300 object-cover"
                      src={images[idx]}
                      alt=""
                    />
                    <span className="text-lg font-semibold">
                      {team.league_id.name}
                    </span>
                  </div>
                  <div className="flex w-80 items-center">
                    <div className="flex w-[75%] items-center justify-between">
                      <div className="ml-10 flex flex-row items-center gap-2">
                        {!teamImages[idx] && (
                          <UserCircleIcon
                            width={30}
                            height={30}
                            className="rounded-full bg-white"
                          />
                        )}
                        {teamImages[idx] && (
                          <img
                            src={teamImages[idx]}
                            width={30}
                            height={30}
                            className="aspect-square rounded-full bg-white object-cover"
                          />
                        )}
                        <span className="font-sm font-semibold">
                          {team.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex h-auto w-[30%] items-center justify-end p-2">
                  <div className="flex whitespace-nowrap rounded-md border border-black bg-white p-0.5 px-2">
                    {!team.league_id.isDrafted && (
                      <span className="text-xs">Pre-draft</span>
                    )}
                    {team.league_id.isDrafted && (
                      <span className="text-xs">Drafted</span>
                    )}
                  </div>
                  {data.id === team.league_id.commissioner_id ? (
                    <div
                      onClick={(e) =>
                        onClickHandler(e, true, team.league_id.id)
                      }
                    >
                      <TrashIcon
                        height={20}
                        width={20}
                        className="ml-2 text-black"
                      />
                    </div>
                  ) : (
                    <div className="mr-7" aria-hidden="true"></div>
                  )}
                </div>
              </li>
            </button>
          ))}
      </ul>
      {showDeletePopup && selectedLeagueId && (
        <Modal>
          <LeagueListItemModal
            onClose={() => setShowDeletePopup(false)}
            leagueId={selectedLeagueId}
          />
        </Modal>
      )}
    </>
  );
};
