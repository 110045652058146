export const CONSTANTS = {
  app: {
    redirectUrl: process.env.REACT_APP_REDIRECT_URL,
    basePath: process.env.REACT_APP_BASE_PATH,
  },
  supabase: {
    url: process.env.REACT_APP_SUPABASE_URL,
    anonKey: process.env.REACT_APP_SUPABASE_ANON_KEY,
  },
};

export const SCORING_SYSTEM = {
  1: 250.0,
  2: 190.0,
  3: 180.0,
  4: 170.0,
  5: 160.0,
  6: 150.0,
  7: 140.0,
  8: 130.0,
  9: 120.0,
  10: 110.0,
  11: 103.0,
  12: 97.0,
  13: 92.0,
  14: 88.0,
  15: 84.0,
  16: 81.0,
  17: 78.0,
  18: 75.0,
  19: 72.0,
  20: 70.0,
  21: 68.0,
  22: 66.0,
  23: 64.0,
  24: 62.0,
  25: 60.0,
  26: 58.0,
  27: 56.0,
  28: 54.0,
  29: 52.0,
  30: 50.0,
  31: 48.0,
  32: 46.0,
  33: 44.0,
  34: 42.0,
  35: 40.0,
  36: 38.0,
  37: 36.0,
  38: 34.0,
  39: 32.0,
  40: 31.0,
  41: 30.0,
  42: 29.0,
  43: 28.0,
  44: 27.0,
  45: 26.0,
  46: 25.0,
  47: 24.0,
  48: 23.0,
  49: 22.0,
  50: 21.0,
  51: 20.0,
  52: 19.0,
  53: 18.0,
  54: 17.0,
  55: 16.0,
  56: 15.0,
  57: 14.0,
  58: 13.0,
  59: 12.0,
  60: 11.0,
  61: 10.0,
  62: 9.0,
  63: 8.0,
  64: 7.0,
  65: 6.0,
  66: 5.0,
  67: 4.0,
  68: 3.0,
  69: 2.0,
  70: 1.0,
};

export const TOURNAMENT_TYPES = {
  SIGNATURE: 1.5,
  REGULAR: 1,
  MAJOR: 2,
  PLAYOFFS: 2,
};

const TOURNAMENT_TYPE_MAP = {
  'The Sentry': 'SIGNATURE',
  'Sony Open in Hawaii': 'REGULAR',
  'The American Express': 'REGULAR',
  'Farmers Insurance Open': 'REGULAR',
  'AT&T Pebble Beach Pro-Am': 'SIGNATURE',
  'WM Phoenix Open': 'REGULAR',
  'The Genesis Invitational': 'SIGNATURE',
  'Mexico Open at Vidanta': 'REGULAR',
  'The Classic in The Palm Beaches': 'REGULAR',
  'Arnold Palmer Invitational': 'SIGNATURE',
  'THE PLAYERS Championship': 'SIGNATURE',
  'Valspar Championship': 'REGULAR',
  'Texas Children’s Houston Open': 'REGULAR',
  'Valero Texas Open': 'REGULAR',
  'The Masters': 'MAJOR',
  'RBC Heritage': 'SIGNATURE',
  'The CJ Cup Byron Nelson': 'REGULAR',
  'Wells Fargo Championship': 'SIGNATURE',
  'PGA Championship': 'MAJOR',
  'Charles Schwab Challenge': 'REGULAR',
  'RBC Canadian Open': 'REGULAR',
  'The Memorial Tournament': 'SIGNATURE',
  'US Open Championship': 'MAJOR',
  'Travelers Championship': 'SIGNATURE',
  'Rocket Mortgage Classic': 'REGULAR',
  'John Deere Classic': 'REGULAR',
  'Genesis Scottish Open': 'REGULAR',
  'The Open Championship': 'MAJOR',
  '3M Open': 'REGULAR',
  'Wyndham Championship': 'REGULAR',
  'FedEx St. Jude Championship': 'PLAYOFFS',
  'BMW Championship': 'PLAYOFFS',
  'Tour Championship': 'PLAYOFFS',
};
