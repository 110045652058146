import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useLogout } from '../../../hooks/useLogout';
import { UserAccountSettings } from './userAccountSettings';

export const DashboardSettingsModal = ({ onClose }) => {
  const { logout } = useLogout();
  const [showUserSetting, setShowUserSetting] = useState<boolean>();

  const settingHandler = (state) => {
    setShowUserSetting(state);
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit w-full transform items-center overflow-hidden rounded-lg bg-white px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                {!showUserSetting && (
                  <div className="flex w-full flex-col gap-4 rounded-md bg-primary p-10">
                    <button
                      className="w-full rounded-lg border-2 bg-white px-4 py-2 text-slate-700 duration-300 hover:bg-accent hover:text-white"
                      onClick={() => settingHandler(true)}
                    >
                      User Account
                    </button>
                    <button
                      className="w-full rounded-lg border-2 bg-white px-4 py-2 text-slate-700 duration-300 hover:bg-accent hover:text-white"
                      onClick={logout}
                    >
                      Logout
                    </button>
                  </div>
                )}
                {showUserSetting && (
                  <UserAccountSettings
                    onBack={() => settingHandler(false)}
                    onClose={onClose}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
