import React from 'react';
import { useState, useEffect } from 'react';
import { useCreateLeague } from '../../../hooks/useCreateLeague';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { usePlayers } from '../../../hooks/usePlayers';
import GolfBall from '../../../assets/golfball.png';
import { useQueryClient } from 'react-query';
import { useUser } from '../../../hooks/useUser';

type form = {
  name: string;
  users: string[];
  numOfTeams: number;
  numOfPlayers: number;
};
export function CreateLeague({ onClose, onNext }) {
  const [form, setForm] = useState<form>({
    name: '',
    users: [],
    numOfTeams: 2,
    numOfPlayers: 7,
  });
  const [selectedNumOfTeams, setSelectedNumOfTeams] = useState<number>(2);
  const [isFormValid, setIsFormValid] = useState(false);
  const { createLeague } = useCreateLeague();
  const { data } = usePlayers();
  const queryClient = useQueryClient();
  const { data: userData } = useUser();

  useEffect(() => {
    const isValid =
      form.name.trim() !== '' && form.numOfPlayers > 0 && form.numOfTeams > 0;
    setIsFormValid(isValid);
  }, [form]);

  const onSubmitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!isFormValid) return;
    if (form.numOfTeams * form.numOfPlayers > data.length) return;
    try {
      const { leagueId } = await createLeague(form);
      setForm({
        name: '',
        users: [],
        numOfTeams: 2,
        numOfPlayers: 7,
      });
      queryClient.invalidateQueries(['useTeams', userData?.id]);
      onNext(leagueId);
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    if (e.target.name === 'players') {
      if (/^\d{1,2}$/.test(e.target.value) || e.target.value === '') {
        setForm({
          ...form,
          [e.currentTarget.name]: e.currentTarget.value,
        });
      }
    } else {
      setForm({
        ...form,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const onTeamAmountPress = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
    amount
  ) => {
    e.preventDefault();
    setForm({
      ...form,
      numOfTeams: amount,
    });
    setSelectedNumOfTeams(amount);
  };

  return (
    <div>
      <div className="mt-5 flex w-full justify-center">
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={onSubmitHandler}
        >
          <div className="flex justify-center gap-2">
            <button onClick={onClose}>
              <ArrowLeftIcon height={20} width={20} />
            </button>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Create League
            </h3>
          </div>
          <div className="space-y-8 divide-y divide-gray-200 rounded-md bg-primary p-5 sm:space-y-5 lg:px-5 lg:pb-8">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5 lg:flex lg:w-full lg:flex-col">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-white"
                >
                  League Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="mt-1 w-full rounded-md border-gray-300 p-2 shadow-sm sm:text-sm"
                  placeholder="The best league ever"
                  value={form.name}
                  onChange={onChangeHandler}
                />
              </div>
              <div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 lg:flex lg:flex-col">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    How many teams?
                  </label>
                  <div>
                    <div className="mt-1 flex flex-row justify-center">
                      <div className="grid grid-cols-6 gap-1">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((number) =>
                          number === 1 ? (
                            <div key={number} className="w-10">
                              <img
                                src={GolfBall}
                                alt="Golf ball"
                                className="h-10 w-10"
                              />
                            </div>
                          ) : (
                            <input
                              key={number}
                              type="button"
                              name="numOfTeams"
                              id={`numOfTeams${number}`}
                              className={`w-10 rounded-full border-gray-300 bg-[#FFFFFF] p-2 shadow-sm sm:text-sm ${
                                selectedNumOfTeams === number
                                  ? 'bg-indigo-500 text-white'
                                  : ''
                              }`}
                              value={number}
                              onClick={(e) => onTeamAmountPress(e, number)}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 lg:flex lg:flex-col">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    Players Per Team
                  </label>
                  <input
                    type="text"
                    name="numOfPlayers"
                    id="numOfPlayers"
                    className="mt-1 w-full rounded-md border-gray-300 p-2 shadow-sm sm:text-sm"
                    placeholder="7"
                    value={form.numOfPlayers}
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex flex-col items-center justify-center gap-2">
              <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:border-gray-400 disabled:text-gray-400"
                disabled={!isFormValid}
              >
                Create a League
              </button>
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
