import { useEffect, useState } from 'react';
import { useUser } from '../../../hooks/useUser';
import { useUpdateUser } from '../../../hooks/useUpdateUser';
import { useQueryClient } from 'react-query';

export const UserAccountSettings = ({ onBack, onClose }) => {
  const { data } = useUser();
  const { updateUser } = useUpdateUser();
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    username: '',
  });
  const queryClient = useQueryClient();

  const onChangeHandler = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    await updateUser({
      firstName: form.firstName,
      lastName: form.lastName,
      username: form.username,
    });
    setForm({
      ...form,
      firstName: '',
      lastName: '',
    });
    queryClient.invalidateQueries(['useUser']);
    onClose();
  };

  useEffect(() => {
    setForm({
      firstName: data.first_name,
      lastName: data.last_name,
      username: data.username,
    });
  }, [data]);

  return (
    <div className="flex w-full flex-col gap-4 rounded-md bg-primary p-4">
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
        >
          First Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="firstName"
            id="firstName"
            className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={form.firstName}
            onChange={onChangeHandler}
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
        >
          Last Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="lastName"
            id="lastName"
            className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={form.lastName}
            onChange={onChangeHandler}
          />
        </div>
      </div>
      <div className="mt-2 flex flex-col gap-3 border-t-2 p-2">
        <button
          className="w-full rounded-lg border-2 bg-white px-4 py-2 text-slate-700 duration-300 hover:bg-accent hover:text-white"
          onClick={() => onSubmit()}
        >
          Save
        </button>
        <button
          className="w-full rounded-lg border-2 bg-white px-4 py-2 text-slate-700 duration-300 hover:bg-accent hover:text-white"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
