import { useQuery } from 'react-query';
import { getDataByTeamIdWithUser } from '../models/teams/api';

export const useTeam = (args) => {
  return useQuery(
    ['useTeam', args],
    async () =>
      getDataByTeamIdWithUser({ id: args }).then((result) => result.data![0]),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
    }
  );
};
