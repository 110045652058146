import { PostgrestFilterBuilder } from '@supabase/postgrest-js';
import { PostgrestError } from '@supabase/supabase-js';
import { Tournament } from '../tournaments/api';
import { supabase } from '../utils';
import { base64Encode, getPreviousYear } from '../../utils/utils';

export type Results = {
  id: string;
  created_at: string;
  player_id: string;
  tournament_id: Tournament;
  position: number;
  soure: number;
  money: number;
  num_tied: number;
  tied: boolean;
  strokes: number;
  status: null | string;
};

export interface ApiResponse {
  data: Results[];
  error: PostgrestError;
}

export interface ApiArgs {}

export const getResults = ({
  playerIds,
  year,
}: {
  playerIds: string[];
  year: number;
}): PostgrestFilterBuilder<any, any, Results[]> => {
  return supabase
    .from('results')
    .select('*, tournament_id (*)')
    .in('player_id', playerIds)
    .eq('year', year);
};

export const getDataByPlayerId = (playerId: string, year: number) => {
  return supabase
    .from('results')
    .select('*, tournament_id (*)')
    .eq('player_id', playerId)
    .eq('year', year);
};

export const getDataByTournamentName = (name, year) => {
  return supabase.from('results').select('*').eq('name', name).eq('year', year);
};

export const getDataFromView = (name, year) => {
  return supabase
    .from('tournament_results_view')
    .select('*')
    .eq('tournament_name', name)
    .eq('year', year)
    .limit(30);
};
