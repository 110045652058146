import React, { useState, useEffect } from 'react';
import { AiFillGoogleCircle } from 'react-icons/ai';
import { useSignInUser } from '../../hooks/useSignInUser';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { ProviderTypes } from '../../hooks/useSignInUser';
import { useSupabase } from '../../hooksV2/useSupabase';
import { LoadingSkeleton } from '../organisms/loadingSkeleton';

type SignInFormValues = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const { user, isLoading } = useSupabase();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form, setForm] = useState<SignInFormValues>({
    email: '',
    password: '',
  });
  const { signInWithPassword, signInWithOAuth } = useSignInUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.id) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const onSubmitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { data, error } = await signInWithPassword({
      email: form?.email,
      password: form?.password,
    });
    if (error) {
      console.log(error);
    }
    if (data?.user?.aud === 'authenticated') {
      navigate('/dashboard');
    }
  };

  const onClickSignInWithOAuth = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    provider: ProviderTypes
  ) => {
    e.preventDefault();
    const { error } = await signInWithOAuth({
      provider,
      code: searchParams.get('code'),
    });
    if (error) {
      console.log(error);
    }
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  if (user?.aud) {
    navigate('/dashboard');
  }

  if (isLoading) {
    return (
      <div className="my-36 flex w-full justify-center">
        <LoadingSkeleton />
      </div>
    );
  }
  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={onSubmitHandler}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={form.email}
                    onChange={onChangeHandler}
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={form.password}
                    onChange={onChangeHandler}
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link
                    to="/passwordReset"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign in
                </button>
              </div>
            </form>
            <div className="mt-2">
              <span className="text-sm ">
                Don&apos;t have an account yet? Register{' '}
                <Link to="/register" className="font-semibold text-indigo-700">
                  Here
                </Link>
              </span>
            </div>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-3">
                <div>
                  <button
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                    onClick={(e) => onClickSignInWithOAuth(e, 'google')}
                  >
                    <span className="sr-only">Sign in with Google</span>
                    <AiFillGoogleCircle className="text-xl" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
