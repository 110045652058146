import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react';
import {
  UsersIcon,
  BuildingLibraryIcon,
  PencilSquareIcon,
  PresentationChartBarIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { LoadingSkeleton } from '../../organisms/loadingSkeleton';
import { ScoringSettings } from './scoringSettings';
import { GeneralSettings } from './generalSettings';
import { Introduction } from './introduction';
import { MyTeam } from './myTeam';
import { DraftResults } from './draftResults';
import { formatReadableDate } from '../../../utils/utils';

const settingsList = [
  {
    id: 1,
    logo: UsersIcon,
    title: 'My Team',
    description: "Update team's avatar/name",
    Component: MyTeam,
  },
  {
    id: 2,
    logo: BuildingLibraryIcon,
    title: 'General',
    description: 'View league general settings',
    Component: GeneralSettings,
  },
  {
    id: 3,
    logo: PencilSquareIcon,
    title: 'Draft Result',
    description: 'View draft result for this league',
    Component: DraftResults,
  },
  {
    id: 4,
    logo: PresentationChartBarIcon,
    title: 'Scoring',
    description: 'View scoring settings',
    Component: ScoringSettings,
  },
];

export const LeagueSettingsModal = ({ league, onClose }) => {
  const [selectedSetting, setSelectedSetting] = useState<any>();

  const onClickSelectedSettings = (e, selected) => {
    e.preventDefault();
    setSelectedSetting(selected);
  };

  const onCloseSelectedSettings = () => {
    setSelectedSetting(undefined);
  };

  const generateMapSettings = () => {
    return settingsList.map((setting) => {
      return (
        <button
          onClick={(e) => onClickSelectedSettings(e, setting)}
          key={setting.id}
          className={`flex flex-col rounded-md border bg-white p-2 ${
            setting.title === 'How To Play / Rules' ? 'col-span-2' : ''
          }`}
        >
          <div className="flex items-center text-lg font-semibold">
            <setting.logo height={20} width={20} />
            {setting.title === 'How To Play / Rules' ? (
              <>
                <span className="ml-2 whitespace-nowrap text-base">
                  {setting.title}
                </span>
              </>
            ) : (
              <span className="ml-2 whitespace-nowrap text-base">
                {setting.title}
              </span>
            )}
          </div>
          {setting.title !== 'How To Play / Rules' && (
            <span className="p-2 text-left text-sm">{setting.description}</span>
          )}
        </button>
      );
    });
  };

  if (!league) {
    <div className="my-36 flex w-full justify-center">
      <LoadingSkeleton />
    </div>;
  }

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex flex-col gap-4">
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                      <span className="text-2xl font-semibold">
                        {!selectedSetting ? league.name : selectedSetting.title}
                      </span>
                      <span className="text-sm">League Settings</span>
                      <span className="text-sm">{`Created: ${formatReadableDate(
                        league.created_at
                      )}`}</span>
                      {league?.drafted_date && (
                        <span className="text-sm">{`Drafted: ${formatReadableDate(
                          league.drafted_date
                        )}`}</span>
                      )}
                    </div>
                    <div>
                      <button onClick={() => onClose()}>
                        <XCircleIcon width={30} height={30} />
                      </button>
                    </div>
                  </div>
                  {selectedSetting && (
                    <selectedSetting.Component back={onCloseSelectedSettings} />
                  )}
                  {!selectedSetting && (
                    <div className="grid grid-cols-2 gap-4 md:grid-cols-2">
                      {generateMapSettings()}
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
