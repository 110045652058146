import { useQuery } from 'react-query';
import { getData } from '../models/players/api';

export const usePlayers = () => {
  return useQuery(
    ['usePlayers'],
    async () => getData().then((result) => result.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
    }
  );
};
