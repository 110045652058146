import { supabase } from '../utils';
import { PostgrestFilterBuilder, PostgrestError } from '@supabase/postgrest-js';
import { getTournamentYear } from '../../utils/utils';

export type Tournament = {
  id: string;
  created_at: string;
  name: string;
  status: string;
  purse: number;
  event_type: string;
  winning_share: number;
  start_date: string;
  end_date: string;
  type: string;
  year: number;
};

export interface ApiResponse {
  data: Tournament;
  error: PostgrestError;
}

export interface GetApiArgs {}

export const getData = (): PostgrestFilterBuilder<any, any, Tournament[]> => {
  const year = getTournamentYear();
  return supabase
    .from('tournaments')
    .select('*')
    .eq('year', year)
    .not('type', 'is', null)
    .order('start_date', { ascending: true });
};
