import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { getDataByLeagueCode } from '../models/leagues/api';
import { getDataByLeagueId, patchData } from '../models/teams/api';
import { useUser } from './useUser';

export const useJoinLeague = () => {
  const { data: userData } = useUser();
  const queryClient = useQueryClient();

  const getNextAvailableTeam = (teams) => {
    return teams.find((team) => !team.user_id);
  };

  const checkIfUserHasTeam = (teams) => {
    return !!teams.find((team) => team.user_id === userData.id);
  };
  const joinLeague = useCallback(
    async ({ code }) => {
      if (!userData) return;
      const leagueCreationResponse = await getDataByLeagueCode({ code });
      const { id } = leagueCreationResponse.data![0];
      const { data: teams, error } = await getDataByLeagueId(id);
      if (!error && teams) {
        if (checkIfUserHasTeam(teams)) {
          return { data: null, error: 'User has team already' };
        }
        const nextAvailableTeam = getNextAvailableTeam(teams);
        if (!nextAvailableTeam) {
          return { data: null, error: 'No available teams left' };
        }
        const { data: teamData, error: teamAddError } = await patchData({
          userId: userData.id,
          teamId: nextAvailableTeam.id,
        });
        queryClient.invalidateQueries(['useTeams', userData?.id]);
        if (teamData) {
          return { data: id, error: null };
        }
        if (teamAddError) {
          return { data: null, error: teamAddError };
        }
      }
      if (error) {
        return { data: null, error };
      }
      return { data: null, error: null };
    },
    [userData]
  );
  return {
    joinLeague,
  };
};
