import React from 'react';

export function Logomark(props) {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.2939 0.020447C28.8142 -0.0852921 28.3427 0.229488 28.2401 0.724153L24.1558 20.402L24.0804 20.7757C23.7869 22.2445 23.606 23.1287 22.2475 23.4289C22.3265 23.043 22.3695 22.6444 22.3695 22.2348C22.3695 19.0565 19.8612 16.4696 16.7776 16.4696C13.6941 16.4696 11.1858 19.0565 11.1858 22.2348C11.1858 22.7027 11.2465 23.1536 11.3479 23.5893H9.28157C7.28304 23.5893 5.87624 22.8717 4.80006 21.6301H9.08235C9.5727 21.6301 9.96994 21.2199 9.96994 20.7149C9.96994 20.2093 9.5727 19.7991 9.08235 19.7991H3.60188C3.39325 19.3877 3.197 18.9484 3.01194 18.4829H8.81125C9.3016 18.4829 9.69941 18.0733 9.69941 17.5677C9.69941 17.0621 9.3016 16.6525 8.81125 16.6525H2.37071C2.25696 16.2301 2.19391 15.7434 2.39253 15.4584C2.54989 15.2353 3.0644 14.8592 4.75233 14.8592H20.955C21.4454 14.8592 21.8426 14.4496 21.8426 13.944C21.8426 13.4384 21.4454 13.0288 20.955 13.0288H4.75233C3.52881 13.0288 1.80433 13.1692 0.952704 14.3882C0.41167 15.163 0.344481 16.1949 0.75173 17.454C1.58921 20.0392 3.33018 25.4185 9.28214 25.4185H12.1199C13.123 26.973 14.8357 28 16.7776 28C18.7432 28 20.4718 26.9463 21.4695 25.3608C25.014 25.1663 25.4601 22.9452 25.8196 21.1446L29.9758 1.10578C30.0789 0.612939 29.7725 0.126186 29.2939 0.020447ZM16.7771 26.1714C14.6724 26.1714 12.9604 24.4061 12.9604 22.2354C12.9604 20.0659 14.6719 18.3006 16.7771 18.3006C18.8823 18.3006 20.5932 20.0653 20.5932 22.2354C20.5932 24.4055 18.8823 26.1714 16.7771 26.1714Z"
        fill="#298E45"
      />
    </svg>
  );
}

export function Logo() {
  return (
    <svg
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2939 0.020447C28.8142 -0.0852921 28.3427 0.229488 28.2401 0.724153L24.1558 20.402L24.0804 20.7757C23.7869 22.2445 23.606 23.1287 22.2475 23.4289C22.3265 23.043 22.3695 22.6444 22.3695 22.2348C22.3695 19.0565 19.8612 16.4696 16.7776 16.4696C13.6941 16.4696 11.1858 19.0565 11.1858 22.2348C11.1858 22.7027 11.2465 23.1536 11.3479 23.5893H9.28157C7.28304 23.5893 5.87624 22.8717 4.80006 21.6301H9.08235C9.5727 21.6301 9.96994 21.2199 9.96994 20.7149C9.96994 20.2093 9.5727 19.7991 9.08235 19.7991H3.60188C3.39325 19.3877 3.197 18.9484 3.01194 18.4829H8.81125C9.3016 18.4829 9.69941 18.0733 9.69941 17.5677C9.69941 17.0621 9.3016 16.6525 8.81125 16.6525H2.37071C2.25696 16.2301 2.19391 15.7434 2.39253 15.4584C2.54989 15.2353 3.0644 14.8592 4.75233 14.8592H20.955C21.4454 14.8592 21.8426 14.4496 21.8426 13.944C21.8426 13.4384 21.4454 13.0288 20.955 13.0288H4.75233C3.52881 13.0288 1.80433 13.1692 0.952704 14.3882C0.41167 15.163 0.344481 16.1949 0.75173 17.454C1.58921 20.0392 3.33018 25.4185 9.28214 25.4185H12.1199C13.123 26.973 14.8357 28 16.7776 28C18.7432 28 20.4718 26.9463 21.4695 25.3608C25.014 25.1663 25.4601 22.9452 25.8196 21.1446L29.9758 1.10578C30.0789 0.612939 29.7725 0.126186 29.2939 0.020447ZM16.7771 26.1714C14.6724 26.1714 12.9604 24.4061 12.9604 22.2354C12.9604 20.0659 14.6719 18.3006 16.7771 18.3006C18.8823 18.3006 20.5932 20.0653 20.5932 22.2354C20.5932 24.4055 18.8823 26.1714 16.7771 26.1714Z"
        fill="#298E45"
      />
    </svg>
  );
}
