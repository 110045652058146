import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSupabase } from '../../hooksV2/useSupabase';
import { CONSTANTS } from '../../utils/constants';

export interface WithPageAuthRequiredProps {
  [key: string]: any;
}

export interface UserProps {
  user: any;
}

/**
 * ```js
 * const MyProtectedPage = withPageAuthRequired(MyPage);
 * ```
 *
 * When you wrap your pages in this higher order component and an anonymous user visits your page,
 * they will be redirected to the login page and then returned to the page they were redirected from (after login).
 *
 * @category Client
 */

export const withPageAuthRequired = (Component) => {
  return function WithPageAuthRequired(props) {
    const { user, isLoading } = useSupabase();
    const [searchParams] = useSearchParams();
    useEffect(() => {
      if (!user || Object.keys(user).length > 0 || isLoading) return;
      if (Object.keys(user).length === 0 && !isLoading) {
        const code = searchParams.get('code');
        window.location.href = `${CONSTANTS.app.basePath}/login?code=${code}`;
      }
    }, [isLoading, user, searchParams]);

    if (user) {
      return <Component {...props} />;
    }
    return <>loading</>;
  };
};
