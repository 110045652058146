import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../../hooks/useUser';
import { useParams } from 'react-router-dom';
import { useTeamsByLeague } from '../../../hooks/useTeamsByLeague';

type Props = {
  leagueId: string;
};

export const LeagueNavigation = (props: Props) => {
  const { data: userData } = useUser();
  const { leagueId } = useParams();
  const { data: teamsData, isLoading: teamsLoading } =
    useTeamsByLeague(leagueId);

  const myTeam =
    teamsData && userData
      ? teamsData.find((team) => team.user_id === userData.id)?.id
      : '';

  const generateLinks = (id) => {
    return [
      {
        name: 'League Standings',
        url: `/league/${id}`,
      },
      {
        name: 'My Team',
        url: `/league/${id}/team/${myTeam}`,
      },
      {
        name: 'Schedule',
        url: `/league/${id}/schedule`,
      },
      {
        name: 'Golfers',
        url: `/league/${id}/players`,
      },
    ];
  };

  return (
    <div className="flex w-full flex-row justify-around bg-gray-600 py-2">
      {props.leagueId &&
        generateLinks(props.leagueId).map((link) => (
          <Link to={link.url} key={link.name} className="text-sm text-white">
            <span>{link.name}</span>
          </Link>
        ))}
    </div>
  );
};
