import { ArrowLeftIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Transition, Dialog } from '@headlessui/react';
import React, { useEffect, useState, Fragment } from 'react';
import { useSupabase } from '../../../hooksV2/useSupabase';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export const DraftHistoryModal = ({ onClose, draftingOrder }) => {
  const [images, setImages] = useState<string[]>([]);
  const [displayPlayers, setDisplayPlayers] = useState([]);
  const [selectedOption, setSelectedOption] = useState<any>({
    value: '',
    label: 'All',
  });
  const { supabase } = useSupabase();

  const fetchImage = async (player) => {
    let result = await supabase.storage
      .from('golfers')
      .getPublicUrl(player.profile_url);
    if (!result.data.publicUrl) {
      result = supabase.storage.from('golfers').getPublicUrl('unknown');
    }
    setImages([...images, result.data.publicUrl]);
  };

  const fetchImages = async (players) => {
    if (images.length === 0) {
      const promises = [];
      const filteredPlayers = players.filter(
        (player) => !!player?.player_id?.profile_url
      );
      for (const player of filteredPlayers) {
        const result = supabase.storage
          .from('golfers')
          .getPublicUrl(player.player_id.profile_url);
        promises.push(result);
      }
      const result = supabase.storage.from('golfers').getPublicUrl('unknown');
      promises.push(result);
      const results = await Promise.all(promises);
      const mapResults = results.map((result) => result.data.publicUrl);
      setImages(mapResults);
    } else {
      const player = players[players.length - 1];
      fetchImage(player);
    }
  };

  useEffect(() => {
    if (draftingOrder) {
      fetchImages(draftingOrder);
    }
  }, [draftingOrder]);

  useEffect(() => {
    if (selectedOption.value && draftingOrder) {
      const filteredPlayers = draftingOrder.filter(
        (order) => order.team_id.id === selectedOption.value
      );
      setDisplayPlayers(filteredPlayers);
    } else {
      setDisplayPlayers(draftingOrder);
    }
  }, [selectedOption, draftingOrder]);

  const onOptionSelectHandler = (value) => {
    setSelectedOption(value);
  };

  const mapDraftResults = (players) => {
    let previousRound = 0;
    const result = players.map((result, idx) => {
      const isNewRound = result.round !== previousRound;
      previousRound = result.round;

      return (
        <React.Fragment key={result.id}>
          {isNewRound && (
            <div className="flex flex-col whitespace-nowrap py-2 text-xs">
              Round {result.round}
            </div>
          )}
          <div className="relative grid h-[60px] grid-cols-[1fr,9fr,3fr] items-center gap-2 p-2">
            {result && <div className="text-sm">{result.draft_number + 1}</div>}
            <div className="flex flex-row items-center gap-2 text-left text-sm">
              {!result?.player_id && (
                <>
                  <img
                    src={images.find((image) => image.includes('unknown'))}
                    height={30}
                    width={30}
                    className="rounded-full"
                  />
                  <span className="mx-2">To be picked</span>
                </>
              )}
              {!!result?.player_id && (
                <>
                  <img
                    src={
                      images.find((image) =>
                        image.includes(result?.player_id?.profile_url)
                      ) ?? images.find((image) => image.includes('unknown'))
                    }
                    height={30}
                    width={30}
                    className="rounded-full"
                  />
                  <span>
                    {result?.player_id?.first_name}{' '}
                    {result?.player_id?.last_name}
                  </span>
                </>
              )}
            </div>
            <div className="text-sm">{result?.team_id?.name}</div>
          </div>
        </React.Fragment>
      );
    });
    return result;
  };

  const generateOptions = () => {
    const result = [{ value: '', label: 'All' }];
    const options = draftingOrder.reduce((acc, curr) => {
      const team = acc.find((option) => option.value === curr.team_id.id);
      if (!team)
        acc.push({
          value: curr.team_id.id,
          label: curr.team_id.name,
        });
      return acc;
    }, result);
    return options;
  };

  const dropdownOptions = generateOptions();

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center rounded-lg bg-slate-200 px-4 py-8 text-left shadow-xl transition-all sm:max-w-lg">
                <div className="flex w-full flex-col">
                  <button
                    className="absolute right-2 top-2 m-1 flex w-full justify-end"
                    onClick={onClose}
                  >
                    <XCircleIcon height={30} width={30} />
                  </button>
                  <div className="mt-4 flex flex-row items-center justify-between gap-4 bg-slate-200">
                    <div className="flex items-center gap-4">
                      <button onClick={onClose}>
                        <ArrowLeftIcon width={20} height={20} />
                      </button>
                      <span className="text-lg font-semibold">
                        Draft History
                      </span>
                    </div>
                    <div className="text-xs">
                      <Dropdown
                        className="z-10 flex items-center"
                        options={dropdownOptions}
                        onChange={onOptionSelectHandler}
                        value={selectedOption}
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex flex-col justify-center gap-1 divide-y rounded-md bg-white px-2">
                    {displayPlayers.length > 0 &&
                      images.length > 0 &&
                      mapDraftResults(displayPlayers)}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
