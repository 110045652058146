import React, { useEffect, useState } from 'react';
import { MagnifyingGlassIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import { useTeamsByLeague } from '../../../../hooks/useTeamsByLeague';
import { useParams } from 'react-router';
import { useDraftMachine } from '../../../../hooks/useDraftMachine';
import { useUser } from '../../../../hooks/useUser';
import { DraftPlayerList } from '../../../organisms/draftPlayerList';
import { useNavigate } from 'react-router-dom';
import { useSupabase } from '../../../../hooksV2/useSupabase';
import { usePlayersScoring } from '../../../../hooks/usePlayersScoring';
import { useLeague } from '../../../../hooks/useLeague';
import { Modal } from '../../../app/modal';
import { DraftHistoryModal } from '../../../app/modals/draftHistoryModal';
import { useLocation } from 'react-router-dom';
import { LoadingSkeleton } from '../../../organisms/loadingSkeleton';
import { TicketIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

export function DraftPage() {
  const { supabase } = useSupabase();
  const { data: userData } = useUser();
  const [search, setSearch] = useState<string>('');
  const { leagueId } = useParams();
  const [currentDrafterPic, setCurrentDrafterPic] = useState<string>();
  const { getPlayersResults } = usePlayersScoring();
  const [showDraftHistoryPopup, setShowDraftHistoryPopup] = useState();
  const [players, setPlayers] = useState([]);
  const location = useLocation();
  const { data: leagueData } = useLeague(leagueId);
  const { data: teamsData, isLoading: teamsLoading } =
    useTeamsByLeague(leagueId);
  const navigate = useNavigate();
  const myTeam = teamsData?.filter((data) => data?.user_id === userData?.id);
  leagueId;
  const {
    availablePlayers,
    draftPlayer,
    currentDraftNumber,
    draftedPlayers,
    draftingOrder,
  } = useDraftMachine({ leagueId, myTeamId: myTeam ? myTeam[0]?.id : '' });

  useEffect(() => {
    const fetchPlayers = async () => {
      const { playersWithScores } = await getPlayersResults(
        availablePlayers,
        leagueData.isDrafted
      );
      setPlayers(playersWithScores);
    };
    if (leagueData) {
      fetchPlayers();
    }
  }, [availablePlayers, leagueData]);

  const getPic = async () => {
    const picUrl = draftingOrder[currentDraftNumber]?.team_id?.pic_url;
    if (picUrl) {
      try {
        const { data, error } = await supabase.storage
          .from('team-logos')
          .download(picUrl);

        if (data) {
          const url = URL.createObjectURL(data);
          setCurrentDrafterPic(url);
        }
      } catch (error) {
        console.error(error);
        setCurrentDrafterPic(null);
      }
    } else {
      setCurrentDrafterPic(null);
    }
  };

  useEffect(() => {
    if (draftingOrder) {
      getPic();
    }
  }, [currentDraftNumber, draftingOrder]);

  if (teamsLoading || !draftingOrder)
    return (
      <div className="my-36 flex w-full justify-center">
        <LoadingSkeleton />
      </div>
    );

  const onChangeSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value.toLowerCase());
  };

  const onClickDraftHistoryPopupHandler = (state) => {
    setShowDraftHistoryPopup(state);
  };

  const handleBackClick = () => {
    if (location.pathname === `/league/${leagueId}`) {
      navigate('/dashboard');
    } else if (!leagueData?.isDrafted) {
      navigate(`/league/${leagueId}`);
    } else {
      navigate(-1);
    }
  };

  const filteredPlayers =
    players?.filter((p) => p.name.toLowerCase().includes(search)) ?? [];

  const isDraftFinished = draftingOrder.length === draftedPlayers.length;
  const isMyPick =
    myTeam[0]?.id === draftingOrder[currentDraftNumber]?.team_id?.id;
  console.log(draftingOrder[currentDraftNumber]?.team_id?.id);
  console.log(isMyPick);

  const calculateRoundNumber = (draftNumber) => {
    return Math.ceil((draftNumber + 1) / teamsData.length);
  };

  const draftingOrderWithRounds = draftingOrder.map((item, index) => ({
    ...item,
    round: calculateRoundNumber(index),
  }));

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex flex-row items-center justify-evenly gap-4 bg-gray-800 p-4 text-white lg:justify-between">
          <button onClick={handleBackClick}>
            <ArrowLeftIcon height={20} width={20} />
          </button>
          <div className="border-1 flex w-full flex-row items-center gap-3 rounded-md border p-2">
            <MagnifyingGlassIcon height={20} width={20} />
            <input
              type="text"
              value={search}
              onChange={onChangeSearchHandler}
              placeholder="Search by player name"
              className="w-full bg-gray-800 text-white outline-none"
            />
          </div>
        </div>
        <div className="flex flex-row items-center justify-between px-2 py-1">
          <div className="flex flex-row items-center justify-start gap-2 text-lg">
            <span className="font-semibold text-red-600">Now Drafting:</span>
            <img
              src={currentDrafterPic}
              height={20}
              width={20}
              className="aspect-square rounded-full object-cover"
            />
            <span>{draftingOrder[currentDraftNumber]?.team_id?.name}</span>
          </div>
          <div>
            <button
              className="items-center justify-center rounded-md border border-transparent bg-primary px-2 py-1 text-sm font-medium text-white shadow-sm"
              onClick={() => onClickDraftHistoryPopupHandler(true)}
            >
              Draft History
            </button>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between px-2">
          <div className="flex flex-row items-center justify-start gap-1 text-xs">
            <div className="flex flex-row items-center gap-2 text-lg">
              {isDraftFinished ? (
                <span className="text-gray-400">-</span>
              ) : (
                <>
                  <TicketIcon height={20} width={20} />
                  <span className="text-lg">
                    Pick{' '}
                    {isDraftFinished ? (
                      <span className="text-gray-400">-</span>
                    ) : (
                      currentDraftNumber + 1
                    )}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between px-2">
          <div className="my-2 flex flex-row items-center justify-start gap-1 text-lg">
            <span className="font-semibold text-black">On Deck:</span>
            {draftingOrder[currentDraftNumber + 1] && (
              <span>
                {draftingOrder[currentDraftNumber + 1]?.team_id?.name}
              </span>
            )}
            {!draftingOrder[currentDraftNumber + 1] && !isDraftFinished && (
              <span>Last Pick!</span>
            )}
            {isDraftFinished && <span className="text-gray-400">-</span>}
          </div>
        </div>
        <div className="flex flex-row items-center gap-2 px-2">
          <span className="text-lg font-semibold text-red-600">Round:</span>
          <span className="text-lg">
            {isDraftFinished ? (
              <span className="text-gray-400">-</span>
            ) : (
              Math.ceil((currentDraftNumber + 1) / teamsData.length)
            )}{' '}
            of{' '}
            {isDraftFinished ? (
              <span className="text-gray-400">-</span>
            ) : (
              draftingOrder.length / teamsData.length
            )}
          </span>
        </div>
        <div className="mb-20 mt-2">
          {filteredPlayers && !isDraftFinished && (
            <DraftPlayerList
              onSubmit={(playerId) =>
                draftPlayer({
                  playerId,
                  teamId: myTeam[0]?.id,
                  draftNumber: currentDraftNumber,
                })
              }
              isMyPick={isMyPick}
              players={filteredPlayers}
            />
          )}
          {isDraftFinished && (
            <div className="fixed flex h-screen w-full flex-col items-center bg-primary">
              <div className="flex h-[70%] items-center">
                <div className="flex flex-col items-center justify-center gap-4 rounded-xl border-4 border-accent bg-white px-10 py-12">
                  <span className="text-lg font-semibold">
                    The Draft is over!
                  </span>
                  <Link
                    to={`/league/${leagueId}`}
                    className="duration rounded-md border border-primary bg-white p-2 duration-200 hover:bg-primary hover:text-white"
                  >
                    Back to the league page
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showDraftHistoryPopup && draftedPlayers && (
        <Modal>
          <DraftHistoryModal
            onClose={() => onClickDraftHistoryPopupHandler(false)}
            draftingOrder={draftingOrderWithRounds}
          />
        </Modal>
      )}
    </>
  );
}
