import { supabase } from '../utils';
import {
  PostgrestFilterBuilder,
  PostgrestTransformBuilder,
  PostgrestError,
} from '@supabase/postgrest-js';
import { Team } from '../teams/api';

interface TeamScore {
  id: string;
  created_at: Date;
  score: number;
  team_id: Team;
}

export interface ApiResponse {
  data: TeamScore[];
  error: PostgrestError;
}

export interface ApiArgs {
  teamData: Team[];
}

export interface PostApiArgs {
  teamScores: {
    teamId: string;
  }[];
}

export const getData = ({
  teamData,
}: ApiArgs): PostgrestFilterBuilder<any, any, TeamScore[]> => {
  const payload = teamData?.map((team) => team?.id);
  return supabase
    .from('team_scores')
    .select('*, team_id (*)')
    .in('team_id', payload);
};

export const postData = ({
  teamScores,
}: PostApiArgs): PostgrestTransformBuilder<any, any, TeamScore[]> => {
  const payload = Object.values(teamScores).map((team) => ({
    team_id: team.teamId,
  }));
  return supabase.from('team_scores').insert(payload).select();
};
