import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { Button } from '../../landing/Button';
import { InviteModal } from './inviteModal';
import { CreateLeague } from './createLeague';
import { InviteTeamsModal } from './inviteTeamsModal';
import { MyTeamLeagueCreation } from './myTeamLeagueCreation';
import { useNavigate } from 'react-router-dom';

export const LeaguesModal = ({ onClose }) => {
  const [selectedModal, setSelectedModal] = useState('');
  const [createdLeagueId, setCreatedLeagueId] = useState();
  const navigate = useNavigate();

  const onClickHandler = (e, type) => {
    e.preventDefault();
    setSelectedModal(type);
  };

  const onBack = () => {
    setSelectedModal(undefined);
  };

  const onNextHandler = (leagueId) => {
    setSelectedModal('join');
    setCreatedLeagueId(leagueId);
  };
  const onNextNextHandler = (data = undefined) => {
    setSelectedModal('settings');
    if (data) setCreatedLeagueId(data);
  };

  const onSuccess = (path) => {
    navigate(path);
  };

  return (
    <Transition.Root as={Fragment} show={true}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex w-fit transform items-center overflow-hidden rounded-lg bg-white px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                {!selectedModal && (
                  <div className="flex flex-col gap-5">
                    <span className="text-md font-semibold">
                      League Options
                    </span>
                    <div className="flex flex-col items-start justify-center gap-4">
                      <button
                        onClick={(e) => onClickHandler(e, 'create')}
                        className="w-full justify-center rounded-md border bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm duration-300 hover:border-primary hover:bg-gray-50 hover:text-primary focus:outline-none"
                      >
                        Create a League
                      </button>
                      <button
                        onClick={(e) => onClickHandler(e, 'code')}
                        className="w-full justify-center rounded-md border bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm duration-300 hover:border-primary hover:bg-gray-50 hover:text-primary focus:outline-none"
                      >
                        Join a League
                      </button>
                    </div>
                  </div>
                )}
                {selectedModal === 'create' && (
                  <CreateLeague onClose={onBack} onNext={onNextHandler} />
                )}
                {selectedModal === 'code' && (
                  <InviteModal
                    onClose={onClose}
                    onBack={onBack}
                    onSuccess={onNextNextHandler}
                  />
                )}
                {selectedModal === 'join' && createdLeagueId && (
                  <InviteTeamsModal
                    onClose={onNextNextHandler}
                    leagueId={createdLeagueId}
                    onSuccess={onNextNextHandler}
                  />
                )}
                {selectedModal === 'settings' && createdLeagueId && (
                  <MyTeamLeagueCreation
                    leagueId={createdLeagueId}
                    onClose={onClose}
                    onSuccess={onSuccess}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
