import { useState } from 'react';
import {
  PlusCircleIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { Modal } from '../../app/modal';
import { DashboardSettingsModal } from '../../app/modals/dashboardSettings';
import { LeaguesModal } from '../../app/modals/leagues';
import { AiOutlineMessage } from 'react-icons/ai';

export const Header = () => {
  const [showModalType, setShowModalType] = useState<string>();

  const onClickHandler = (e, type) => {
    e.preventDefault();
    setShowModalType(type);
  };

  const onCloseHandler = () => {
    setShowModalType(undefined);
  };
  return (
    <>
      <div className="flex items-center justify-end gap-4 bg-gray-800 px-8 py-2 text-white">
        <button
          className="flex items-center gap-2 rounded-lg border border-white py-1 pl-2 pr-4"
          onClick={(e) => onClickHandler(e, 'league')}
        >
          <PlusCircleIcon height={30} width={30} />
          <span className="text-xs">Create or Join</span>
        </button>
        {/* <button onClick={(e) => onClickHandler(e, 'dots')}>
          <EllipsisVerticalIcon height={30} width={30} />
        </button> */}
      </div>
      {!!showModalType && (
        <Modal>
          {showModalType === 'league' && (
            <LeaguesModal onClose={onCloseHandler} />
          )}
          {showModalType === 'dots' && (
            <DashboardSettingsModal onClose={onCloseHandler} />
          )}
        </Modal>
      )}
    </>
  );
};

export default Header;
