import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSupabase } from '../../hooksV2/useSupabase';

type ResetPasswordFormValues = {
  email: string;
};

export const PasswordReset = () => {
  const { user, supabase } = useSupabase();
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [form, setForm] = useState<ResetPasswordFormValues>({
    email: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.id) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const onSubmitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { data, error } = await supabase.auth.resetPasswordForEmail(
      form.email
    );
    setHasSentEmail(true);
    if (error) {
      console.log(error);
    }
  };

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  if (user?.aud) {
    navigate('/dashboard');
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Reset your password
          </h2>
        </div>
        {hasSentEmail && (
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white px-4 py-8 sm:rounded-lg sm:px-10">
              <span>Please check your email for a password reset link!</span>
              <div className="mt-2">
                <span className="text-sm ">
                  Don&apos;t have an account yet? Register{' '}
                  <Link
                    to="/register"
                    className="font-semibold text-indigo-700"
                  >
                    Here
                  </Link>
                </span>
              </div>
            </div>
          </div>
        )}
        {!hasSentEmail && (
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white px-4 py-8 sm:rounded-lg sm:px-10">
              <form className="space-y-6" onSubmit={onSubmitHandler}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={form.email}
                      onChange={onChangeHandler}
                      required
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Password Reset
                  </button>
                </div>
              </form>
              <div className="mt-2">
                <span className="text-sm ">
                  Don&apos;t have an account yet? Register{' '}
                  <Link
                    to="/register"
                    className="font-semibold text-indigo-700"
                  >
                    Here
                  </Link>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
