import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import ErrorImage from '../../assets/error_boundary.png';
import { Link } from 'react-router-dom';

export class ErrorBoundary extends React.Component<
  {
    children: React.ReactNode;
  },
  {
    hasError: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex h-screen flex-col items-center justify-center">
          <h1 className="text-lg">Oops</h1>
          <div className="flex flex-col">
            <h2 className="text-sm">Something went wrong on the course.</h2>
            <h2 className="text-sm">
              But it&apos;s okay, let&apos;s get back in the game!
            </h2>
          </div>
          <a
            href="/dashboard"
            className="mt-4 flex gap-2 rounded-lg border-2 border-primary px-4 py-2 text-primary duration-300 hover:bg-primary hover:text-white"
          >
            Click to Refresh
            <ArrowPathIcon height={25} width={25} />
          </a>
          <img
            className="mt-6 aspect-square w-80 rounded-xl object-cover"
            src={ErrorImage}
            alt="Error"
          />
        </div>
      );
    }
    return this.props.children;
  }
}
