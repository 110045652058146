import { Transition, Dialog } from '@headlessui/react';
import {
  ArrowsRightLeftIcon,
  PlusIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useCreateTrade } from '../../../hooks/useCreateTrade';
import { useMyTeam } from '../../../hooks/useMyTeam';
import { Modal } from '../modal';
import { PlayerLookup } from './playerLookup';
import { useQueryClient } from 'react-query';
import { PlayerModal } from './playerModal';

export const TradingModal = ({ onClose, player, team, image }) => {
  const [sendPlayers, setSendPlayers] = useState<any[]>([]);
  const [receivePlayers, setReceivePlayers] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTeamType, setSelectedTeamType] = useState();
  const [selectedTeam, setSelectedTeam] = useState();
  const { leagueId } = useParams();
  const { data: myTeamData } = useMyTeam();
  const { createTrade } = useCreateTrade();
  const [showPlayerInfo, setShowPlayerInfo] = useState<any>();
  const [playerInfo, setPlayerInfo] = useState<any>();
  const [tradeValidationMessage, setTradeValidationMessage] = useState('');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (player && image) {
      const playerPayload = {
        ...player,
        image,
      };
      setReceivePlayers([playerPayload]);
    }
  }, [player, image]);

  const onAddPlayerHandle = (teamType, team) => {
    setOpenModal(true);
    setSelectedTeamType(teamType);
    setSelectedTeam(team);
  };
  const onCloseLookUpHandler = () => {
    setOpenModal(false);
    setSelectedTeamType(undefined);
    setSelectedTeam(undefined);
  };

  const onClosePlayerInfoHandler = () => {
    setShowPlayerInfo(false);
    setPlayerInfo(undefined);
  };

  const updatePlayerList = (players, teamType) => {
    if (teamType === 'send') {
      setSendPlayers(players);
    } else {
      setReceivePlayers(players);
    }
    if (
      sendPlayers.length + 1 === receivePlayers.length ||
      sendPlayers.length - 1 === receivePlayers.length
    ) {
      setTradeValidationMessage('');
    }
  };

  const onLookupSelectHandler = (e, player, imageUrl) => {
    e.preventDefault();
    const newPlayer = { ...player, image: imageUrl };
    if (selectedTeamType === 'send') {
      updatePlayerList([...sendPlayers, newPlayer], 'send');
    } else {
      updatePlayerList([...receivePlayers, newPlayer], 'receive');
    }
    setTradeValidationMessage('');
    onCloseLookUpHandler();
  };

  const deletePlayerFromList = (e, type, player) => {
    e.stopPropagation();
    if (type === 'send') {
      const filteredPlayers = sendPlayers.filter(
        (sendPlayer) => sendPlayer.id !== player.id
      );
      updatePlayerList([...filteredPlayers], 'send');
    } else {
      const filteredPlayers = receivePlayers.filter(
        (receivePlayer) => receivePlayer.id !== player.id
      );
      updatePlayerList([...filteredPlayers], 'receive');
    }
  };

  const onClickPlayerHandler = (player) => {
    setShowPlayerInfo(true);
    setPlayerInfo(player);
  };

  const generateTradeColumn = (teamType, team, players) => {
    const maxPlayerCount = Math.max(sendPlayers.length, receivePlayers.length);
    const playerElements = players.map((player) => (
      <div className="flex h-[40px] flex-col gap-2 rounded-md" key={player.id}>
        <button
          className="flex flex-row items-center gap-2 border"
          onClick={() => onClickPlayerHandler(player)}
        >
          <div onClick={(e) => deletePlayerFromList(e, teamType, player)}>
            <XCircleIcon width={20} height={20} />
          </div>
          <div className="flex flex-row items-center gap-2">
            <img
              src={player.image ? player.image : image}
              alt="player image"
              height={24}
              width={24}
              className="rounded-full border border-slate-700"
            />
            <span className="text-xs">{player.abbr_name}</span>
          </div>
        </button>
      </div>
    ));

    for (let i = playerElements.length; i < maxPlayerCount; i++) {
      playerElements.push(
        <div
          className="invisible h-[40px] rounded-md py-2"
          key={`placeholder-${i}`}
        ></div>
      );
    }

    playerElements.push(
      <button
        className="my-2 flex w-fit flex-row items-center justify-center self-center rounded-lg border border-slate-500 p-1"
        key="addPlayer"
        onClick={() => onAddPlayerHandle(teamType, team)}
      >
        <PlusIcon height={20} width={20} />
        <span className="text-xs">Add Player</span>
      </button>
    );

    return (
      <div className="flex h-full flex-col justify-between">
        {playerElements}
      </div>
    );
  };

  const onSubmitSendTrade = async (e) => {
    e.preventDefault();
    if (sendPlayers.length === 0 && receivePlayers.length === 0) {
      setTradeValidationMessage(
        'You need to select players to send and receive.'
      );
      return;
    }
    if (sendPlayers.length !== receivePlayers.length) {
      setTradeValidationMessage(
        'You can only trade an equal number of players.'
      );
      return;
    }
    setTradeValidationMessage('');
    const players = [];
    sendPlayers.forEach((player) => {
      players.push({
        id: player.id,
        type: 'sender',
      });
    });
    receivePlayers.forEach((player) => {
      players.push({
        id: player.id,
        type: 'receiver',
      });
    });
    const trade = {
      senderTeamId: myTeamData.id,
      receiverTeamId: team.id,
      leagueId: leagueId,
    };

    await createTrade(players, trade);
    queryClient.invalidateQueries(['useTrades', myTeamData.id]);
    onClose();
  };

  return (
    <>
      <Transition.Root as={Fragment} show={true}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative flex w-full transform items-center overflow-hidden rounded-lg bg-slate-200 px-4 py-5 text-left shadow-xl transition-all sm:max-w-lg">
                  <span className="absolute top-0 mt-2 text-lg font-semibold">
                    Trading Offer
                  </span>
                  <div className="mt-8 flex w-full flex-col items-center justify-center">
                    <div className="flex flex-row items-center">
                      <div className="w-full">
                        <div className="flex w-[300px] justify-around">
                          <div className="grid w-full grid-cols-1 place-items-center">
                            <div className="flex flex-col items-center">
                              <span className="text-xs">
                                {myTeamData?.name}
                              </span>
                              <span className="text-sm font-semibold text-primary">
                                Send
                              </span>
                            </div>
                            {generateTradeColumn(
                              'send',
                              myTeamData,
                              sendPlayers
                            )}
                          </div>
                          <div className="mx-2 mt-3 w-fit grow-0">
                            <ArrowsRightLeftIcon height={24} width={24} />
                          </div>
                          <div className="grid w-full grid-cols-1 place-items-center">
                            <div className="flex flex-col items-center">
                              <span className="text-xs">{team.name}</span>
                              <span className="text-sm font-semibold text-accent">
                                Receive
                              </span>
                            </div>
                            {generateTradeColumn(
                              'receive',
                              team,
                              receivePlayers
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full flex-col gap-2">
                      {tradeValidationMessage ? (
                        <div className="mb-1 h-[12px] text-center text-xs text-red-500">
                          {tradeValidationMessage}
                        </div>
                      ) : (
                        <div className="mb-1 h-[12px]"></div>
                      )}
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={(e) => onSubmitSendTrade(e)}
                      >
                        Trade
                      </button>
                      <button
                        type="button"
                        onClick={onClose}
                        className="w-full rounded-md border border-gray-900 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {openModal && selectedTeamType && selectedTeam && (
        <Modal>
          <PlayerLookup
            onClose={onCloseLookUpHandler}
            onSelect={onLookupSelectHandler}
            teamPlayers={
              selectedTeamType === 'send' ? sendPlayers : receivePlayers
            }
            team={selectedTeam}
          />
        </Modal>
      )}
      {showPlayerInfo && playerInfo && (
        <Modal>
          <PlayerModal
            player={playerInfo}
            onClose={() => onClosePlayerInfoHandler()}
            imageUrl={playerInfo.image}
          />
        </Modal>
      )}
    </>
  );
};
