import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSupabase } from '../../../hooksV2/useSupabase';
import { Player } from '../../../models/players/api';
import { Modal } from '../../app/modal';
import {
  yearsDifferenceFromCurrent,
  formatNumberWithCommas,
  formatPlayerName,
} from '../../../utils/utils';
import { HandRaisedIcon } from '@heroicons/react/24/outline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { PlayerDraftModal } from '../../app/modals/playerDraftModal';
import { DraftModal } from '../../app/modals/draftModal';

interface PlayerWithScore extends Player {
  score: number;
  places: number;
  rank: number;
}

type Props = {
  players: any[];
  title?: string;
  description?: string;
  onSubmit: any;
  isMyPick: boolean;
};

export function DraftPlayerList({ players, onSubmit, isMyPick }: Props) {
  const { supabase } = useSupabase();
  const [images, setImages] = useState([]);
  const [playerSelected, setPlayerSelected] = useState<Player>();
  const [playerImage, setPlayerImage] = useState();
  const [sortMode, setSortMode] = useState<string>('rank');
  const [clickedAmount, setClickedAmount] = useState<number>(1);
  const [showDraftModal, setShowDraftModal] = useState(false);

  const onSubmitHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    e.stopPropagation();
    onSubmit(id);
    setPlayerSelected(undefined);
    setPlayerImage(undefined);
  };

  const fetchImages = async (players) => {
    const promises = [];
    const filteredPlayers = players.filter((player) => !!player.profile_url);
    for (const player of filteredPlayers) {
      const result = supabase.storage
        .from('golfers')
        .getPublicUrl(player.profile_url);
      promises.push(result);
    }
    const result = supabase.storage.from('golfers').getPublicUrl('unknown');
    promises.push(result);
    const results = await Promise.all(promises);
    const mapResults = results.map((result) => result.data.publicUrl);
    setImages(mapResults);
  };

  const onDraftHandler = (player) => {
    setPlayerSelected(player);
    setShowDraftModal(true);
  };

  useEffect(() => {
    if (players) {
      fetchImages(players);
    }
  }, [players]);

  const generateTableData = (player: PlayerWithScore, index, playerImages) => {
    let imageUrl = playerImages.find((playerImage) =>
      playerImage.includes(player.profile_url)
    );
    if (!imageUrl) {
      imageUrl = playerImages.find((playerImage) =>
        playerImage.includes('unknown')
      );
    }

    const formattedName = formatPlayerName(player.name);

    const yoe = player.turned_pro
      ? yearsDifferenceFromCurrent(player.turned_pro)
      : '-';

    const yoeClass = clsx({
      'text-gray-400': yoe === '-',
      'text-current': yoe !== '-',
    });

    const iconStyle =
      player.handedness === 'L' ? { transform: 'scaleX(-1)' } : {};

    return (
      <div className="h-[100px] py-2 pl-2 pr-3 text-sm sm:pl-0">
        <div className="flex w-full items-center justify-end pr-3">
          <button
            type="button"
            className={`flex items-center justify-center rounded-md border border-transparent ${
              isMyPick ? 'bg-primary' : 'bg-slate-400'
            } px-2 py-0.5 text-xs font-medium text-white shadow-sm`}
            onClick={() => (isMyPick ? onDraftHandler(player) : '')}
          >
            Draft
          </button>
        </div>
        <div className="flex items-center">
          <span className="mr-5 lg:mx-5">{index + 1}</span>
          <div className="h-10 w-10 flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src={imageUrl ?? ''}
              alt=""
            />
          </div>
          <div className="ml-4 flex flex-1 items-center justify-between">
            <div>
              <div className="font-medium text-gray-900">{formattedName}</div>
              <div className="flex flex-row items-center gap-3">
                <div className="flex flex-row items-center gap-1">
                  <HandRaisedIcon style={iconStyle} height={15} width={15} />
                  <div>{player.handedness}</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <span>YOE:</span>
                  <div className={yoeClass}>{yoe}</div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 whitespace-nowrap">
              <div className="flex flex-col items-center">
                <span className="text-sm font-semibold">Total PTS</span>
                <div>{formatNumberWithCommas(player.score)}</div>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-sm font-semibold">Rank</span>
                <div>{player?.rank ?? 'NR'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onPlayerClickHandler = (e: any, player) => {
    e.preventDefault();
    let imageUrl = images.find((playerImage) =>
      playerImage.includes(player.profile_url)
    );
    if (!imageUrl) {
      imageUrl = images.find((playerImage) => playerImage.includes('unknown'));
    }
    setPlayerSelected(player);
    setPlayerImage(imageUrl);
  };

  const onModalClose = () => {
    setPlayerImage(undefined);
    setPlayerSelected(undefined);
    setShowDraftModal(false);
  };

  const onClickSortHandler = (sort: string) => {
    if (sortMode === sort) {
      setClickedAmount((prevAmount) => (prevAmount === 1 ? 2 : 1));
    } else {
      setClickedAmount(1);
    }
    setSortMode(sort);
  };

  const getSortedPlayers = () => {
    const ascending = clickedAmount === 1;
    const compareYOE = (a, b) => {
      if (!a.turned_pro && !b.turned_pro) {
        return 0;
      }
      if (!a.turned_pro) {
        return 1;
      }
      if (!b.turned_pro) {
        return -1;
      }
      return ascending
        ? a.turned_pro - b.turned_pro
        : b.turned_pro - a.turned_pro;
    };

    if (sortMode === 'pts') {
      players.sort((a, b) =>
        ascending ? b.score - a.score : a.score - b.score
      );
    } else if (sortMode === 'exp') {
      players.sort(compareYOE);
    } else if (sortMode === 'rank') {
      players.sort((a, b) => (ascending ? a.rank - b.rank : b.rank - a.rank));
    }
    return players;
  };

  return (
    <>
      <div className="my-2 flex flex-row justify-around whitespace-nowrap">
        <div className="w-[5ch]">
          <button
            type="button"
            className="rounded-full bg-primary px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => onClickSortHandler('exp')}
          >
            EXP
          </button>
          {sortMode === 'exp' &&
            (clickedAmount === 1 ? (
              <ArrowDropUpIcon className="text-primary" />
            ) : (
              <ArrowDropDownIcon className="text-primary" />
            ))}
        </div>
        <div className="w-[5ch]">
          <button
            type="button"
            className="rounded-full bg-primary px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => onClickSortHandler('pts')}
          >
            PTS
          </button>
          {sortMode === 'pts' &&
            (clickedAmount === 1 ? (
              <ArrowDropUpIcon className="text-primary" />
            ) : (
              <ArrowDropDownIcon className="text-primary" />
            ))}
        </div>
        <div className="w-[12ch]">
          <button
            type="button"
            className={`rounded-full bg-primary px-2.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
            onClick={() => onClickSortHandler('rank')}
          >
            WORLD RANK
          </button>
          {sortMode === 'rank' &&
            (clickedAmount === 1 ? (
              <ArrowDropUpIcon className="text-primary" />
            ) : (
              <ArrowDropDownIcon className="text-primary" />
            ))}
        </div>
      </div>
      <div className="fixed flex h-[calc(100vh-15rem)] w-screen flex-col overflow-x-hidden overflow-y-scroll bg-primary pb-16 lg:px-8">
        <div className="lg:-mx-4">
          <div className="min-w-full py-2 align-middle sm:px-2 lg:px-4">
            <div className="min-w-full divide-y divide-gray-300">
              <ul className="flex flex-col divide-y divide-gray-200">
                {players &&
                  getSortedPlayers().map((player, idx) => (
                    <li
                      key={player.id}
                      className="m-1 cursor-pointer rounded-md bg-white"
                      onClick={(e: any) => onPlayerClickHandler(e, player)}
                    >
                      {generateTableData(player, idx, images)}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {playerSelected && playerImage && !showDraftModal && (
        <Modal>
          <PlayerDraftModal
            imageUrl={playerImage}
            player={playerSelected}
            onClose={onModalClose}
            onSubmit={(e) => onSubmitHandler(e, playerSelected.id)}
            isMyPick={isMyPick}
          />
        </Modal>
      )}
      {playerSelected && showDraftModal && (
        <Modal>
          <DraftModal
            onClose={onModalClose}
            onSubmit={(e) => onSubmitHandler(e, playerSelected.id)}
            player={playerSelected}
          />
        </Modal>
      )}
    </>
  );
}
